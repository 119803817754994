import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AlignersKit from './AlignersKit';
import Login from './Login';

export default () => (
  <Switch>
    {/* TODO: rename '/aligners-kit' path to a generic name */}
    <Redirect exact from="/" to="/aligners-kit" />
    <Route path="/aligners-kit" component={AlignersKit} />
    <Route exact path="/login" component={Login} />
  </Switch>
);
