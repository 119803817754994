import axios from 'axios';
import { AddressFields, ValidatedAddress, AutocompleteAddress } from './types';

const SMARTY_STREETS_AUTO_COMPLETE =
  'https://us-autocomplete-pro.api.smartystreets.com/suggest';
const SMARTY_STREETS_AUTO_COMPLETE_FALLBACK =
  'https://us-autocomplete.api.smartystreets.com/suggest';
const SMARTY_STREETS_ADDRESS_VALIDATION_URL =
  'https://us-street.api.smartystreets.com/street-address';

const AUTOCOMPLETE_LIMIT = 10;

const validationUrlMapper = (address: AddressFields): URLSearchParams => {
  const lookupObj = {
    address_line_1: 'street',
    address_line_2: 'secondary',
    city: 'city',
    state_code: 'state',
    zip: 'zipcode',
  };
  const searchParams = new URLSearchParams();

  (Object.keys(address) as (keyof AddressFields)[]).forEach((field) => {
    const lookupKey = lookupObj[field];
    if (lookupKey) {
      searchParams.set(lookupKey, address[field]);
    }
  });
  return searchParams;
};

const getAuthKey = () => {
  return process.env.REACT_APP_SMARTY_AUTH_KEY;
};

export const validateAddress = async (
  params: AddressFields
): Promise<ValidatedAddress[]> => {
  const searchParams = validationUrlMapper(params);
  const authKey = getAuthKey() ?? '';
  if (authKey === '') {
    console.error('No auth key given');
    return Promise.resolve([]);
  }
  searchParams.set('key', authKey);

  const withUrlParams = `${SMARTY_STREETS_ADDRESS_VALIDATION_URL}?${searchParams.toString()}`;

  try {
    const resp = await axios.get(withUrlParams);
    return resp.data;
  } catch (err) {
    console.error('request failed', err);
    return Promise.resolve([]);
  }
};

export const autocompleteAddress = async (
  input: string,
  limit: number = AUTOCOMPLETE_LIMIT
): Promise<AutocompleteAddress[]> => {
  const searchParams = new URLSearchParams();
  searchParams.set('prefix', input);
  searchParams.set('suggestions', limit.toString());
  const authKey = getAuthKey() ?? '';
  if (authKey === '') {
    console.error('No auth key given');
    return Promise.resolve([]);
  }
  searchParams.set('key', authKey);

  const withUrlParams = `${SMARTY_STREETS_AUTO_COMPLETE}?${searchParams.toString()}`;
  const fallbackWithUrlParams = `${SMARTY_STREETS_AUTO_COMPLETE_FALLBACK}?${searchParams.toString()}`;
  try {
    const resp = await axios.get(withUrlParams);
    return resp.data?.suggestions ?? [];
  } catch (err) {
    console.error('request failed', err);
    try {
      const resp = await axios.get(fallbackWithUrlParams);
      return resp.data?.suggestions ?? [];
    } catch (err) {
      return Promise.resolve([]);
    }
  }
};
