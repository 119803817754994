import { compose, withState, withHandlers, withProps } from 'recompose';
import { parse } from 'query-string';
import { withRouter } from 'react-router-dom';
import { login } from 'modules/user/api';
import { unauthenticated } from 'modules/user/hoc';
import { signIn } from 'modules/user/storage';
import { segment } from 'core/integrations';
import { logrocket } from 'core/integrations/logrocket';
import { setEmail } from 'core/storage';
import View from './View';

export default compose(
  unauthenticated,
  withRouter,
  withState('error', 'setError', void 0),
  withState('isRequesting', 'setRequesting', false),
  withHandlers({
    onSubmit: ({ history, setError, setRequesting }) => async data => {
      try {
        setRequesting(true);
        segment.identify(data.email.toLowerCase());

        const res = await login(data.email, data.password);

        if (
          !res.is_superuser &&
          (!res.groups || (res.groups && res.groups.indexOf('sales') === -1))
        ) {
          throw new Error('You are not allowed to sign in');
        }

        logrocket.identify(data.email, data.email.toLowerCase());

        setEmail(data.email);
        signIn(res.access_token, res.refresh_token, res.groups);
        setError(void 0);
        setRequesting(false);
        history.replace('/');
      } catch (err) {
        setError(err.message);
        setRequesting(false);
      }
    },
  }),
  withProps(({ location }) => {
    const { expired } = parse(location.search);

    return {
      isExpired: expired && JSON.parse(expired),
    };
  })
)(View);
