import { Dispatch, Store } from 'redux';
import { ProductCatalogDetail, ProductSkus } from 'store/products/types';
import { createAction, ActionsUnion } from 'store/action';
import { CartItem } from './types';
import { fetchApiWithPromise, RequestMethods } from 'core/conceptions/api';
import { OrderResponse } from 'store/payment/types';
import { getSelectedCase } from 'store/cases';
import { getCustomerData } from 'store/customer';

export enum CartAction {
  ADD_ITEM = 'ADD_ITEM',
  REMOVE_ITEM = 'REMOVE_ITEM',
  CLEAR_CART = 'CLEAR_CART',
  SET_NEW_ORDER = 'SET_NEW_ORDER',
  FETCH_QUOTE_REQUEST = 'FETCH_QUOTE_REQUEST',
  FETCH_QUOTE_SUCCESS = 'FETCH_QUOTE_SUCCESS',
  FETCH_QUOTE_FAILURE = 'FETCH_QUOTE_FAILURE',
  SET_SELECTED_SHIPPING_ADDRESS = 'SET_SELECTED_SHIPPING_ADDRESS',
  SET_SENT_PATIENT_SHIPPING_UPDATE = 'SET_SENT_PATIENT_SHIPPING_UPDATE',
  SET_SHIPPING_ADDRESS_TYPE = 'SET_SHIPPING_ADDRESS_TYPE',
  SET_SELECTED_SHIPPING_ADDRESS_BUTTON_REF = 'SET_SELECTED_SHIPPING_ADDRESS_BUTTON_REF',
  CLEAR_SHIPPING_ADDRESS_SELECTION = 'CLEAR_SHIPPING_ADDRESS_SELECTION',
}

export const CartActions = {
  addItem: (
    sku: ProductSkus,
    productDetail: ProductCatalogDetail,
    quantity?: number,
    metadata?: { [key: string]: any }
  ) => createAction(CartAction.ADD_ITEM, { sku, productDetail, quantity, metadata }),
  removeItem: (sku: ProductSkus) =>
    createAction(CartAction.REMOVE_ITEM, { sku }),
  clearCart: () => createAction(CartAction.CLEAR_CART),
  clearShippingAddressSelection: () => createAction(CartAction.CLEAR_SHIPPING_ADDRESS_SELECTION),
  setNewOrder: (isNewOrder: boolean) =>
    createAction(CartAction.SET_NEW_ORDER, { isNewOrder }),
  fetchQuoteRequest: () => createAction(CartAction.FETCH_QUOTE_REQUEST),
  fetchQuoteSuccess: (quote: OrderResponse) =>
    createAction(CartAction.FETCH_QUOTE_SUCCESS, { quote }),
  fetchQuoteFailure: (errorMessage: string) =>
    createAction(CartAction.FETCH_QUOTE_FAILURE, { errorMessage }),
  setShippingAddress: (shippingAddress: {}) =>
    createAction(CartAction.SET_SELECTED_SHIPPING_ADDRESS, { shippingAddress }),
  setSentPatientShippingUpdate: (sentPatientShippingUpdate: boolean) =>
    createAction(CartAction.SET_SENT_PATIENT_SHIPPING_UPDATE, { sentPatientShippingUpdate }),
  setShippingAddressType: (shippingAddressType: string) =>
    createAction(CartAction.SET_SHIPPING_ADDRESS_TYPE, { shippingAddressType }),
  setSubmitRef: (ref: any) =>
    createAction(CartAction.SET_SELECTED_SHIPPING_ADDRESS_BUTTON_REF, { ref }),
};

export type CartActions = ActionsUnion<typeof CartActions>;

export const fetchQuote = (
  items: CartItem[],
  customerId: string,
  couponCode?: string
) => async (dispatch: Dispatch, getState: Store['getState']) => {
  dispatch(CartActions.fetchQuoteRequest());
  
  try {
    const state = getState();
    const case_type = getSelectedCase(state)?.case_type!;
    // hit the api to fetch a quote
    const resp = await fetchApiWithPromise(
      '/api/v1/quote/',
      RequestMethods.POST,
      {
        customer_id: customerId,
        items: items.map(item => ({ sku: item.sku, quantity: item.quantity })),
        shipping_address: getCustomerData(state)?.shipping_address!,
        case_type: case_type.name,
        ...(couponCode ? { coupon_code: couponCode } : {}),
      }
    );
    dispatch(CartActions.fetchQuoteSuccess(resp.data as OrderResponse));
  } catch (e) {
    if (e.response?.status === 422) {
      dispatch(CartActions.fetchQuoteFailure(e.response.data.error));
      return;
    }
    dispatch(CartActions.fetchQuoteFailure('something happened'));
  }
};
