/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import { LOAD_STATES } from 'core/constants';
import { ErrorTextPill } from 'components/common/ErrorPill';
import { AddressForm } from 'components/pages/AlignersKit/Checkout/components/Address';
import { ErrorText } from 'components/common/ErrorText';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomerShipping } from 'store/customer/actions';

import {
  getShippingAddress,
  getCustomerLoadState,
  getCustomerErrorMessage,
  getCustomerBrand,
  getCustomerEmail,
  isCustomerShippingAddressEmpty,
  getCustomerData,
} from 'store/customer';
import { AddressInputCamelCase } from 'store/customer/types';
import { getSelectedCase } from 'store/cases';
import { segment } from 'core/integrations';

const CustomerInfo = ({ customerId }: { customerId: string }) => {
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

  const caseInfo = useSelector(getSelectedCase);
  const customer = useSelector(getCustomerData);
  const shippingAddress = useSelector(getShippingAddress);
  const customerLoadState = useSelector(getCustomerLoadState);
  const customerErrorMessage = useSelector(getCustomerErrorMessage);
  const isShippingAddressEmpty = useSelector(isCustomerShippingAddressEmpty);
  const email = useSelector(getCustomerEmail);
  const brand = useSelector(getCustomerBrand);

  if (customerLoadState === LOAD_STATES.FAILURE) {
    return ErrorText(customerErrorMessage);
  }

  if (
    customerLoadState === LOAD_STATES.PENDING ||
    customerLoadState === LOAD_STATES.UNDEFINED
  ) {
    return <div className="flex">Loading customer data...</div>;
  }
  const {
    first_name,
    last_name,
    address_line_1,
    address_line_2,
    city,
    state_code,
    zip,
    phone,
    country_code,
  } = shippingAddress;

  const renderErrorTextPill = () => {
    if (isShippingAddressEmpty) {
      return ErrorTextPill(
        'Customer address needs to be filled out before payment can be made'
      );
    }

    return null;
  };

  const clickOnEditMode = () => {
    setEditMode(!editMode);
    segment.trackClick('Edit Customer Info');
  };

  return (
    <>
      <div className="flex">
        <div className="mb1 pt2">
          <div className="fw6">
            <span data-private="lipsum" data-dd-privacy="mask">{`${customer.first_name}`}</span>{' '}
            {customer.last_name} {'('}
            {/* links directly to the admin page for the given customer */}
            <a
              href={`${process.env.REACT_APP_API}/admin/app/customer/${customerId}/change/`}
              className="link blue underline hover-pink"
              tabIndex={0}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => segment.trackClick('Open customer in admin')}
            >
              {`ID: ${customerId}`}
            </a>
            {')'}
          </div>
        </div>
        <div className="pt2 ml-auto">
          <button
            type="button"
            className="fw6 underline pointer bn input-reset outline-0"
            disabled={isShippingAddressEmpty}
            onClick={() => clickOnEditMode()}
            tabIndex={0}
          >
            {editMode ? 'Hide Customer Info' : 'Edit Customer Info'}
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-between pt2 lh-copy">
        <div>
          <div>
            Email: <span data-private="lipsum" data-dd-privacy="mask">{email}</span>
          </div>
          {brand && (
            <div>
              Brand:{' '}
              <span data-private="lipsum" data-dd-privacy="mask">
                {brand?.label}
              </span>
            </div>
          )}
          {caseInfo?.id && (
            <>
              <div>Case ID: {caseInfo.id}</div>
              <div>Case Type: {caseInfo.case_type?.label || 'Unknown'}</div>
            </>
          )}
        </div>
        {customer.referring_dentist && (
          <div className="i">
            Referring Dentist:{' '}
            <span className="nowrap">
              {customer.referring_dentist.full_name}
            </span>
          </div>
        )}
      </div>
      <div className="mt2 lh-copy">
        <div className="flex">
          <div className="w-100">
            <div className="fw6 mt2 mb2">Shipping address:</div>
            {renderErrorTextPill()}
            <AddressForm
              isEditMode={editMode || isShippingAddressEmpty}
              onSave={(data: object) => {
                dispatch(
                  updateCustomerShipping(
                    customerId,
                    data as AddressInputCamelCase
                  )
                );
              }}
              firstName={first_name}
              lastName={last_name}
              addressLine1={address_line_1}
              addressLine2={address_line_2}
              city={city}
              state={state_code}
              zip={zip}
              phone={phone}
              country={country_code}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerInfo;
