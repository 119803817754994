import React from 'react';
import { Label } from '..';

const defaultClass = 'input-reset ba br2 ph2 pv3 db f5 fw6 outline-0';

export default ({
  required,
  input,
  label,
  placeholder,
  type,
  meta: { touched, error },
  className = defaultClass,
}: {
  required: boolean;
  input: any;
  label?: any;
  placeholder: string;
  type: string;
  meta: Partial<{
    touched: boolean;
    error: any;
  }>;
  className?: string;
}) => (
  <Label title={label} error={touched && error}>
    <input
      style={{ boxSizing: 'border-box' }}
      className={`${className} w-100 ${
        touched && error ? 'b--red' : 'b--black-20'
      }`}
      {...input}
      required={required}
      placeholder={placeholder}
      type={type}
    />
  </Label>
);
