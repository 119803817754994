import { Dispatch, Store } from 'redux';
import { createAction, ActionsUnion } from 'store/action';
import { ProductSkus } from 'store/products/types';
import {
  InsuranceForm,
  BirthDate,
  InsuranceRequestPayload,
} from 'store/insurance/types';
import { fetchApiWithPromise, RequestMethods } from 'core/conceptions/api';
import { getCustomerData } from 'store/customer';
import { getInsuranceForm } from '.';

type OnederfulError = {
  reason: string;
  followup: string;
  is_valid_request: string;
};

/** Actions */
export enum InsuranceAction {
  SAVE_INSURANCE_REQUEST = 'SAVE_INSURANCE_REQUEST',
  SAVE_INSURANCE_SUCCESS = 'SAVE_INSURANCE_SUCCESS',
  SAVE_INSURANCE_FAILURE = 'SAVE_INSURANCE_FAILURE',
  FETCH_INSURANCE_REQUEST = 'FETCH_INSURANCE_REQUEST',
  FETCH_INSURANCE_SUCCESS = 'FETCH_INSURANCE_SUCCESS',
  FETCH_INSURANCE_FAILURE = 'FETCH_INSURANCE_FAILURE',
  CHECK_ELIGIBILITY = 'CHECK_ELIGIBILITY',
  SET_IN_NETWORK = 'SET_IN_NETWORK',
}

export const InsuranceActions = {
  saveInsuranceRequest: () =>
    createAction(InsuranceAction.SAVE_INSURANCE_REQUEST),
  saveInsuranceSuccess: (payload: any) =>
    createAction(InsuranceAction.SAVE_INSURANCE_SUCCESS, payload),
  saveInsuranceFailure: (error: any) =>
    createAction(InsuranceAction.SAVE_INSURANCE_FAILURE, error),
  fetchInsuranceRequest: () =>
    createAction(InsuranceAction.FETCH_INSURANCE_REQUEST),
  fetchInsuranceSuccess: (payload: {
    data: InsuranceRequestPayload;
    valid: boolean;
  }) => createAction(InsuranceAction.FETCH_INSURANCE_SUCCESS, payload),
  fetchInsuranceFailure: (error: any) =>
    createAction(InsuranceAction.FETCH_INSURANCE_FAILURE, error),
  checkEligibility: (payload: any) =>
    createAction(InsuranceAction.CHECK_ELIGIBILITY, payload),
  setInNetwork: (payload: any) =>
    createAction(InsuranceAction.SET_IN_NETWORK, payload),
};


export const fetchInsurance = (customerId: number | string) => async (
  dispatch: Dispatch<any>
) => {
  dispatch(InsuranceActions.fetchInsuranceRequest());

  try {
    const resp = await fetchApiWithPromise<any, InsuranceRequestPayload>(
      `/api/v1/membership/${customerId}`,
      RequestMethods.GET
    );
    const payload = resp.data;

    const { onederfulPayerId, activePlanData } = payload;
    const hasErrors = Boolean(activePlanData?.errors);
    const validInsurance = Boolean(onederfulPayerId) && !hasErrors;
    dispatch(
      InsuranceActions.fetchInsuranceSuccess({
        data: payload,
        valid: validInsurance,
      })
    );

    // Only show valid, onederful insurance
    if (validInsurance) {
      dispatch(getEstimatedClaim(Number(customerId)));
    } else if (hasErrors) {
      const error = parseInsuranceErrors(activePlanData?.errors);
      dispatch(InsuranceActions.fetchInsuranceFailure(error));
    }
  } catch (err) {
    if (err.response.data.detail && err.response.data.detail === 'Not found.') {
      // if response is a 404 "Not found", then don't show the error
      return;
    }

    dispatch(
      InsuranceActions.fetchInsuranceFailure(JSON.stringify(err.response.data))
    );
  }
};

const transformFormDataToPayload = (form: InsuranceForm) => {
  const {
    onederfulPayerId,
    primaryFirstName,
    primaryLastName,
    primaryMemberId,
    groupNumber,
    primaryBirthMonth,
    primaryBirthDay,
    primaryBirthYear,
    ...dependentFields
  } = form;

  const formatBirthDate = ({ month, day, year }: BirthDate) =>
    [year, month, day].join('-');

  let dependent;
  const isDependent = Object.keys(dependentFields).length !== 0;
  if (isDependent) {
    const {
      dependentFirstName,
      dependentLastName,
      dependentBirthDay,
      dependentBirthMonth,
      dependentBirthYear,
      dependentMemberId,
    } = dependentFields;

    dependent = {
      dependentMemberId,
      dependentFirstName,
      dependentLastName,
      dependentDateOfBirth: formatBirthDate({
        month: dependentBirthMonth!,
        day: dependentBirthDay!,
        year: dependentBirthYear!,
      }),
    };
  }

  return {
    onederfulPayerId,
    primaryFirstName,
    primaryLastName,
    primaryMemberId,
    groupNumber,
    primaryDateOfBirth: formatBirthDate({
      month: primaryBirthMonth,
      day: primaryBirthDay,
      year: primaryBirthYear,
    }),
    ...(isDependent && dependent),
  };
};

export const saveInsurance = (payload: InsuranceForm) => async (
  dispatch: Dispatch<any>,
  getState: Store['getState']
) => {
  dispatch(InsuranceActions.saveInsuranceRequest());
  const customerId = getCustomerData(getState()).id;
  const formData = transformFormDataToPayload(payload);
  const userHasInsurance = Boolean(
    getInsuranceForm(getState())?.onederfulPayerId
  );
  const membershipUrl = `/api/v1/membership/${
    userHasInsurance ? customerId : ''
  }`;
  const method = userHasInsurance ? RequestMethods.PUT : RequestMethods.POST;

  try {
    const resp = await fetchApiWithPromise(membershipUrl, method, {
      ...formData,
      customer: customerId,
    });

    const data: InsuranceRequestPayload = resp.data;
    dispatch(InsuranceActions.saveInsuranceSuccess(data));
    dispatch(getEstimatedClaim(customerId));

    if (data.activePlanData?.errors) {
      throw data.activePlanData.errors;
    }
  } catch (err) {
    dispatch(InsuranceActions.saveInsuranceFailure(parseInsuranceErrors(err)));
  }
};

export const parseInsuranceErrors = (err: Array<OnederfulError> | any) => {
  if (Array.isArray(err)) {
    const { reason, followup } = err[0];
    return [reason, followup].join('. ');
  } else {
    return "There's been a server error. Please contact engineering support.";
  }
};

export const getEstimatedClaim = (customerId: number) => async (
  dispatch: Dispatch
) => {
  const skus = [ProductSkus.deprecatedAlignerKitDentalMonitoring, ProductSkus.deprecatedAlignerKitDentalMonitoringTY, ProductSkus.AlignerKitDentalMonitoring, ProductSkus.AlignerKitTeenAndYouth];

  const requestPayload = {
    customerId,
    skus,
  };

  try {
    const resp = await fetchApiWithPromise(
      `/api/v1/calculate-estimated-claims/`,
      RequestMethods.POST,
      requestPayload
    );
    const data = resp.data;
    const claims = Object.keys(data).length ? data : null;
    dispatch(InsuranceActions.checkEligibility(claims));
    dispatch(InsuranceActions.setInNetwork(Boolean(claims)));
  } catch (err) {
    console.log(err);
  }
};

export type InsuranceActions = ActionsUnion<typeof InsuranceActions>;
