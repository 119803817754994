import { withState, compose, mapProps } from 'recompose';
import { path, identity } from 'ramda';
import { getNewToken } from 'modules/user/api';
import * as storage from 'modules/user/storage';

export default (fn, key) =>
  hoc(fn, key, () => next => async (...args) => {
    try {
      return await next(...args);
    } catch (err) {
      if (isTokenInvalid(err)) {
        try {
          const res = await getNewToken(storage.getRefreshToken());
          storage.signIn(res.access_token, res.refresh_token);

          return await next(...args);
        } catch (err) {
          storage.signOut();
          document.location.href = '/login';
        }
      }

      throw err;
    }
  });

const hoc = (fn, key, propsToMiddleware = () => identity) =>
  compose(
    withState(key, 'updateState', {}),
    mapProps(({ updateState, ...props }) => {
      const request = propsToMiddleware(props)(fn);

      return {
        ...props,
        [key]: {
          ...props[key],
          // Use curryN, relying on `fn.length`?
          update: fn => {
            updateState({
              ...props[key],
              data: fn(props[key].data),
            });
          },
          fetch: async (...args) => {
            updateState(state => ({
              ...state,
              isFetching: true,
            }));
            try {
              const data = await request(...args);
              updateState(state => ({
                ...state,
                isFetching: false,
                lastUpdated: Date.now(),
                error: void 0,
                data,
              }));

              return data;
            } catch (err) {
              updateState(state => ({
                ...state,
                isFetching: false,
                error: err,
              }));
              throw err;
            }
          },
        },
      };
    })
  );

const isTokenInvalid = err =>
  path(['response', 'status'], err) === 401 &&
  path(['response', 'data', 'code'], err) === 'token_not_valid';
