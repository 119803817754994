import React from 'react';

export const AddressStatic = ({
  addressLine1,
  addressLine2,
  city,
  firstName,
  lastName,
  phone,
  state,
  zip,
  country,
}: {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  country: string;
}) => (
  <div>
    <div className="mt2" data-private data-dd-privacy="mask">
      {firstName} {lastName}
    </div>
    <div className="mt2" data-private data-dd-privacy="mask">
      {addressLine1} {addressLine2}
    </div>
    <div className="mt2">
      {city}, {state} {zip}
    </div>
    <div className="mt2" data-private data-dd-privacy="mask">{phone}</div>
    <div className="mt2">{country}</div>
  </div>
);
