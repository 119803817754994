import styled, { css } from 'styled-components';
import { hideVisually } from 'polished';
import { colors, mediaQueries } from '@candidco/enamel';

export const TabContainer = styled.nav`
  display: flex;
  width: 100%;
  height: 2rem;
  margin-top: 16px;
  margin-bottom: ${({ hasBottomMargin }) => hasBottomMargin && '1rem'};
  border-bottom: 1px solid ${colors.black20};

  ${({ disabledBreakpoint }) =>
    disabledBreakpoint &&
    css`
      @media ${mediaQueries[disabledBreakpoint]} {
        display: none;
      }
    `};
`;

export const Tab = styled.button`
  height: 100%;
  padding: 0.5rem 1rem 0.375rem;
  margin-right: 8px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${colors.white};
      background: #357edd;
      border-top-right-radius: 0.375rem;
      border-top-left-radius: 0.375rem;
    `};
`;

export const TabContent = styled.div`
  ${({ disabledBreakpoint, isVisible }) =>
    disabledBreakpoint
      ? css`
          @media not all and ${mediaQueries[disabledBreakpoint]} {
            ${!isVisible && hideVisually()};
          }
        `
      : !isVisible && hideVisually()};
`;
