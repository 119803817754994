export const formatPrice = (n: number): number | string =>
  n.toFixed(2).replace(/\.00$/, '');

const DEFAULT_ERROR_MESSAGE = 'Sorry! Something unexpected happened';

/**
 * @param {string | Error} err
 * @param {string} defaultMessage
 */
export const getMessageFromError = (
  err: Error,
  defaultMessage: string = DEFAULT_ERROR_MESSAGE
): string => {
  let message;

  if (err instanceof Error) {
    message = err.message;
  }

  if (typeof err === 'string') {
    message = err;
  }

  return message || defaultMessage;
};

export const getMessageFromErrorNested = (
  err: object | string,
  defaultMessage: string = DEFAULT_ERROR_MESSAGE
) => {
  let message;
  if (err === '') {
    return defaultMessage;
  }
  if (typeof err === 'object') {
    message = Object.values(err).reduce(
      (agg, curr) => (agg += JSON.stringify(curr)),
      ''
    );
  }

  if (typeof err === 'string') {
    message = err;
  }

  return message.replace(/['"]+/g, '');
};

/**
 * titleCaseScreamingSnake
 * skus transformed to human readable title case format
 *
 * @example
 *  "ALIGNER_KIT"
 *  => "Aligner Kit"
 */
export const titleCaseScreamingSnake = (productName: string) => {
  return productName
    .toLowerCase()
    .split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const isUpperCase = (str: string) => {
  return str === str.toUpperCase();
};

export const camelToSnakeCase = (str: string) => {
  // We don't need to break up all-upper-case words
  // i.e. AK0001 needs to remain intact, not become A_K_0001
  if (isUpperCase(str)) {
    return str;
  }
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

export const objectToSnakeCase = (obj: object) => {
  const transformedEntries:{[key: string]: any}[] = Object.entries(obj).map(([string, value]) => {
    if (Array.isArray(value)) {
      return {[camelToSnakeCase(string)]: value};
    }
    if (typeof value === "object") {
      return {[camelToSnakeCase(string)]: objectToSnakeCase(value)};
    }
    return {[camelToSnakeCase(string)]: value};
  });

  return Object.assign({}, ...transformedEntries);
};