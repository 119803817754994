import { Dispatch } from 'redux';
import { RequestMethods, fetchApiWithPromise } from 'core/conceptions/api';
import { createAction, ActionsUnion } from 'store/action';
import { CustomerResponse, AddressInputCamelCase } from './types';
import { getMessageFromErrorNested } from 'core/util';
import { segment } from 'core/integrations';

export enum CustomerAction {
  CUSTOMER_REQUEST = 'CUSTOMER_REQUEST',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
  CUSTOMER_FAILURE = 'CUSTOMER_FAILURE',

  UPDATE_SHIPPING_ADDRESS_REQUEST = 'UPDATE_SHIPPING_ADDRESS_REQUEST',
  UPDATE_SHIPPING_ADDRESS_SUCCESS = 'UPDATE_SHIPPING_ADDRESS_SUCCESS',
  UPDATE_SHIPPING_ADDRESS_FAILURE = 'UPDATE_SHIPPING_ADDRESS_FAILURE',
}

export const CustomerActions = {
  requestCustomer: () => createAction(CustomerAction.CUSTOMER_REQUEST),
  successCustomer: (customer: CustomerResponse) =>
    createAction(CustomerAction.CUSTOMER_SUCCESS, { customer }),
  failureCustomer: (errorMessage: any) =>
    createAction(CustomerAction.CUSTOMER_FAILURE, { errorMessage }),

  requestUpdateShipping: () =>
    createAction(CustomerAction.UPDATE_SHIPPING_ADDRESS_REQUEST),
  successUpdateShipping: (address: AddressInputCamelCase) =>
    createAction(CustomerAction.UPDATE_SHIPPING_ADDRESS_SUCCESS, {
      address,
    }),
  failureUpdateShipping: (errorMessage: any) =>
    createAction(CustomerAction.UPDATE_SHIPPING_ADDRESS_FAILURE, {
      errorMessage,
    }),
};

export type CustomerActions = ActionsUnion<typeof CustomerActions>;

export const loadCustomer = (customerId: string) => async (
  dispatch: Dispatch
) => {
  dispatch(CustomerActions.requestCustomer());
  try {
    const resp = await fetchApiWithPromise(
      `/api/v1/customers/${customerId}/`,
      RequestMethods.GET
    );
    dispatch(CustomerActions.successCustomer(resp.data));
    segment.track('loadCustomer');
  } catch (e) {
    dispatch(
      CustomerActions.failureCustomer(
        getMessageFromErrorNested(e.response.data)
      )
    );
  }
};

export const updateCustomerShipping = (
  customerId: string,
  address: AddressInputCamelCase
) => async (dispatch: Dispatch) => {
  dispatch(CustomerActions.requestUpdateShipping());
  try {
    const { addressLine2, ...rest } = address;
    await fetchApiWithPromise(
      `/api/v1/users/${customerId}/update-shipping-address`,
      RequestMethods.PUT,
      {
        shippingAddress: {
          addressLine2: addressLine2 || '',
          ...rest,
          country: 'US',
        },
      }
    );
    dispatch(CustomerActions.successUpdateShipping(address));
    segment.track('updateCustomerShipping');
  } catch (e) {
    dispatch(
      CustomerActions.failureUpdateShipping(
        getMessageFromErrorNested(e.response.data)
      )
    );
  }
};
