import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Success from 'components/common/Success';
import Search from './Search';
import Checkout from './Checkout';

export default ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={Search} />
    <Route exact path={`${match.url}/:id`} component={Checkout} />
    <Route exact path={`${match.url}/:id/success`} component={Success} />
  </Switch>
);
