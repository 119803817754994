export const AK_PRICE = 1900;
export const MAX_PAYMENTS = 3;
export const AK_MIN_FIRST_PAYMENT_AMOUNT = 100;
export const AK_SKU = 'AK00001';
export const ALIGNER_GOOD = 'ALIGNER_GOOD';
export const MAX_QUANTITY = 16;
export const MAX_MONTH_DAYS = 31;

export const ORDER_STATES = {
  OPEN: 0,
  PAID: 1,
  CANCELLED: 2,
  COMPLETE: 3,
  FULFILLED: 4,
  PROCESSING: 5,
};

export enum ORDER_FINANCIAL_STATES {
  PENDING = -1,
  FINANCIAL_PAID = 0,
  PARTIALLY_REFUNDED = 1,
  REFUNDED = 2,
}

export const PRODUCT_TYPES = {
  MODELING_KIT: 'MODELIG_KIT',
  ALIGNERS_KIT: 'ALIGNER_KIT',
};

export const PAYMENT_GATEWAYS = {
  STRIPE: 'stripe',
  AFFIRM: 'affirm',
  ALLEGRO: 'allegro',
};

export enum LOAD_STATES {
  UNDEFINED = 'UNDEFINED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum FEATURE_KEYS {
  ALLEGRO_FINANCING = 'use_allegro_financing',
}

export const CASE_TYPES = {
  STANDALONE_WHITENING: "standalone_whitening",
};
