import { Dispatch, Store } from 'redux';
import { RequestMethods, fetchApiWithPromise } from 'core/conceptions/api';
import allSettled from 'promise.allsettled';
import {
  OrderResponse,
  GenericCheckout,
  AffirmCheckoutResponse,
  AllegroCheckoutResponse,
  PaymentHistory,
  FinancingGateway,
  CheckoutEligibilityResponse,
} from './types';
import { createAction, ActionsUnion } from 'store/action';
import { segment } from 'core/integrations';
import { ProductSkus } from 'store/products/types';
import { getMessageFromErrorNested, objectToSnakeCase } from 'core/util';
import { Address } from 'store/customer/types';
import {
  getAllegroInitiatedApplications,
  getActiveAKOrder,
} from 'store/payment/index';
import { getCouponCode } from 'store/coupon';
import { getCatalogMap, getSelectedProductSKU } from 'store/products';
import { getSelectedCase } from 'store/cases';
import { getCustomerData } from 'store/customer';
import { PaymentGateway } from 'store/payment/types';
import { CartActions } from 'store/cart/actions';
import { getCart } from 'store/cart';
import { ALLEGRO_MAX_PAYMENT } from './constants';
import { ProductActions } from 'store/products/actions';
import { getShippingAddressFormSubmitRef, getSelectedShippingAddress, getSelectedShippingAddressType, getSentPatientShippingUpdate } from 'store/cart';

export enum PaymentAction {
  FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST',
  FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE',

  CHECKOUT_REQUEST = 'CHECKOUT_REQUEST',
  CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS',
  CHECKOUT_FAILURE = 'CHECKOUT_FAILURE',

  FETCH_EXPERIMENT_REQUEST = 'FETCH_EXPERIMENT_REQUEST',
  FETCH_EXPERIMENT_SUCCESS = 'FETCH_EXPERIMENT_SUCCESS',

  FETCH_FINANCING_OPTIONS_REQUEST = 'FETCH_FINANCING_OPTIONS_REQUEST',
  FETCH_FINANCING_OPTIONS_SUCCESS = 'FETCH_FINANCING_OPTIONS_SUCCESS',
  FETCH_FINANCING_OPTIONS_FAILURE = 'FETCH_FINANCING_OPTIONS_FAILURE',

  AFFIRM_CHECKOUT_REQUEST = 'AFFIRM_CHECKOUT_REQUEST',
  AFFIRM_CHECKOUT_SUCCESS = 'AFFIRM_CHECKOUT_SUCCESS',
  AFFIRM_CHECKOUT_FAILURE = 'AFFIRM_CHECKOUT_FAILURE',

  ALLEGRO_CHECKOUT_REQUEST = 'ALLEGRO_CHECKOUT_REQUEST',
  ALLEGRO_CHECKOUT_SUCCESS = 'ALLEGRO_CHECKOUT_SUCCESS',
  ALLEGRO_CHECKOUT_FAILURE = 'ALLEGRO_CHECKOUT_FAILURE',
  RESET_ALLEGRO_CHECKOUT = 'RESET_ALLEGRO_CHECKOUT',
  
  FINALIZE_FINANCING_REQUEST = 'FINALIZE_FINANCING_REQUEST',
  FINALIZE_FINANCING_SUCCESS = 'FINALIZE_FINANCING_SUCCESS',
  FINALIZE_FINANCING_FAILURE = 'FINALIZE_FINANCING_FAILURE',
  
  FETCH_PAYMENT_HISTORY_REQUEST = 'FETCH_PAYMENT_HISTORY_REQUEST',
  FETCH_PAYMENT_HISTORY_SUCCESS = 'FETCH_PAYMENT_HISTORY_SUCCESS',
  FETCH_PAYMENT_HISTORY_FAILURE = 'FETCH_PAYMENT_HISTORY_FAILURE',
  RESET_PAYMENT_HISTORY = 'RESET_PAYMENT_HISTORY',
  
  SELECT_ORDER = 'SELECT_ORDER',
  ENABLE_MULTI_ORDER_ITEM = 'ENABLE_MULTI_ORDER_ITEM',
  CLEAR_ORDER = 'CLEAR_ORDER',
  
  DRAFT_GENERIC_CHECKOUT = 'DRAFT_GENERIC_CHECKOUT',
  CLEAR_DRAFT_GENERIC_CHECKOUT = 'CLEAR_DRAFT_GENERIC_CHECKOUT',
  GENERIC_CHECKOUT_FAILURE = 'GENERIC_CHECKOUT_FAILURE',
}

export const PaymentActions = {
  fetchOrdersRequest: () => createAction(PaymentAction.FETCH_ORDERS_REQUEST),
  draftGenericCheckout: (data: GenericCheckout) => createAction(PaymentAction.DRAFT_GENERIC_CHECKOUT, {data}),
  fetchOrdersSuccess: (orders: OrderResponse[]) =>
    createAction(PaymentAction.FETCH_ORDERS_SUCCESS, {
      orders,
    }),
  fetchOrdersFailure: (errorMessage: any) =>
    createAction(PaymentAction.FETCH_ORDERS_FAILURE, { errorMessage }),

  checkoutRequest: () => createAction(PaymentAction.CHECKOUT_REQUEST),
  checkoutSuccess: () => createAction(PaymentAction.CHECKOUT_SUCCESS),
  checkoutFailure: (errorMessage: any) =>
    createAction(PaymentAction.CHECKOUT_FAILURE, { errorMessage }),

  fetchExperimentRequest: () =>
    createAction(PaymentAction.FETCH_EXPERIMENT_REQUEST),
  fetchExperimentSuccess: ({
    featureKey,
    isFeatureEnabled,
  }: {
    featureKey: string;
    isFeatureEnabled: boolean;
  }) => {
    return createAction(PaymentAction.FETCH_EXPERIMENT_SUCCESS, {
      featureKey,
      isFeatureEnabled,
    });
  },

  setFinancingOptionsRequest: () =>
    createAction(PaymentAction.FETCH_FINANCING_OPTIONS_REQUEST),
  setFinancingOptionsSuccess: (options: PaymentGateway[]) =>
    createAction(PaymentAction.FETCH_FINANCING_OPTIONS_SUCCESS, options),
  setFinancingOptionsFailure: () =>
    createAction(PaymentAction.FETCH_FINANCING_OPTIONS_FAILURE),

  affirmCheckoutRequest: () =>
    createAction(PaymentAction.AFFIRM_CHECKOUT_REQUEST),
  affirmCheckoutSuccess: (affirm: AffirmCheckoutResponse) =>
    createAction(PaymentAction.AFFIRM_CHECKOUT_SUCCESS, { affirm }),
  affirmCheckoutFailure: (errorMessage: any) =>
    createAction(PaymentAction.AFFIRM_CHECKOUT_FAILURE, { errorMessage }),

  allegroCheckoutRequest: () =>
    createAction(PaymentAction.ALLEGRO_CHECKOUT_REQUEST),
  allegroCheckoutSuccess: (allegro: AllegroCheckoutResponse) =>
    createAction(PaymentAction.ALLEGRO_CHECKOUT_SUCCESS, { allegro }),
  allegroCheckoutFailure: (errorMessage: any) =>
    createAction(PaymentAction.ALLEGRO_CHECKOUT_FAILURE, { errorMessage }),
  resetAllegroCheckout: () =>
    createAction(PaymentAction.RESET_ALLEGRO_CHECKOUT),

  finalizeFinancingRequest: () =>
    createAction(PaymentAction.FINALIZE_FINANCING_REQUEST),
  finalizeFinancingSuccess: () =>
    createAction(PaymentAction.FINALIZE_FINANCING_SUCCESS),
  finalizeFinancingFailure: () =>
    createAction(PaymentAction.FINALIZE_FINANCING_FAILURE),

  fetchPaymentHistoryRequest: () =>
    createAction(PaymentAction.FETCH_PAYMENT_HISTORY_REQUEST),
  fetchPaymentHistorySuccess: (paymentHistory: PaymentHistory) =>
    createAction(PaymentAction.FETCH_PAYMENT_HISTORY_SUCCESS, {
      paymentHistory,
    }),
  fetchPaymentHistoryFailure: (errorMessage: any) =>
    createAction(PaymentAction.FETCH_PAYMENT_HISTORY_FAILURE, {
      errorMessage,
    }),
  resetPaymentHistory: () => createAction(PaymentAction.RESET_PAYMENT_HISTORY),

  // multi-item order support
  selectOrder: (orderId: string) =>
    createAction(PaymentAction.SELECT_ORDER, {
      orderId,
    }),
  clearOrder: () => createAction(PaymentAction.CLEAR_ORDER),
  clearDraftGenericCheckout: () => createAction(PaymentAction.CLEAR_DRAFT_GENERIC_CHECKOUT),
  genericCheckoutFailure: (errorMessage: any) =>
    createAction(PaymentAction.GENERIC_CHECKOUT_FAILURE, { errorMessage }),
  enableMultiOrderItem: (enableMultiOrderItem: boolean) =>
    createAction(PaymentAction.ENABLE_MULTI_ORDER_ITEM, {
      enableMultiOrderItem,
    }),
};

export type PaymentActions = ActionsUnion<typeof PaymentActions>;

const isAKSku = new RegExp('AK[0]{4}[1-9]');

export const fetchOrdersForCustomer = (
  customerId: string,
  shouldSetSelectedOrder: boolean = false
) => async (dispatch: Dispatch) => {
  dispatch(PaymentActions.fetchOrdersRequest());
  dispatch(PaymentActions.enableMultiOrderItem(shouldSetSelectedOrder));
  try {
    const resp = await fetchApiWithPromise(
      `/api/v1/orders/?customer_id=${customerId}`,
      RequestMethods.GET
    );
    dispatch(PaymentActions.fetchOrdersSuccess(resp.data));
    if (shouldSetSelectedOrder && resp.data.length > 0) {
      const order = resp.data[0]
      const hasAK = order?.product_types_in_order.includes('ALIGNER_KIT');
      const skuToSelect = hasAK
        ? order.skus_in_order.find((sku: string) => isAKSku.test(sku))
        : order.skus_in_order[0];
      dispatch(PaymentActions.selectOrder(order.id));
      dispatch(ProductActions.selectProduct(skuToSelect));
    }
    segment.track('fetchOrdersForCustomer');
  } catch (e) {
    const error = getMessageFromErrorNested(e.response.data);
    segment.trackError('fetchOrdersForCustomer', { error });
    dispatch(PaymentActions.fetchOrdersFailure(error));
  }
};

const hasBillingAddressValues = (address: Address | undefined) => {
  if (!address) {
    return false;
  }

  return Object.values(address).some(Boolean);
};

export const preGenericCheckout = (data: GenericCheckout) => async (dispatch: Dispatch, getState: Store['getState']
) => {
  // Draft generic checkout data to be sent to the backend
  dispatch(PaymentActions.draftGenericCheckout(data))

  // If shipping address type is other, then we need to validate the address
  if (getSelectedShippingAddressType(getState()) === 'other'){
    const shipping_address_confirm_ref = getShippingAddressFormSubmitRef(getState());
    // We submit the shipping address form to trigger validation, actual checkout call will be trigger there
    if (shipping_address_confirm_ref) {
      shipping_address_confirm_ref?.click();
    }
    return
  }
  genericCheckout(data)(dispatch, getState)
}

export const genericCheckout = ({
  orderId,
  email,
  token,
  first_name,
  last_name,
  phone,
  shipping_address,
  billing_address,
  items,
  couponCode,
  payment_amount_in_dollars,
  couponValue = '',
  caseRef,
  caseType,
  metadata = {},
  userId,
  referring_dentist,
  brand,
}: GenericCheckout) => async (dispatch: Dispatch, getState: Store['getState']
) => {
  dispatch(PaymentActions.checkoutRequest());
  // Generic checkout needs a billing address; if none is provided, set
  // billing to shipping address
  const validBillingAddress = hasBillingAddressValues(billing_address)
  ? billing_address
  : shipping_address;
  
  const selectedShippingAddress = getSelectedShippingAddress(getState());
  const validShippingAddress = Object.keys(selectedShippingAddress).length === 0 ? shipping_address : selectedShippingAddress;
  
  try {
    const payload = {
      email,
      source_token: token,
      payment_channel: 'invoice',
      first_name,
      last_name,
      phone,
      items,
      shipping_address: validShippingAddress,
      billing_address: validBillingAddress,
      ...(orderId && { order_id: orderId }),
      ...(couponCode && { coupon_code: couponCode, coupon_value: couponValue }),
      payment_amount_in_dollars,
      case_ref: caseRef,
      case_type: caseType,
      metadata: objectToSnakeCase(metadata),
      shipping_address_type: getSelectedShippingAddressType(getState()),
      sent_patient_shipping_update: getSentPatientShippingUpdate(getState()),
      referring_dentist: referring_dentist,
      brand: brand, 
    };

    await fetchApiWithPromise(
      `/api/v1/checkout/`,
      RequestMethods.POST,
      payload
    );
    dispatch(PaymentActions.checkoutSuccess());
    dispatch(CartActions.setNewOrder(false));
    dispatch(CartActions.clearShippingAddressSelection());
    dispatch(PaymentActions.clearDraftGenericCheckout());
    segment.track('genericCheckout', { couponCode, items });
  } catch (e) {
    const error = getMessageFromErrorNested(e.response.data);
    dispatch(PaymentActions.checkoutFailure(error));
    segment.trackError('genericCheckout - checkoutFailure', {
      couponCode,
      items,
      error,
    });
  }
};

export const affirmCheckout = (amountToPay: number) => async (
  dispatch: Dispatch<any>,
  getState: Store['getState']
) => {
  dispatch(PaymentActions.affirmCheckoutRequest());
  const state = getState();
  const catalog = getCatalogMap(state)
  const case_type = getSelectedCase(state)?.case_type!;
  const case_ref = getSelectedCase(state)?.case_ref!;
  const {
    braze_id: external_id,
    first_name,
    last_name,
    phone,
    email,
    shipping_address,
    id: customerId,
  } = state.customer.data;
  const userPayload = {
    shipping_address,
    external_id,
    phone,
    first_name,
    last_name,
    email,
    // Sales portal-specific
    payment_channel: 'telesale',
    payment_amount_in_dollars: amountToPay, // Partial payment
    customer_id: customerId,
    case_ref,
    case_type: case_type.name,
  };

  const couponCode = getCouponCode(state);
  const sku = getSelectedProductSKU(state);
  const activeAKOrder = getActiveAKOrder(state);
  const cartItems = getCart(state);
  let items = [];
  if (activeAKOrder) {
    items = activeAKOrder.orderitems.map(orderItem => ({
      sku: orderItem.product_sku,
      quantity: orderItem.quantity,
    }));
  } else {
    items = cartItems.map(cartItem => ({
      sku: cartItem.sku,
      quantity: cartItem.quantity,
    }));
  }

  const hasAKInOrder = items.some(
    item => catalog[item.sku]?.alignerCheckout || false
  );
  const hasAlignerGoodInOrder = items.some(
    item => item.sku === ProductSkus.AlignerGood
  );
  if (hasAKInOrder && !hasAlignerGoodInOrder) {
    items = [...items, { sku: ProductSkus.AlignerGood, quantity: 1 }];
  }

  const requestPayload = {
    ...userPayload,
    ...(couponCode && { coupon_code: couponCode }),
    items,
    gateway: 'affirm',
  };

  try {
    const resp = await fetchApiWithPromise(
      '/api/v1/staff-checkout/initiate_financing/',
      RequestMethods.POST,
      requestPayload
    );
    dispatch(PaymentActions.affirmCheckoutSuccess(resp.data));
    dispatch(CartActions.setNewOrder(false));
    segment.track('affirmCheckout', {
      couponCode,
      sku,
      paymentAmountInDollars: amountToPay,
      response: { ...resp },
    });
  } catch (e) {
    const error = e.response.data.error || getMessageFromErrorNested(e.response.data);
    segment.trackError('affirmCheckout', { error });
    dispatch(PaymentActions.affirmCheckoutFailure(error));
  }
};

export const allegroCheckout = (amountToPay: number) => async (
  dispatch: Dispatch<any>,
  getState: Store['getState']
) => {
  dispatch(PaymentActions.allegroCheckoutRequest());
  const state = getState();
  const catalog = getCatalogMap(state);
  const case_ref = getSelectedCase(state)?.case_ref!;
  const case_type = getSelectedCase(state)?.case_type!;
  const {
    braze_id: external_id,
    first_name,
    last_name,
    phone,
    email,
    shipping_address,
    id: customerId,
  } = state.customer.data;
  const userPayload = {
    shipping_address,
    external_id,
    phone,
    first_name,
    last_name,
    email,
    // Sales portal-specific
    payment_channel: 'telesale',
    payment_amount_in_dollars: Math.min(amountToPay, ALLEGRO_MAX_PAYMENT), // Partial payment
    customer_id: customerId,
    case_ref,
    case_type: case_type.name,
  };

  const couponCode = getCouponCode(state);
  const sku = getSelectedProductSKU(state);
  const activeAKOrder = getActiveAKOrder(state);
  const cartItems = getCart(state);
  let items = [];
  if (activeAKOrder) {
    items = activeAKOrder.orderitems.map(orderItem => ({
      sku: orderItem.product_sku,
      quantity: orderItem.quantity,
    }));
  } else {
    items = cartItems.map(cartItem => ({
      sku: cartItem.sku,
      quantity: cartItem.quantity,
    }));
  }

  const hasAKInOrder = items.some(
    item => catalog[item.sku]?.alignerCheckout || false
  );
  const hasAlignerGoodInOrder = items.some(
    item => item.sku === ProductSkus.AlignerGood
  );
  if (hasAKInOrder && !hasAlignerGoodInOrder) {
    items = [...items, { sku: ProductSkus.AlignerGood, quantity: 1 }];
  }

  const requestPayload = {
    ...userPayload,
    ...(couponCode && { coupon_code: couponCode }),
    items,
    gateway: 'allegro',
  };

  try {
    const resp = await fetchApiWithPromise(
      '/api/v1/staff-checkout/initiate_financing/',
      RequestMethods.POST,
      requestPayload
    );
    const allegroResponse = resp.data;
    dispatch(PaymentActions.allegroCheckoutSuccess(allegroResponse));
    dispatch(CartActions.setNewOrder(false));
    dispatch(fetchOrdersForCustomer(customerId));
    segment.track('allegroCheckout', {
      customerId,
      couponCode,
      sku,
      paymentAmountInDollars: amountToPay,
      response: {
        ...resp,
      },
    });
  } catch (e) {
    const error = getMessageFromErrorNested(e.response.data);
    dispatch(PaymentActions.allegroCheckoutFailure(error));
    segment.trackError('allegroCheckout', { error });
  }
};

export const checkoutEligibility = () => async (
  dispatch: Dispatch,
  getState: Store['getState']
) => {
  const customer = getCustomerData(getState());
  dispatch(PaymentActions.setFinancingOptionsRequest());

  try {
    /**
     * This eligibility check returns either ['affirm'] or ['allegro']
     * Compile the list of available payment options with affirm as the always available default
     */
    const resp = await fetchApiWithPromise<CheckoutEligibilityResponse>(
      `/api/v1/checkout-eligibility/${customer.id}/`,
      RequestMethods.GET
    );

    const options = [];
    if (resp.data.financing.includes(FinancingGateway.allegro)) {
      options.push(PaymentGateway.allegro);
    }
    dispatch(PaymentActions.setFinancingOptionsSuccess(options));
  } catch (error) {
    segment.trackError('getAllegroFinancingFeature', { error });
    dispatch(PaymentActions.setFinancingOptionsFailure());
  }
};

const fetchPaymentHistoryAPI = (orderId: string) => {
  return fetchApiWithPromise(
    `/api/v1/orders/${orderId}/get_payments_list/`,
    RequestMethods.GET
  );
};

export const fetchPaymentHistory = (orderId: string) => async (
  dispatch: Dispatch
) => {
  dispatch(PaymentActions.fetchPaymentHistoryRequest());
  try {
    const resp = await fetchPaymentHistoryAPI(orderId);

    dispatch(PaymentActions.fetchPaymentHistorySuccess(resp.data));
    segment.track('fetchPaymentHistory', {
      orderId,
      paymentHistory: {
        ...resp.data,
      },
    });
  } catch (e) {
    const error = getMessageFromErrorNested(e.response.data);
    dispatch(PaymentActions.fetchPaymentHistoryFailure(error));
    segment.trackError('fetchPaymentHistory', { error });
  }
};

export const attemptFinalizeAllegroApps = () => async (
  dispatch: Dispatch<any>,
  getState: Store['getState']
) => {
  dispatch(PaymentActions.finalizeFinancingRequest());
  const initiatedAllegroApplications = getAllegroInitiatedApplications(
    getState()
  );
  const activeAKOrder = getActiveAKOrder(getState());
  const orderNumber = activeAKOrder?.order_number;
  const customerId = activeAKOrder!.customer_id;

  const finalizeAllAllegroApps = initiatedAllegroApplications!.map(
    ({ ref: applicationId }) =>
      finalizeAllegroAPI({
        orderNumber,
        applicationId,
      })
  );

  segment.track('attemptFinalizeAllegroApps', {
    initiatedAllegroApplications,
  });

  allSettled(finalizeAllAllegroApps).then((results: { status: string }[]) => {
    const hasSuccessResponse = results.some((result: { status: string }) => {
      return result.status === 'fulfilled';
    });

    if (hasSuccessResponse) {
      dispatch(PaymentActions.finalizeFinancingSuccess());
      dispatch(fetchOrdersForCustomer(customerId));
      segment.track('finalizeFinancingSuccess');
      return;
    } else {
      dispatch(PaymentActions.finalizeFinancingFailure());
      segment.trackError('finalizeFinancingFailure', {
        error: 'Error finalizing',
      });
    }
  });
};

export const finalizeAllegroAPI = async ({
  orderNumber,
  applicationId,
}: {
  orderNumber: number | undefined;
  applicationId: string;
}) => {
  const requestPayload = {
    order_ref: orderNumber,
    application_id: applicationId,
  };

  const resp = await fetchApiWithPromise(
    '/api/v1/finalize-allegro-application/',
    RequestMethods.POST,
    requestPayload
  );

  return resp;
};

export const finalizeAllegro = (orderId: string) => async (
  dispatch: Dispatch<any>
) => {
  try {
    // Fetch updated payment history to search for finalized allegro payment first
    dispatch(PaymentActions.fetchPaymentHistoryRequest());

    const resp = await fetchPaymentHistoryAPI(orderId);
    dispatch(PaymentActions.fetchPaymentHistorySuccess(resp.data));
    const hasAllegroCompletedApplication = resp.data.initiated_financing_applications.some(
      ({ payment_id }: { payment_id: string | undefined }) => {
        return Boolean(payment_id);
      }
    );

    // If user has a completed application show success state
    if (hasAllegroCompletedApplication) {
      dispatch(PaymentActions.finalizeFinancingSuccess());
      return;
    }

    // User doesn't have a complete application, start finalizing api requests
    // for each application id
    dispatch(attemptFinalizeAllegroApps());
  } catch (e) {
    dispatch(
      PaymentActions.fetchPaymentHistoryFailure(
        getMessageFromErrorNested(e.response.data)
      )
    );
  }
};
