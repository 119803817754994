import React from 'react';
import { Form, Field } from 'react-final-form';
import { Input } from 'components/generic/form';
import { Button } from 'components/generic/kit';

export default ({ isRequesting, isExpired, error, validate, onSubmit }) => (
  <div className="flex items-center justify-center flex-column min-h-100 pv3">
    <div className="f3 fw6 mb2" data-cy="login-container">
      Log in
    </div>
    <div className="measure f4-ns lh-copy w-100 pa0-l pa3">
      {isExpired && (
        <div className="red f6 tc mb1">
          Your token was expired, please sign in again
        </div>
      )}
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb3" data-cy="login-email">
              <Field
                name="email"
                label="Email"
                type="email"
                required
                component={Input}
              />
            </div>
            <div className="mb3" data-cy="login-password">
              <Field
                name="password"
                label="Password"
                type="password"
                required
                component={Input}
              />
            </div>
            {error && <div className="tc red f6">{error}</div>}
            <div className="tc mt3" data-cy="login-submit">
              <Button>{isRequesting ? 'Signing in...' : 'Sign in'}</Button>
            </div>
          </form>
        )}
      </Form>
    </div>
  </div>
);
