import { combineReducers, AnyAction, CombinedState } from 'redux';
import customer from './customer';
import payment from './payment';
import coupon from './coupon';
import products from './products';
import cases from './cases';
import insurance from './insurance';
import cart from './cart';
import { AppAction } from './action';
import { CartState } from 'store/cart/types';

const appReducer = combineReducers({
  // Add global window reducers here
  customer,
  payment,
  coupon,
  products,
  cases,
  insurance,
  cart,
});

type appReducers = {
  customer: any;
  payment: any;
  coupon: any;
  products: any;
  cases: any;
  insurance: any;
  cart: CartState;
};

export default (
  state: CombinedState<appReducers> | undefined,
  action: AnyAction
) => {
  if (action.type === AppAction.RESET_STORE) {
    state = undefined;
  }

  return appReducer(state, action);
};
