import styled from 'styled-components/macro';
import {
  mediaQueries,
  type,
  theme,
  Text,
  Avatar,
} from '@candidco/enamel';

export const FormContainer = styled.div`
  max-width: 640px;
`;

export const FormRow = styled.div`
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 2.5rem;
  }

  p {
    margin-bottom: 0;
  }
`;

export const RadioGroup = styled.div`
  margin-top: 1.25rem;

  @media ${mediaQueries.tabletAndAbove} {
    width: 50%;
  }
`;

export const Label = styled.label`
  display: block;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const Checkbox = styled.input`
  margin-top: 0.125rem;
  margin-right: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
  appearance: checkbox;
`;

export const QuestionHeading = styled(type.H4)`
  margin-bottom: 1rem;
`;

export const Card = styled.div<{ selected?: boolean }>`
  text-align: center;
  border: 1px solid
    ${({ selected }) => (selected ? theme.colors.blue50 : theme.colors.black20)};
  border-radius: 4px;
  padding: 32px 16px;
  margin-bottom: 2rem;
  gap: 40px;
`;

export const StyledImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
`;

export const TextLine = styled(Text)`
  font-size: 0.875rem;
  margin-bottom: 0px;
`;

export const BoldLine = styled.h4`
  margin-bottom: 0px;
`;

export const ContactAvatar = styled(Avatar)`
  width: 67px;
  height: 67px;
  background-color: ${({ theme }) => theme.colors.blue50};
  padding-top: 0.25rem;
  margin-top: 0.2rem;
  font-zize: 2rem;
`;
export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: initial;
`;
export const LeftAddressSection = styled.div`
  display: inline-flex;
  margin-bottom: 0px;
`;
export const SideNote = styled(Text)`
  color: ${({ theme }) => theme.colors.black70};
  padding-left: 1.5rem;
`;
