import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withPromise } from 'hoc';
import { search } from 'modules/customers/api';
import { authenticated } from 'modules/user/hoc';
import { segment } from 'core/integrations';
import View from './View';

export default compose(
  authenticated,
  withRouter,
  withPromise(search, 'search'),
  withHandlers({
    onSubmit: props => async data => {
      segment.trackClick('Click Search', { search: data.customer });
      await props.search.fetch(data.customer);
    },
    onCustomerClick: ({ history }) => id => {
      segment.trackClick(`Click on Customer Id`, { customerId: id });
      history.replace(`/aligners-kit/${id}`);
    },
  })
)(View);
