import Dinero from 'dinero.js';
import { CartState } from 'store/cart/types';
import { CartActions, CartAction } from './actions';
import { produce } from 'immer';
import { CartItem } from './types';
import { LOAD_STATES, PRODUCT_TYPES } from 'core/constants';
import { ProductState } from 'store/products/types';

const initialState: CartState = {
  cart: [],
  cartTotal: Dinero({ amount: 0 }),
  cartDiscountAmount: Dinero({ amount: 0 }),
  quoteLoadState: LOAD_STATES.UNDEFINED,
  isNewOrder: false,
  errorMessage: '',
  selectedShippingAddress: {},
  shippingAddressFormSubmitRef: undefined,
  sentPatientShippingUpdate: true,
  shippingAddressType: 'patient',
};

export default (state = initialState, action: CartActions) =>
  produce(state, draft => {
    switch (action.type) {
      case CartAction.ADD_ITEM:
        
        const isAlignerProductType =
          action.payload.productDetail.alignerCheckout || false;
        const foundIndex = state.cart.findIndex(
          cartItem => cartItem.sku === action.payload.sku
        );
        if (foundIndex > -1) {
          if (isAlignerProductType) {
            // makes sure that AK orders are only added once to the cart
            return;
          }
          draft.cart = state.cart.map((cartItem, idx) => {
            if (foundIndex === idx) {
              return {
                ...cartItem,
                quantity: cartItem.quantity + (action.payload.quantity || 1),
              };
            }
            return cartItem;
          });
        } else {
          draft.cart = [...state.cart, action.payload as CartItem];
        }
        break;
      case CartAction.CLEAR_CART:
        draft.cart = [];
        break;
      case CartAction.REMOVE_ITEM:
        const found = state.cart.findIndex(
          cartItem => cartItem.sku === action.payload.sku
        );
        draft.cart = state.cart.filter((_, index) => index !== found);
        break;
      case CartAction.SET_NEW_ORDER:
        draft.isNewOrder = action.payload.isNewOrder;
        break;
      case CartAction.FETCH_QUOTE_SUCCESS:
        const order = action.payload.quote;
        order.orderitems.forEach(orderItem => {
          const foundIndex = state.cart.findIndex(
            cartItem => cartItem.sku === orderItem.product_sku
          );
          const unitPrice = Dinero({
            amount: parseInt(`${Number(orderItem.unit_price) * 100}`, 10),
          });
          const unitPriceAdjustment = Dinero({
            amount: parseInt(`${Number(orderItem.unit_price_adjustment) * 100}`, 10),
          });
          const claimAdditionalDiscount = Dinero({
            amount: parseInt(`${orderItem.claim_additional_discount}`, 10),
          });
          const claimAmountApplied = Dinero({
            amount: parseInt(`${orderItem.claim_amount_applied}`, 10),
          });
          const totalPrice = Dinero({
            amount: parseInt(`${Number(orderItem.total) * 100}`, 10),
          })
            .add(claimAdditionalDiscount)
            .add(claimAmountApplied);
          draft.cart[foundIndex] = {
            ...state.cart[foundIndex],
            unitPrice,
            unitPriceAdjustment,
            claimAdditionalDiscount,
            claimAmountApplied,
            totalPrice,
          };
        });
        draft.cartTotal = Dinero({ amount: parseInt(`${Number(order.total) * 100}`, 10) });
        draft.cartDiscountAmount = Dinero({
          amount: parseInt(`${Number(order.coupon_amount) * 100}`, 10),
        });
        draft.quoteLoadState = LOAD_STATES.SUCCESS;
        break;
      case CartAction.FETCH_QUOTE_REQUEST:
        draft.quoteLoadState = LOAD_STATES.PENDING;
        break;
      case CartAction.FETCH_QUOTE_FAILURE:
        draft.quoteLoadState = LOAD_STATES.FAILURE;
        draft.errorMessage = action.payload.errorMessage;
        break;
      case CartAction.SET_SELECTED_SHIPPING_ADDRESS:
        draft.selectedShippingAddress = action.payload.shippingAddress;
        break;
      case CartAction.SET_SENT_PATIENT_SHIPPING_UPDATE:
        draft.sentPatientShippingUpdate = action.payload.sentPatientShippingUpdate;
        break;
      case CartAction.SET_SHIPPING_ADDRESS_TYPE:
        draft.shippingAddressType = action.payload.shippingAddressType;
        break;
      case CartAction.SET_SELECTED_SHIPPING_ADDRESS_BUTTON_REF:
        draft.shippingAddressFormSubmitRef = action.payload.ref;
        break;
      case CartAction.CLEAR_SHIPPING_ADDRESS_SELECTION:
        draft.selectedShippingAddress = {}
        draft.shippingAddressFormSubmitRef = undefined;
        draft.sentPatientShippingUpdate = true;
        draft.shippingAddressType = 'patient';
        break;
      default:
        break;
    }
  });

/* SELECTORS */
export const getCart = ({ cart }: { cart: CartState }) => {
  return cart.cart;
};

export const getCartTotal = ({ cart }: { cart: CartState }): Dinero.Dinero =>
  cart.cartTotal;

export const getCartDiscountAmount = ({
  cart,
}: {
  cart: CartState;
}): Dinero.Dinero => cart.cartDiscountAmount;

export const getInsuranceDeductions = ({
  cart,
}: {
  cart: CartState;
}): {
  claimAmountApplied: Dinero.Dinero;
  claimAdditionalDiscount: Dinero.Dinero;
} =>
  cart.cart.reduce(
    (agg, curr) => {
      return {
        claimAmountApplied: agg.claimAmountApplied.add(
          curr.claimAmountApplied || Dinero({ amount: 0 })
        ),
        claimAdditionalDiscount: agg.claimAdditionalDiscount.add(
          curr.claimAdditionalDiscount || Dinero({ amount: 0 })
        ),
      };
    },
    {
      claimAmountApplied: Dinero({ amount: 0 }),
      claimAdditionalDiscount: Dinero({ amount: 0 }),
    }
  );

export const getIsFetchingQuote = ({ cart }: { cart: CartState }): boolean =>
  cart.quoteLoadState === LOAD_STATES.PENDING;

export const getIsNewOrder = ({ cart }: { cart: CartState }): boolean =>
  cart.isNewOrder;

export const getQuoteLoadState = ({ cart }: { cart: CartState }): LOAD_STATES =>
  cart.quoteLoadState;

export const getCartErrorMessage = ({ cart }: { cart: CartState }): string =>
  cart.errorMessage;
  
export const hasAlignerKitInCart = ({
  cart,
  products,
}: {
  cart: CartState;
  products: ProductState;
}): boolean => {
  return cart.cart.some(
    cartItem =>
      products.productCatalog[cartItem.sku]?.product_type ===
      PRODUCT_TYPES.ALIGNERS_KIT
  );
};
export const getSelectedShippingAddress = ({ cart }: { cart: CartState }) => {
  return cart.selectedShippingAddress;
};

export const getSentPatientShippingUpdate = ({ cart }: { cart: CartState }) => {
  return cart.sentPatientShippingUpdate;
};

export const getSelectedShippingAddressType = ({ cart }: { cart: CartState }) => {
  return cart.shippingAddressType;
};

export const getShippingAddressFormSubmitRef = ({ cart }: { cart: CartState }) => {
  return cart.shippingAddressFormSubmitRef;
};