import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { mediaQueries } from '@candidco/enamel';

import { Tab, TabContainer, TabContent } from './Tabs.css';

const mediaQueryKeys = Object.keys(mediaQueries);

const Tabs = ({
  contentWrapper,
  defaultTab,
  disabledBreakpoint,
  hasBottomMargin = false,
  items = [],
}) => {
  const [firstTab = {}] = items;
  const [activeTab, setActiveTab] = useState(defaultTab || firstTab.id);
  const Content = contentWrapper;

  const tabContents = items.map(({ content, id }) => (
    <TabContent
      key={id}
      disabledBreakpoint={disabledBreakpoint}
      isVisible={id === activeTab}
    >
      {content}
    </TabContent>
  ));

  return (
    <Fragment>
      <TabContainer
        disabledBreakpoint={disabledBreakpoint}
        hasBottomMargin={hasBottomMargin}
      >
        {items.map(({ id, name }) => (
          <Tab
            data-cy={`tab-data-${id}`}
            key={id}
            isActive={id === activeTab}
            onClick={() => setActiveTab(id)}
          >
            {name}
          </Tab>
        ))}
      </TabContainer>
      {contentWrapper ? <Content>{tabContents}</Content> : tabContents}
    </Fragment>
  );
};

Tabs.propTypes = {
  contentWrapper: PropTypes.elementType,
  defaultTab: PropTypes.string,
  disabledBreakpoint: PropTypes.oneOf(mediaQueryKeys),
  hasBottomMargin: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Tabs;
