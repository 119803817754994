import { Dispatch } from 'redux';
import { createAction, ActionsUnion } from 'store/action';
import { RequestMethods, fetchApiWithPromise } from 'core/conceptions/api';
import { Case, TreatmentPlanStaging } from '../cases/types';

export enum CaseAction {
  FETCH_CASES_REQUEST = 'FETCH_CASES_REQUEST',
  FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS',
  FETCH_CASES_FAILURE = 'FETCH_CASES_FAILURE',
  SELECT_CASE = 'SELECT_CASE',
}

export const CaseActions = {
  fetchCasesRequest: () => createAction(CaseAction.FETCH_CASES_REQUEST),
  fetchCasesFailure: (errorMessage: any) =>
    createAction(CaseAction.FETCH_CASES_FAILURE, { errorMessage }),
  fetchCasesSuccess: (cases: Case[]) =>
    createAction(CaseAction.FETCH_CASES_SUCCESS, { cases }),
  selectCase: (caseId: number | null) =>
    createAction(CaseAction.SELECT_CASE, { caseId }),
};

export type CaseActions = ActionsUnion<typeof CaseActions>;

export const fetchCasesForCustomer = (customerId: string) => async (
  dispatch: Dispatch<any>
) => {
  dispatch(CaseActions.fetchCasesRequest);
  try {
    const resp = await fetchApiWithPromise(
      `/api/v1/gateway/case/case-by-customer-ref/${customerId}/`,
      RequestMethods.GET
    );
    dispatch(CaseActions.fetchCasesSuccess(resp.data));
  } catch (e) {
    dispatch(CaseActions.fetchCasesFailure(e));
  }
};

export enum TreatmentPlanStagingAction {
  FETCH_TREATMENT_PLAN_STAGINGS_REQUEST = 'FETCH_TREATMENT_PLAN_STAGINGS_REQUEST',
  FETCH_TREATMENT_PLAN_STAGINGS_SUCCESS = 'FETC_TREATMENT_PLAN_STAGINGS_SUCCESS',
  FETCH_TREATMENT_PLAN_STAGINGS_FAILURE = 'FETCH_TREATMENT_PLAN_STAGINGS_FAILURE',
  SELECT_LATEST_TREATMENT_PLAN_STAGING = 'SELECT__TREATMENT_PLAN_STAGING',
}

export const TreatmentPlanStagingActions = {
  fetchTreatmentPlanStagingsRequest: () => createAction(TreatmentPlanStagingAction.FETCH_TREATMENT_PLAN_STAGINGS_REQUEST),
  fetchTreatmentPlanStagingsFailure: (errorMessage: any) =>
    createAction(TreatmentPlanStagingAction.FETCH_TREATMENT_PLAN_STAGINGS_FAILURE, { errorMessage }),
  fetchTreatmentPlanStagingsSuccess: (treatmentPlanStagings: TreatmentPlanStaging[]) =>
    createAction(TreatmentPlanStagingAction.FETCH_TREATMENT_PLAN_STAGINGS_SUCCESS, treatmentPlanStagings ),
  selectLatestTreatmentPlanStaging: () =>
    createAction(TreatmentPlanStagingAction.SELECT_LATEST_TREATMENT_PLAN_STAGING ),
};

export type TreatmentPlanStagingActions = ActionsUnion<typeof TreatmentPlanStagingActions>;

export const fetchTreatmentPlanStagingsForCaseRef = (caseRef: string) => async (
  dispatch: Dispatch<any>
) => {
  dispatch(TreatmentPlanStagingActions.fetchTreatmentPlanStagingsRequest);
  try {
    console.log('fetchTreatmentPlanStagingsForCaseRef action')
    const resp = await fetchApiWithPromise(
      `/api/v1/gateway/case/treatment-plan-staging/${caseRef}`,
      RequestMethods.GET
    );
    dispatch(TreatmentPlanStagingActions.fetchTreatmentPlanStagingsSuccess(resp.data as TreatmentPlanStaging[]));
  } catch (e) {
    dispatch(TreatmentPlanStagingActions.fetchTreatmentPlanStagingsFailure(e));
  }
};
