import React from 'react';
import { useSelector } from 'react-redux';
import { ProductSkus, ProductCatalogDetail } from 'store/products/types';
import { MAX_QUANTITY } from 'core/constants';
import { Case } from 'store/cases/types';
import { getCustomerBrand } from 'store/customer';
import { getUserGroups } from 'modules/user/storage';

function truncateString(str: string): string {
  const maxLength = 55;
  return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
}

const cutomerToProductBrand = new Map([
  ['candid_pro', 'candid'],
  ['glidewell', 'pro_monitoring'],
]);

const ProductOptions = ({ products }: { products: ProductCatalogDetail[] }) => (
  <>
    {products.map(product => (
      <option key={product.sku} value={product.sku}>
        {`${truncateString(product.name)} - ${product.price.toFormat(
          '$0,0.00'
        )}`}
      </option>
    ))}
  </>
);

export const ProductSelectDropdown = ({
  handleChange,
  products,
  quantity,
  productSku,
  activeCase,
}: {
  handleChange: (sku: ProductSkus, quantity?: number) => void;
  products: Map<ProductSkus, ProductCatalogDetail>;
  productSku: ProductSkus | undefined;
  quantity?: number;
  activeCase?: Case;
}) => {
  const brand = useSelector(getCustomerBrand);
  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base',
  });
  const journeyStatesToNotShowUnsupport = [
    'in-treatment',
    'treatment-completed',
    'aligner-kit-delivered',
    'aligner-kit-shipped',
    'aligner-kit-in-production',
  ];

  // After April 1, 2022 12am ET
  let productsToDisplay = Array.from(products, ([k, v]) => v);
  const activeCaseName = activeCase?.case_type?.name;
  const alignerJourneyState = activeCase?.journey?.legs?.production_aligner_leg;
  const isProPatient =
    activeCaseName === 'cpro_teen_aligner' ||
    activeCaseName === 'cpio_adult_aligner';
  const isITI = activeCaseName === 'aligner_in_treatment_issue';
  const userGroups = getUserGroups();
  const showAKOverride = userGroups?.indexOf('journey-admin') > -1;
  const hideAlignerProducts =
    !showAKOverride &&
    !isITI &&
    !isProPatient &&
    !journeyStatesToNotShowUnsupport.includes(alignerJourneyState ?? '');
  if (hideAlignerProducts) {
    // Filter out aligner products after cutoff date
    productsToDisplay = productsToDisplay.filter(
      v => !v.name.toLowerCase().includes('aligner')
    );
  }

  const orderedProducts = productsToDisplay.sort((a, b) =>
    collator.compare(a.name, b.name)
  );

  const groupedProducts = orderedProducts.reduce((acc, product) => {
    if (!acc[product.brand]) {
      acc[product.brand] = [];
    }
    acc[product.brand].push(product);
    return acc;
  }, {} as { [key: string]: ProductCatalogDetail[] });
  const selectedProduct = productSku
    ? products.get(productSku)
    : orderedProducts[0];

  const handleProductSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const sku = event.currentTarget.value as ProductSkus;
    handleChange(sku, quantity);
  };

  const handleQuantitySelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (productSku) {
      handleChange(productSku, Number(event.currentTarget.value));
    }
  };

  return (
    <div
      className="flex"
      style={{
        flexWrap: 'wrap',
        flexDirection: 'column',
      }}
    >
      <select
        style={{ boxSizing: 'border-box', height: '34px' }}
        className="ba pl1 pr1 br2 db f5 fw4 outline-0 b--black-20 bg-white pointer mr2"
        onChange={handleProductSelect}
        key={productSku}
        defaultValue={productSku}
        data-cy="product-select-dropdown"
      >
        <option key="default" value="" disabled hidden>
          Select Products
        </option>
        <>
          {brand ? (
            <>
              <optgroup label={brand?.label}>
                <ProductOptions
                  products={
                    groupedProducts[cutomerToProductBrand.get(brand.name)!]
                  }
                />
              </optgroup>
              <optgroup label="Generic">
                <ProductOptions products={groupedProducts['generic']} />
              </optgroup>
            </>
          ) : (
            <ProductOptions products={orderedProducts} />
          )}
        </>
      </select>
      {selectedProduct?.showQuantity && (
        <select
          className="ba pl1 pr1 br2 db f5 fw4 outline-0 b--black-20 bg-white pointer"
          onChange={handleQuantitySelect}
          key={quantity}
          defaultValue={quantity}
        >
          {[...new Array(MAX_QUANTITY)].map((_, index) => {
            return (
              <option key={index} value={index + 1}>
                {index + 1}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};
