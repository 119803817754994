import React from 'react';
import { withRouter } from 'react-router-dom';
import { signOut } from 'modules/user/storage';
import { getEmail } from 'core/storage';

export default ({ children }) => (
  <React.Fragment>
    <Header />
    {children}
  </React.Fragment>
);

const Header = withRouter(({ history }) => {
  const email = getEmail();

  return (
    <div className="flex pa3 bb b--black-20 mb2">
      <div className=" flex ml-auto">
        <div className="black mr3  f6 ml-auto">{email}</div>
        <div
          className="black pointer mr3 f6 underline-hover ml-auto"
          onClick={() => {
            signOut();
            history.replace('/login');
          }}
        >
          Sign out
        </div>
      </div>
    </div>
  );
});
