import React from 'react';
import { authenticated } from 'modules/user/hoc';

import { CheckoutViewWrapper } from 'components/pages/AlignersKit/Checkout/ViewWrapper';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

const Loading = () => (
  <div className="flex items-center justify-center">Loading...</div>
);

const CheckoutSwitcher = props => {
  if (props.user.isFetching) {
    return <Loading />;
  }

  return <CheckoutViewWrapper />;
};

export default compose(authenticated, withRouter)(CheckoutSwitcher);
