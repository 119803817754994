import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

const ContainerContent = styled.div`
  max-height: ${({ hasToggle }: { hasToggle: boolean }) =>
    hasToggle ? '0' : 'auto'};
  ${({ hasToggle }: { hasToggle: boolean }) =>
    hasToggle &&
    css`
      padding: 0 1rem 1rem 1rem;
    `};
  overflow: hidden;
  transition: max-height 0.35s;

  &.open {
    max-height: 100vh;
  }
`;

const ToggleArrow = styled.div`
  transition: transform 0.35s;

  &.open {
    transform: rotate(180deg);
    transform-origin: center;
  }
`;

export const Container = ({
  headerText,
  children,
  isOpen = false,
  hasToggle = false,
  customContent = undefined,
}: {
  headerText: string;
  children: any;
  isOpen?: boolean;
  hasToggle?: boolean;
  customContent?: JSX.Element; // add any additional buttons
}) => {
  const [toggleOpen, setToggleOpen] = useState(isOpen);

  useEffect(() => {
    if (toggleOpen !== isOpen) {
      setToggleOpen(isOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div className="mt4 mb4">
      <div
        className={`flex justify-between ${
          hasToggle ? 'pointer pt3 ph3 ma0 br bl bt b--dark-green' : ''
        }`}
        data-cy={headerText.split(' ').join('')}
        onClick={() => setToggleOpen(!toggleOpen)}
      >
        <h3 className={`fw5 ${hasToggle && 'ma0 dark-green'}`}>{headerText}</h3>
        {customContent && customContent}
        {hasToggle && (
          <ToggleArrow className={`dark-green ${toggleOpen ? 'open' : ''}`}>
            ▾
          </ToggleArrow>
        )}
      </div>
      <ContainerContent
        className={`${toggleOpen && 'open pb3'} ${
          hasToggle ? 'b--dark-green br bl bb' : 'ba pa3'
        }`}
        hasToggle={hasToggle}
      >
        {children}
      </ContainerContent>
    </div>
  );
};
