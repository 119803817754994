import React from 'react';
import { useSelector } from 'react-redux';
import { LOAD_STATES } from 'core/constants';
import {
  wasOrderPaid,
  getOrderErrorMessage,
  getOrderLoadState,
} from 'store/payment';
import PaymentForm from './PaymentForm';

export const PaymentBody = ({ customerId }: { customerId: string }) => {
  const orderPaid = useSelector(wasOrderPaid);
  const orderErrorMessage = useSelector(getOrderErrorMessage);
  const orderLoadState = useSelector(getOrderLoadState);

  const renderPayment = () => {
    if (orderLoadState === LOAD_STATES.FAILURE) {
      return (
        <div className="bg-light-red b--dark-red near-black pa1 br2 ba f6 b--solid mb1">
          Error loading order: {orderErrorMessage}
        </div>
      );
    }

    if (orderPaid) {
      return (
        <div className="bg-light-green b--dark-green near-black pa1 br2 ba f6 b--solid mb1">
          Order Successful
        </div>
      );
    }

    return (
      <>
        <PaymentForm />
      </>
    );
  };

  return renderPayment();
};
