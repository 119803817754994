import { ProductCatalog, ProductSkus } from 'store/products/types';
import { OrderResponse } from 'store/payment/types';
import { CartItem } from 'store/cart/types';

export const compileOrderItems = ({
  cartItems = [],
  activeAKOrder,
  catalog,
}: {
  cartItems?: CartItem[];
  activeAKOrder?: OrderResponse;
  catalog: ProductCatalog;
}) => {
  let items = [];
  if (activeAKOrder) {
    items = activeAKOrder.orderitems.map(orderItem => ({
      sku: orderItem.product_sku,
      quantity: orderItem.quantity,
      metadata: activeAKOrder.metadata,
    }));
  } else {
    items = cartItems.map(cartItem => ({
      sku: cartItem.sku,
      quantity: cartItem.quantity,
      metadata: cartItem.metadata,
    }));
  }

  // TODO: this is probably not the best place to put it, add aligner good to checkout in the bundled order
  const hasAKInOrder = items.some(
    item => catalog[item.sku]?.alignerCheckout || false
  );
  const hasAlignerGoodInOrder = items.some(
    item => item.sku === ProductSkus.AlignerGood
  );
  if (hasAKInOrder && !hasAlignerGoodInOrder) {
    items = [...items, { sku: ProductSkus.AlignerGood, quantity: 1 }];
  }

  return items;
};
