import React, { useEffect, useMemo } from 'react';
import { ProductSelectDropdown } from './ProductSelectDropdown';
import { Button } from 'components/generic/kit';
import { ProductSkus, ProductTypeDetail } from 'store/products/types';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { CartActions, fetchQuote } from 'store/cart/actions';
import {
  getCart,
  getCartTotal,
  getIsFetchingQuote,
  getCartDiscountAmount,
  getQuoteLoadState,
  getCartErrorMessage,
} from 'store/cart';
import { getLatestTreatmentPlanStaging, getSelectedCase } from 'store/cases';
import { useParams } from 'react-router-dom';
import { Loading } from '@candidco/enamel';
import {
  getAppliedCoupon,
  getReferralCoupon,
  getCouponCode,
} from 'store/coupon';
import { LOAD_STATES } from 'core/constants';
import { ErrorText } from 'components/common/ErrorText';
import { hasEstimateClaims } from 'store/insurance';
import { getInsuranceDeductions } from 'store/cart/index';
import styled from 'styled-components';
import { getProductCatalogMap } from 'store/products';
import { ProductActions } from 'store/products/actions';
import { fetchTreatmentPlanStagingsForCaseRef } from 'store/cases/actions';
import { Treatmentplan } from 'store/cases/types';

const REACT_APP_OPEN_SOURCE_TP_SUPPLIERS = process.env.REACT_APP_OPEN_SOURCE_TP_SUPPLIERS?.split(
  ','
);

const AlignerSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 0;
`;
const CorrectlyAlignedCheckbox = styled.input`
  margin-top: 2px;
  margin-right: 2px;
`;
interface State {
  selectedProductSku: ProductSkus;
  selectedProduct?: {
    showAlignerSelection?: boolean;
    showReplacementSelection?: boolean;
  };
  alignerStages: string[];
  quantity?: number;
}
/**
 * ProductCart Component is the wrapper responsible for
 *
 * 1) handling the adding/removing by dispatching to the cart reducer so that
 * we can compile an order with multiple items and quantities
 *
 * 2) Rendering the list of items to be displayed
 */
export const ProductCart = () => {
  const dispatch = useDispatch();
  const { id: customerId } = useParams<{ id?: string }>();
  const cart = useSelector(getCart);
  const totalQuantity = cart.reduce((agg, curr) => agg + curr.quantity, 0);
  const cartTotal = useSelector(getCartTotal);
  const discountAmount = useSelector(getCartDiscountAmount);
  const hasInNetworkInsurance = useSelector(hasEstimateClaims);
  const insuranceDeductions = useSelector(getInsuranceDeductions);
  const isFetchingQuote = useSelector(getIsFetchingQuote);
  const quoteLoadState = useSelector(getQuoteLoadState);
  const errorMessage = useSelector(getCartErrorMessage);
  const appliedCoupon = useSelector(getAppliedCoupon);
  const referralCoupon = useSelector(getReferralCoupon);
  const couponCode = useSelector(getCouponCode);
  const selectedCase = useSelector(getSelectedCase);
  const productCatalog = useSelector(getProductCatalogMap);
  const latestTreatmentPlanStaging =
    useSelector(getLatestTreatmentPlanStaging) ||
    selectedCase?.treatmentplans?.find(plan => plan.active);
  const productsMap = useMemo(
    () => new Map(Object.entries(productCatalog) as ProductTypeDetail),
    [productCatalog]
  );
  const initialSelectedSku = productsMap.keys().next().value;
  const [state, setState] = useImmer<State>({
    selectedProductSku: initialSelectedSku,
    selectedProduct: undefined,
    alignerStages: [],
    quantity: 1,
  });
  const shouldShowSteps =
    state.selectedProduct?.showAlignerSelection ||
    state.selectedProduct?.showReplacementSelection;
  const caseRef = selectedCase?.case_ref;
  let tpSupplier;
  let numSteps;
  if (latestTreatmentPlanStaging && 'data' in latestTreatmentPlanStaging) {
    tpSupplier = latestTreatmentPlanStaging?.data.software?.name;
    numSteps = latestTreatmentPlanStaging?.data.steps || 0;
  } else if (
    latestTreatmentPlanStaging &&
    'details' in latestTreatmentPlanStaging
  ) {
    tpSupplier = (latestTreatmentPlanStaging as Treatmentplan)?.details
      ?.supplier;
    numSteps =
      (latestTreatmentPlanStaging as Treatmentplan)?.details.steps || 0;
  } else {
    tpSupplier = 'none';
    numSteps = 0;
  }

  // For open-source treatment plans, stage 0 is included in the files.
  //   We dont want to show this extra stage in our views.
  const isOpenSourceTreatmentPlan = REACT_APP_OPEN_SOURCE_TP_SUPPLIERS?.includes(
    tpSupplier
  );
  if (isOpenSourceTreatmentPlan && numSteps > 0) {
    numSteps -= 1;
  }
  useEffect(() => {
    console.log('caseRef', caseRef);
    if (caseRef) {
      dispatch(fetchTreatmentPlanStagingsForCaseRef(caseRef));
    }
  }, [dispatch, caseRef]);
  useEffect(() => {
    if (totalQuantity > 0 && customerId) {
      dispatch(fetchQuote(cart, customerId, couponCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalQuantity, dispatch, customerId, appliedCoupon, referralCoupon]);
  const handleChange = (sku: ProductSkus, quantity?: number) => {
    setState(draft => {
      draft.selectedProductSku = sku;
      draft.selectedProduct = productsMap.get(sku);
      draft.quantity = quantity;
    });
    dispatch(ProductActions.selectProduct(sku));
  };
  const handleSelectAligner = (state: State, aligner: string) => {
    // remove aligner
    if (state.alignerStages.indexOf(aligner) > -1) {
      const alignerStages = state.alignerStages.filter(
        existingAligner => existingAligner !== aligner
      );

      return setState(draft => {
        draft.alignerStages = alignerStages;
        draft.quantity = alignerStages.length;
      });
    }
    // add aligner
    const alignerStages = [...state.alignerStages, aligner];
    return setState(draft => {
      draft.alignerStages = alignerStages;
      draft.quantity = alignerStages.length;
    });
  };

  const allowAddItem = (): boolean => {
    /**
     * This solves an issue where we need single orders 
     * for aligners, refinements, and retainers for downstream data issues.
     */
    const selectedProduct = productsMap.get(state.selectedProductSku);
    const currentProductTypesCart: string[] = cart.map(c => productsMap.get(c.sku)!.product_type)
    
    const countOfProductTypes = currentProductTypesCart.length;
    const isCartEmpty = countOfProductTypes === 0;
    if (isCartEmpty) {
      return true;
    }

    // These need standalone orders. (Note: Aligner Good is added for refinements and aligner kits on checkout.)
    const isRestrictedProductType = (productType: string) => ["REFINEMENTS", "ALIGNER_KIT", "RETAINER", "REFINEMENTS_DM_BUNDLE", "REPLACEMENT_TRAY"].includes(productType);
    const isRestrictedProductTypeThatAllowsMultiples = (productType: string) => ["RETAINER", "REPLACEMENT_TRAY"].includes(productType);

    // If the products are not manufacturable (exlcuding replacement trays), then they are outside the scope.
    const cartDoesIncludeRestrictedProductTypes = currentProductTypesCart.some(isRestrictedProductType)
    if (!isCartEmpty && !cartDoesIncludeRestrictedProductTypes) {
      if (isRestrictedProductType(selectedProduct!.product_type)) {
        // If someone adds a nonrestricted product type first.
        console.log(`Did not allow ${selectedProduct?.name ?? state.selectedProductSku} to be added to cart because that product must be ordered in a standalone order.\nCurrent cart has following product types: ${currentProductTypesCart}`)
        return false
      }
      return true // We allow people to add only non restricted items together into the cart.
    } else if (countOfProductTypes === 1 && cartDoesIncludeRestrictedProductTypes) {
      if (isRestrictedProductTypeThatAllowsMultiples(selectedProduct!.product_type) && currentProductTypesCart.includes(selectedProduct!.product_type)) {
        // If the product type is a retainer or replacement tray, then the cart can have multiples of that.
        return true
      }
      // If someone tries to combine a restricted product type with another product type regardless of restriction.
      console.log(`Did not allow ${selectedProduct?.name ?? state.selectedProductSku} to be added to cart because cart already includes product types with combination rules.\nCurrent cart has following product types: ${currentProductTypesCart}`)
      return false
    }

    console.log(`Current product types in cart: ${currentProductTypesCart}, defaulted to false when adding to cart.`)
    return false
  }

  const handleAddItem = () => {
    const selectedProduct = productsMap.get(state.selectedProductSku);
    if (!selectedProduct) return;
    const canAddSelectedSku = allowAddItem()
    if (!canAddSelectedSku) {
      throw new Error(`Can't add ${selectedProduct?.name ?? state.selectedProductSku} to current cart combination.`)
    }
    if (shouldShowSteps) {
      dispatch(
        CartActions.addItem(
          state.selectedProductSku,
          selectedProduct,
          state.quantity || 1,
          {
            alignerStages: state.alignerStages,
          }
        )
      );
      setState(draft => {
        draft.alignerStages = [];
      });
      return;
    }
    dispatch(
      CartActions.addItem(
        state.selectedProductSku,
        selectedProduct,
        state.quantity || 1
      )
    );
  };
  return (
    <>
      <div className="flex justify-between">
        <ProductSelectDropdown
          products={productsMap}
          productSku={state.selectedProductSku}
          quantity={state.quantity}
          handleChange={handleChange}
          activeCase={selectedCase}
        />
        <div>
          <Button
            dataCy="add-to-cart-button"
            className="pv2"
            fullWidth={false}
            onClick={handleAddItem}
            disabled={shouldShowSteps && !state.alignerStages.length}
          >
            Add Item to cart
          </Button>
        </div>
      </div>
      <div className="i pa1 mt1">
        Please select any number of products from the dropdown
      </div>
      {shouldShowSteps &&
        !cart.find(item => item.sku === state.selectedProductSku) && (
          <>
            <h2>Select Aligners</h2>
            <div className="flex justify-between">
              <p>{numSteps} total steps</p>
              <p>
                Treatment Plan vendor: <strong>{tpSupplier}</strong>
              </p>
            </div>
            <AlignerSelectionContainer>
              {/* workaround: numSteps is sometimes a string */}
              {/* https://app.clubhouse.io/candid/story/48205/high-software-bug-report-1288-via-jana-anderson-candidco-com */}
              {Array.from(Array(parseInt(numSteps + '')), (e, i) => {
                const teethLocation = ['U', 'L'];
                // no stage 0
                const stage = `${i + 1}`.padStart(2, '0');
                return teethLocation.map(upperOrLower => {
                  const label = upperOrLower + stage;
                  return (
                    <label
                      data-cy={label}
                      key={label}
                      htmlFor={label}
                      className="flex w-20 pa1"
                      onClick={() => handleSelectAligner(state, label)}
                    >
                      <CorrectlyAlignedCheckbox
                        name={label}
                        type="checkbox"
                        checked={state.alignerStages.indexOf(label) > -1}
                        readOnly
                      />
                      <strong>{upperOrLower}</strong>
                      {stage}
                    </label>
                  );
                });
              })}
            </AlignerSelectionContainer>
          </>
        )}
      {cart.length > 0 && (
        <>
          <div className="b--black-10 flex flex-column items-end">
            <div className="flex w-100 pa1">
              <div className="w-40 b pa1">Name</div>
              <div className="w-20 b pa1 tr">Quantity</div>
              <div className="w-20 b pa1 tr">Price</div>
              <div className="w-20 b pa1 tr">Info</div>
              <div className="w-20" />
            </div>
          </div>
          {cart.map((cartItem, idx) => {
            const productDetail = productsMap.get(cartItem.sku);

            return (
              <div key={idx}>
                <div className="pa1 flex w-100 items-end">
                  <div className="w-40 pa1">{productDetail?.name}</div>
                  <div className="w-20 pa1 tr">{cartItem.quantity}</div>
                  <div className="w-20 pa1 tr">
                    {isFetchingQuote ? (
                      <div />
                    ) : (
                      cartItem.totalPrice?.toFormat('$0,0.00')
                    )}
                  </div>
                  <div
                    className="ml2 f6 no-underline pointer w-20 pa1 br-pill bg-red tc white"
                    onClick={() =>
                      dispatch(CartActions.removeItem(cartItem.sku))
                    }
                  >
                    Remove
                  </div>
                </div>
                <div>
                  {cartItem.metadata && (
                    <div className="pa1 flex w-100 items-end">
                      {cartItem?.metadata.alignerStages && (
                        <p data-cy="selected-aligners-list">
                          Selected aligners:{' '}
                          <strong>
                            {cartItem.metadata.alignerStages.join(', ')}
                          </strong>
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {quoteLoadState === LOAD_STATES.FAILURE ? (
            <>
              {ErrorText(
                !LOAD_STATES.FAILURE.includes('something')
                  ? errorMessage
                  : 'An error occured while fetching the quote.'
              )}
            </>
          ) : (
            <>
              {hasInNetworkInsurance && (
                <>
                  <div className="flex w-100 pa1 items-end">
                    <div className="w-40 pa1 i">Insurance Discount Amount</div>
                    <div className="w-20 pa1" />
                    <div className="w-20 pa1 i tr">
                      {isFetchingQuote ? (
                        <div />
                      ) : (
                        insuranceDeductions.claimAdditionalDiscount.toFormat(
                          '$0,0.00'
                        )
                      )}
                    </div>
                  </div>
                  <div className="flex w-100 pa1 items-end">
                    <div className="w-40 pa1 i">Insurance Claimable Amount</div>
                    <div className="w-20 pa1" />
                    <div className="w-20 pa1 i tr">
                      {isFetchingQuote ? (
                        <div />
                      ) : (
                        insuranceDeductions.claimAmountApplied.toFormat(
                          '$0,0.00'
                        )
                      )}
                    </div>
                  </div>
                </>
              )}
              <>
                <div className="flex w-100 pa1 items-end">
                  <div className="w-40 pa1 i">Discounts</div>
                  <div className="w-20 pa1" />
                  <div className="w-20 pa1 i tr">
                    {isFetchingQuote ? (
                      <div />
                    ) : (
                      discountAmount.toFormat('$0,0.00')
                    )}
                  </div>
                  <div className="ml2 w-20 pa1" />
                </div>
                <div className="flex w-100 pa1 items-end">
                  <div className="w-40 pa1 i">Estimated Total</div>
                  <div className="w-20 pa1" />
                  <div className="w-20 pa1 i tr">
                    {isFetchingQuote ? (
                      <Loading />
                    ) : (
                      cartTotal.toFormat('$0,0.00')
                    )}
                  </div>
                  <div className="ml2 w-20 pa1" />
                </div>
              </>
            </>
          )}
        </>
      )}
    </>
  );
};
