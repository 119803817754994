import LogRocket from 'logrocket';

export default {
  init: () => {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
        network: {
          requestSanitizer: request => {
            // if the url contains 'jwt-auth'
            if (request.url.toLowerCase().indexOf('jwt-auth') !== -1) {
              // ignore the request response pair
              return null;
            }

            request.headers['authorization'] = null;
            return request;
          },
        },
      });
    }
  },
  identify: (userId, email) => {
    LogRocket.identify(userId, {
      email: email,
    });
  },
};
