import React from 'react';
import Dinero from 'dinero.js';
import { Button } from 'components/generic/kit';
import { useDispatch, useSelector } from 'react-redux';
import { preGenericCheckout, genericCheckout } from 'store/payment/actions';
import { getCouponCode } from 'store/coupon';
import { getCustomerData } from 'store/customer';
import {
  getCheckoutErrorMessage,
  getCheckoutLoadState,
  getActiveAKOrder,
} from 'store/payment';
import { LOAD_STATES } from 'core/constants';
import { getSelectedCase } from 'store/cases';
import { compileOrderItems } from '../utils/compile-order-items';
import { getCart } from 'store/cart';
import { getCatalogMap } from 'store/products';
import { useFlags } from 'launchdarkly-react-client-sdk';

const FreePurchaseForm = () => {
  const dispatch = useDispatch();
  const activeAKOrder = useSelector(getActiveAKOrder);
  const couponCode = useSelector(getCouponCode);
  const checkoutErrorMessage = useSelector(getCheckoutErrorMessage);
  const checkoutLoadState = useSelector(getCheckoutLoadState);
  const cartItems = useSelector(getCart);
  const catalog = useSelector(getCatalogMap)
  const metadata = cartItems
      .reduce((c: {[key: string]: any}, item) => {
        c[item.sku] = item.metadata;
        return c;
      }, {});
  const caseInfo = useSelector(getSelectedCase);
  const { email, first_name, last_name, shipping_address, referring_dentist, brand } = useSelector(
    getCustomerData
  );

  const isPaying = checkoutLoadState === LOAD_STATES.PENDING;
  const { 'non-ak-shipping-address-improvements': nonAkshippingAddressImprovements } = useFlags();
  const handleOnSubmit = () => {
    const items = compileOrderItems({ cartItems, activeAKOrder, catalog });
    const checkoutAction = nonAkshippingAddressImprovements ? preGenericCheckout : genericCheckout;
    dispatch(
      checkoutAction({
        orderId: activeAKOrder?.id,
        email,
        first_name,
        last_name,
        shipping_address,
        phone: shipping_address.phone,
        items,
        couponCode,
        payment_amount_in_dollars: 0,
        caseRef: caseInfo?.case_ref!,
        caseType: caseInfo?.case_type?.name || '',
        metadata,
        referring_dentist: referring_dentist?.id,
        brand: brand?.name,
      })
    );
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleOnSubmit();
      }}
    >
      <div className="flex justify-center">
        <div className="fl w-third pv2">
          <div className="mt2 mb3">
            <label htmlFor="card-amount" className="fw6">
              Amount to charge:
            </label>
            <div className="mt2 ph2 pv2 db w-100 f6 ba b--black-30 br2 bg-near-white dib">
              {Dinero({ amount: 0 }).toFormat('$0,0.00')}
            </div>
            <div className="pt1 red f6">{checkoutErrorMessage}</div>
          </div>
          <div className="pv3">
            <Button fullWidth disabled={isPaying}>
              {isPaying ? 'Ordering...' : 'Order $0'}
            </Button>
          </div>
        </div>
      </div>

      {checkoutLoadState === LOAD_STATES.SUCCESS && (
        <div className="bg-light-green b--dark-green near-black pa1 br2 ba f6 b--solid mb1">
          Order was successful
        </div>
      )}

      {checkoutLoadState === LOAD_STATES.FAILURE && (
        <div className="bg-light-red b--dark-red near-black pa1 br2 ba f6 b--solid mb1">
          {checkoutErrorMessage || 'Something went wrong'}
        </div>
      )}
    </form>
  );
};

export default FreePurchaseForm;
