import React, { useEffect, StrictMode } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import createBrowserHistory from 'history/createBrowserHistory';
import { ThemeProvider } from '@candidco/enamel';

import { ErrorBoundary } from 'core/integrations/sentry';
import { datadog } from 'core/integrations/datadog';
import { initSentry } from 'core/integrations/sentry/sentry';
import { logrocket } from 'core/integrations/logrocket';
import configureStore from './store/store';
import Pages from './components/pages';

const history = createBrowserHistory();

createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
`;

datadog.init();
initSentry();
logrocket.init();

const App = () => {
  const handleTabClosing = () => datadog.close();

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing);

    return () => {
      window.removeEventListener('unload', handleTabClosing);
    };
  });

  return (
    <Provider store={configureStore()}>
      <ErrorBoundary className="App-link">
        <Router history={history}>
          <ThemeProvider>
            <Pages />
          </ThemeProvider>
        </Router>
      </ErrorBoundary>
    </Provider>
  );
};

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  ReactDOM.render(
    <StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </StrictMode>,
    document.getElementById('root')
  );
})();