import { Button, Modal, colors, type } from '@candidco/enamel';
import React, { useState } from 'react';
import { SelectedAddress as AddressFormType } from 'components/pages/AlignersKit/Checkout/components/ShippingAddressSelection';

import styled from 'styled-components/macro';


export interface SpacerProps {
  isVertical?: boolean;
  spacing?: string;
}

export const Spacer = styled.div<SpacerProps>`
  display: flex;
  flex-direction: ${({ isVertical = false }) =>
    isVertical ? 'column' : 'row'};

  > *:not(:first-child) {
    margin-top: ${({ spacing = '1rem', isVertical = false }) =>
    isVertical ? spacing : '0'};
    margin-left: ${({ spacing = '1rem', isVertical = false }) =>
    !isVertical ? spacing : '0'};
  }
`;

const ConfirmDialogContainer = styled.div`
  margin: 2rem;
  min-width: 400px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StyledButton = styled(Button)<{ fontColor?: string }>`
  &:not(:disabled) {
    background-color: ${({ color }) => color};
    color: ${({ fontColor = colors.white }) => fontColor};

    &:hover {
      background-color: ${({ color }) => color};
      color: ${({ fontColor = colors.white }) => fontColor};
      filter: brightness(90%);
    }
    &:active {
      background-color: ${({ color }) => color};
      color: ${({ fontColor = colors.white }) => fontColor};
      filter: brightness(75%);
    }
  }
`;
const RadioGroup = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
`;

const Radio = styled.input`
  margin-right: 0.75rem;
  cursor: pointer;
  appearance: radio;
`;

const RadioLabel = styled.label`
  margin-right: 1.75rem;
  margin-bottom: 0.5rem;
  font-size: ${type.size.small};
`;

export type AddressConfirmationProps = {
  isOpen: boolean;
  suggestions: AddressFormType[];
  onConfirm: (selected_value: AddressFormType) => void;
  onCancel: () => void;
};

const AddressConfirmation = ({
  isOpen,
  suggestions,
  onConfirm,
  onCancel,
}: AddressConfirmationProps) => {
  const [selectedValue, setSelectedValue] = useState<AddressFormType>();

  const displayAddressSuggestion = (input: AddressFormType) => {
    if (!input) {
      return '';
    }

    let combinedAddressLine = input.address_line_1;

    if (input.address_line_2) {
      combinedAddressLine += ' ' + input.address_line_2;
    }

    return combinedAddressLine + ' ' + input.city + ' ' + input.state_code + ' ' + input.zip;
  };
  const bodyText =
    suggestions.length > 1
      ? 'Multiple addresses were found. Choose the one that fits best:'
      : 'No matching address was found. You can use the entered address, or hit cancel to enter a new address';

  return (
    <Modal
      isOpen={isOpen}
      overlayColor="rgba(0, 0, 0, 0.73)"
      hideCloseButton={false}
      onClose={onCancel}
    >
      <ConfirmDialogContainer>
        <Spacer spacing="1rem" isVertical>
          <type.Body>{bodyText}</type.Body>
          <RadioGroup>
            {suggestions?.map((suggestion) => (
              <RadioLabel>
                <Radio
                  data-testId={'addressConfirmation-' + suggestion.address_line_1}
                  name="suggestions"
                  type="radio"
                  value={suggestion.address_line_1!}
                  id="suggestions"
                  onClick={() => setSelectedValue(suggestion)}
                />
                {displayAddressSuggestion(suggestion)}
              </RadioLabel>
            ))}
          </RadioGroup>
          <ButtonContainer>
            <Spacer spacing="1rem">
              <StyledButton
                testId="addressConfirmation-Confirm"
                color={colors.blue}
                disabled={!selectedValue}
                onClick={() => {
                  onConfirm(selectedValue!);
                  setSelectedValue(undefined);
                }}
              >
                Confirm
              </StyledButton>
              <StyledButton
                color={colors.blue10}
                fontColor={colors.black90}
                onClick={onCancel}
              >
                Cancel
              </StyledButton>
            </Spacer>
          </ButtonContainer>
        </Spacer>
      </ConfirmDialogContainer>
    </Modal>
  );
};

export default AddressConfirmation;
