import { createAction, ActionsUnion } from 'store/action';
import { ProductSkus, ProductResponse } from 'store/products/types';
import { Dispatch } from 'redux';
import { fetchApiWithPromise, RequestMethods } from 'core/conceptions/api';

export enum ProductAction {
  SELECT_PRODUCT = 'SELECT_PRODUCT',
  SELECT_QUANTITY = 'SELECT_QUANTITY',

  CLEAR_SCOPED_PRODUCTS = 'CLEAR_SCOPED_PRODUCTS',
  FETCH_SCOPED_PRODUCTS_REQUEST = 'FETCH_SCOPED_PRODUCTS_REQUEST',
  FETCH_SCOPED_PRODUCTS_SUCCESS = 'FETCH_SCOPED_PRODUCTS_SUCCESS',
  FETCH_SCOPED_PRODUCTS_FAILURE = 'FETCH_SCOPED_PRODUCTS_FAILURE',

  FETCH_CATALOG_REQUEST = 'FETCH_CATALOG_REQUEST',
  FETCH_CATALOG_SUCCESS = 'FETCH_CATALOG_SUCCESS',
  FETCH_CATALOG_FAILURE = 'FETCH_CATALOG_FAILURE',
}

export const ProductActions = {
  selectProduct: (productSku: ProductSkus | undefined) => 
  createAction(ProductAction.SELECT_PRODUCT, {
    productSku,
  }),
  selectQuantity: (quantity: number) =>
    createAction(ProductAction.SELECT_QUANTITY, quantity),

  clearProducts: () => createAction(ProductAction.CLEAR_SCOPED_PRODUCTS),
  fetchProducts: () => createAction(ProductAction.FETCH_SCOPED_PRODUCTS_REQUEST),
  fetchProductsSuccess: (response: ProductResponse[]) =>
    createAction(ProductAction.FETCH_SCOPED_PRODUCTS_SUCCESS, response),
  fetchProductsFailure: (error: string) =>
    createAction(ProductAction.FETCH_SCOPED_PRODUCTS_FAILURE, error),
  fetchCatalog: () => createAction(ProductAction.FETCH_CATALOG_REQUEST),
  fetchCatalogSuccess: (response: ProductResponse[]) =>
    createAction(ProductAction.FETCH_CATALOG_SUCCESS, response),
  fetchCatalogFailure: (error: string) =>
    createAction(ProductAction.FETCH_CATALOG_FAILURE, error),
};

export type ProductActions = ActionsUnion<typeof ProductActions>;

export const fetchCatalog = () => async (
  dispatch: Dispatch
) => {
  dispatch(ProductActions.fetchCatalog());
  try {
    const resp = await fetchApiWithPromise('/api/v1/catalog/', RequestMethods.GET);
    dispatch(ProductActions.fetchCatalogSuccess(resp.data));
  } catch (e) {
    dispatch(ProductActions.fetchCatalogFailure(e));
  }
};

export const fetchCatalogProductsForCaseType = (caseType: string) => async (
  dispatch: Dispatch
) => {
  dispatch(ProductActions.clearProducts());
  dispatch(ProductActions.fetchProducts());
  const url = caseType === 'standalone_whitening' ? `/api/v1/catalog/?case_type=whitening` : `/api/v1/catalog/`; 
  try {
    const resp = await fetchApiWithPromise(url, RequestMethods.GET);
    dispatch(ProductActions.fetchProductsSuccess(resp.data));
  } catch (e) {
    dispatch(ProductActions.fetchProductsFailure(e));
  }
};
