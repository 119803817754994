import axios, { AxiosResponse } from 'axios';
import { getNewToken } from 'modules/user/api';
import { path } from 'ramda';
import * as sentry from 'core/integrations/sentry';
import * as storage from 'modules/user/storage';

const isTokenInvalid = (err: Error) =>
  path(['response', 'status'], err) === 401 &&
  path(['response', 'data', 'code'], err) === 'token_not_valid';

const getAxiosErrorMessage = (err: any) =>
  err.response && err.response.data && err.response.data.error;

export enum RequestMethods {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
}

/**
 * @param {string} path
 * @param {('GET' | 'POST' | 'PATCH')} method
 * @param {{ [key: string]: any }} body
 * @returns {Promise<any>}
 */
export const fetchApi = async <RequestBody = any, RequestResult = any>(
  path: string,
  method: RequestMethods,
  body?: RequestBody
): Promise<RequestResult> => {
  const token = storage.getToken();
  try {
    const res = await axios({
      method,
      url: process.env.REACT_APP_API + path,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        ...(token && {
          authorization: `JWT ${token}`,
        }),
      },
    });

    return res.data;
  } catch (err) {
    /**
     * If access token is expired - getting the new one and trying again
     */
    if (isTokenInvalid(err)) {
      try {
        const res = await getNewToken(storage.getRefreshToken());
        storage.signIn(res.data.access_token, res.data.refresh_token);

        return await fetchApi(path, method, body);
      } catch (tokenRefreshError) {
        storage.signOut();
        document.location.href = '/login';
        throw new Error('Your session has expired. Please log in again.');
      }
    }

    sentry.captureException(err);
    const errorMessage = getAxiosErrorMessage(err);
    throw errorMessage || err;
  }
};

/**
 * This one returns the actual promise response instead of just the data
 */
export const fetchApiWithPromise = async <
  RequestBody = any,
  RequestResult = any
>(
  path: string,
  method: RequestMethods,
  body?: RequestBody
): Promise<AxiosResponse<RequestResult>> => {
  const token = storage.getToken();
  try {
    return axios({
      method,
      url: process.env.REACT_APP_API + path,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        ...(token && {
          authorization: `JWT ${token}`,
        }),
      },
    });
  } catch (err) {
    /**
     * If access token is expired - getting the new one and trying again
     */
    if (isTokenInvalid(err)) {
      try {
        const res = await getNewToken(storage.getRefreshToken());
        storage.signIn(res.data.access_token, res.data.refresh_token);

        return await fetchApi(path, method, body);
      } catch (tokenRefreshError) {
        storage.signOut();
        document.location.href = '/login';
        throw new Error('Your session has expired. Please log in again.');
      }
    }

    sentry.captureException(err);

    throw err;
  }
};

/**
 * Tries to parse the JSON response
 * The response that's returned here from python is not parsed and is still
 * wrapped in python's byte format
 *
 * an example response:
 * resp = "b'{"this": "is a sample"}'"" <- JSON can't parse this so need to get rid of the "b" and "'"
 * @param {string} stringifiedJsonResp
 *
 * @returns {object} parsed object
 */
export const convertBytesPythonAndParseJsonReponse = (
  stringifiedJsonResp: string
): object | string => {
  try {
    const removeByteString =
      stringifiedJsonResp[0] === 'b'
        ? stringifiedJsonResp.slice(1)
        : stringifiedJsonResp;
    const removeOutsideSingleQuotes =
      removeByteString[0] === "'" &&
      removeByteString[removeByteString.length - 1] === "'"
        ? removeByteString.slice(1, removeByteString.length - 1)
        : removeByteString;

    return JSON.parse(removeOutsideSingleQuotes);
  } catch (err) {
    return stringifiedJsonResp;
  }
};
