import { Dispatch } from 'redux';
import { CouponResponse } from './types';
import { createAction, ActionsUnion } from 'store/action';
import { RequestMethods, fetchApiWithPromise } from 'core/conceptions/api';
import { getMessageFromErrorNested } from 'core/util';

export enum CouponAction {
  APPLY_COUPON_REQUEST = 'APPLY_COUPON_REQUEST',
  APPLY_COUPON_SUCCESS = 'APPLY_COUPON_SUCCESS',
  APPLY_COUPON_FAILURE = 'APPLY_COUPON_FAILURE',

  FETCH_REFERRAL_COUPON_REQUEST = 'FETCH_REFERRAL_COUPON_REQUEST',
  FETCH_REFERRAL_COUPON_SUCCESS = 'FETCH_REFERRAL_COUPON_SUCCESS',
  FETCH_REFERRAL_COUPON_FAILURE = 'FETCH_REFERRAL_COUPON_FAILURE',

  RESET_COUPON = 'RESET_COUPON',
}

export const CouponActions = {
  applyCouponRequest: () => createAction(CouponAction.APPLY_COUPON_REQUEST),
  applyCouponSuccess: (coupon: CouponResponse) =>
    createAction(CouponAction.APPLY_COUPON_SUCCESS, {
      coupon,
    }),
  applyCouponError: (errorMessage: any) =>
    createAction(CouponAction.APPLY_COUPON_FAILURE, {
      errorMessage,
    }),

  fetchReferralCouponRequest: () =>
    createAction(CouponAction.FETCH_REFERRAL_COUPON_REQUEST),
  fetchReferralCouponSuccess: (coupon: CouponResponse) =>
    createAction(CouponAction.FETCH_REFERRAL_COUPON_SUCCESS, {
      coupon,
    }),
  fetchReferralCouponFailure: (errorMessage: any) =>
    createAction(CouponAction.FETCH_REFERRAL_COUPON_FAILURE, { errorMessage }),

  resetCoupon: () => createAction(CouponAction.RESET_COUPON),
};

export type CouponActions = ActionsUnion<typeof CouponActions>;

export const applyCoupon = (couponCode: string) => async (
  dispatch: Dispatch
) => {
  dispatch(CouponActions.applyCouponRequest());
  try {
    const resp = await fetchApiWithPromise(
      `/api/v1/apply-coupon/`,
      RequestMethods.POST,
      {
        coupon_code: couponCode.toUpperCase(),
        product_type: 'aligner',
      }
    );
    dispatch(CouponActions.applyCouponSuccess(resp.data));
  } catch (e) {
    dispatch(
      CouponActions.applyCouponError(getMessageFromErrorNested(e.response.data))
    );
  }
};

export const fetchReferralCoupon = (id: string) => async (
  dispatch: Dispatch<any>
) => {
  dispatch(CouponActions.applyCouponRequest());
  try {
    const resp = await fetchApiWithPromise(
      `/api/v1/referral-pending-aligner-credit?id=${id}`,
      RequestMethods.GET
    );
    dispatch(CouponActions.fetchReferralCouponSuccess(resp.data));
  } catch (e) {
    dispatch(
      CouponActions.fetchReferralCouponFailure(
        getMessageFromErrorNested(e.response.data)
      )
    );
  }
};
