import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';
import { path } from 'ramda';
import { withPromise } from 'hoc';
import { setEmail, getEmail } from 'core/storage';
import * as storage from '../storage';
import { getUserData } from '../api';

export default Component =>
  compose(
    withRouter,
    withPromise(getUserData, 'user'),
    lifecycle({
      async componentDidMount() {
        if (!storage.isAuthenticated()) {
          return this.props.history.replace('/login');
        }

        this.props.user.fetch();
        datadogRum.startSessionReplayRecording();
      },
      componentDidUpdate() {
        const currentEmail = path(['user', 'data', 'email'], this.props);
        const currentId = path(['user', 'data', 'id'], this.props);
        const storedEmail = getEmail();
        datadogRum.setUser({ email: currentEmail, id: currentId });
        if (!!currentEmail && currentEmail !== storedEmail) {
          setEmail(currentEmail);
        }
      },
    })
  )(({ isAuthenticated, ...props }) =>
    storage.isAuthenticated() && props.user.data ? (
      <Component {...props} />
    ) : null
  );
