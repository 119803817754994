import { FormikOption } from './index';
export interface StringMap {
  [key: string]: string;
}

export enum UsaStates {
  Al = 'AL',
  Ak = 'AK',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Dc = 'DC',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY',
  Pr = 'PR',
}

export const stateToFullMap: StringMap = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  AA: 'Armed Forces Americas',
  AE: 'Armed Forces Europe',
  AP: 'Armed Forces Pacific',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  VI: 'US Virgin Islands',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};


type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

export const convertEnumToFormikOptions = <T extends Enum<T>>(
  targetEnum: T,
  displayValueTransformer: (value: string) => string = (v) => v
): FormikOption[] => {
  return Object.keys(targetEnum).map((key) => {
    const castedKey = key as keyof typeof targetEnum;
    return {
      value: (targetEnum[castedKey] as unknown) as string,
      displayValue: displayValueTransformer(String(targetEnum[castedKey])),
    };
  });
};

/**
 *
 * Shared Options
 *
 */

export const usStateOptions = convertEnumToFormikOptions(
  UsaStates,
  (state) => stateToFullMap[state]
);

export const zipCodeValidator = (value: string) => {
  let zipRegex = /^\d{5}$/i;
  let error;

  if (!value) {
    error = 'Required';
  } else if (!value.match(zipRegex)) {
    error = 'Invalid zip';
  }
  return error;
};

const _capitalizeAWord = (word: string) =>
  word.charAt(0).toUpperCase() + word.substring(1);

export const capitalizeWords = (words: string) =>
  words.toLowerCase().split(' ').map(_capitalizeAWord).join(' ');