import React, { FunctionComponent } from 'react';
import { useQuery } from 'core/hooks/use-query';
import { useSelector } from 'react-redux';
import { getCases, getCaseLoadState } from 'store/cases';
import { Redirect, useParams } from 'react-router-dom';
import { Loading } from '@candidco/enamel';
import { LOAD_STATES } from 'core/constants';

export const CaseWrapper: FunctionComponent = ({ children }) => {
  const query = useQuery();
  const cases = useSelector(getCases);
  const loadState = useSelector(getCaseLoadState);
  const caseId = query.get('caseId');
  const bypassCase = query.get('bypassCase') || false;
  const { id: customerId } = useParams();

  if (!bypassCase) {
    if (
      loadState === LOAD_STATES.PENDING ||
      loadState === LOAD_STATES.UNDEFINED
    ) {
      return <Loading isCentered />;
    } else if (caseId && !cases.some(c => String(c.id) === caseId)) {
      return <Redirect to={`/aligners-kit/${customerId}`} />;
    }
  }

  return <>{children}</>;
};
