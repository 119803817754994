import React from 'react';

const defaultVerticalPadding = ' pv3';

export default ({
  disabled = false,
  wide = false,
  fullWidth,
  className = '',
  onClick = () => {},
  children,
  dataCy = '',
}) => {
  if (!className.includes('pv')) {
    className += defaultVerticalPadding;
  }
  return disabled ? (
    <button
      data-cy={dataCy}
      disabled
      className={`${wide ? 'ph6' : 'ph4'}
                  ${fullWidth ? 'w-100' : ''}
                  db bw0 b0 bg-moon-gray br2 white f6 button-reset
                  ${className}`}
    >
      {children}
    </button>
  ) : (
    <button
      data-cy={dataCy}
      onClick={onClick}
      className={`${wide ? 'ph6' : 'ph4'}
                  ${fullWidth ? 'w-100' : ''}
                  db bw0 bg-blue b0 br2 white pointer f6 hover-bg-black button-reset
                  ${className}`}
    >
      {children}
    </button>
  );
};
