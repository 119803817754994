import React from 'react';
import { InsuranceForm, InsuranceProvider } from 'store/insurance/types';

type AdditionalProps = {
  handleEdit(editMode: boolean): void;
  editMode: boolean;
  isPrimary: boolean;
  providers: InsuranceProvider[];
};

const Summary = ({
  providers,
  onederfulPayerId,
  primaryFirstName,
  primaryLastName,
  primaryMemberId,
  groupNumber,
  primaryBirthMonth,
  primaryBirthDay,
  primaryBirthYear,
  dependentFirstName,
  dependentLastName,
  dependentMemberId,
  dependentBirthMonth,
  dependentBirthDay,
  dependentBirthYear,
  isPrimary,
}: InsuranceForm & AdditionalProps) => {
  const providerName = providers.find(
    provider => provider?.value === onederfulPayerId
  );

  return (
    <div className="w-100 flex mt3" data-private data-dd-privacy="mask">
      <div className="w-70">
        <p className="mb3">
          <span className="b">Insurance</span>: {providerName?.label}
        </p>
        <p className="mb2">
          <span className="b">Primary Insurance Holder</span>:{' '}
          {[primaryFirstName, primaryLastName].join(' ')}
        </p>
        <p className="mb2">
          <span className="b">Primary D.O.B.</span>:{' '}
          {[primaryBirthMonth, primaryBirthDay, primaryBirthYear].join('-')}
        </p>
        <p className="mb2">
          <span className="b">Primary Member Id</span>: {primaryMemberId}
        </p>
        <p className="mb2">
          <span className="b">Group Id</span>: {groupNumber}
        </p>
        {!isPrimary && (
          <div className="pv2 br1 bt mt4 b--moon-gray">
            <p className="mb2">
              <span className="b">Dependent Name</span>:{' '}
              {[dependentFirstName, dependentLastName].join(' ')}
            </p>
            <p className="mb2">
              <span className="b">Dependent D.O.B.</span>:{' '}
              {[
                dependentBirthMonth,
                dependentBirthDay,
                dependentBirthYear,
              ].join('-')}
            </p>
            <p className="mb2">
              <span className="b">Dependent Member Id</span>:{' '}
              {dependentMemberId}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
