/* eslint-disable no-console */
import { isEmpty } from 'ramda';

const analytics = method => (...args) => {
  window && window.analytics && window.analytics[method](...args);
};
const track = analytics('track');
const identify = analytics('identify');

export default {
  identify: (email, name) => {
    identify(email, {
      name,
      email,
    });
  },
  track: (event, properties = {}) => {
    console.log(
      `%c Track: ${event}`,
      'background: #00bfa5; color: white; padding: 3px; width: 100%; font-size: 12px'
    );

    if (!isEmpty(properties)) {
      console.table(properties);
    }

    track(event, properties);
  },
  trackClick: (event, properties = {}) => {
    console.log(
      `%c TrackClick: ${event}`,
      'background: #619dea; color: white; padding: 3px; width: 100%; font-size: 12px'
    );

    if (!isEmpty(properties)) {
      console.table(properties);
    }

    track(`Click: ${event}`, properties);
  },
  trackError: (event, { error = 'Error', ...properties }) => {
    console.log(
      `%c TrackError: ${event}`,
      'background: #fd5969; color: white; padding: 3px; width: 100%; font-size: 12px'
    );
    console.log(error);
    track(`Error: ${event}`, {
      error,
      ...(!isEmpty(properties) && { properties }),
    });
  },
  paymentConfirmation: salesEmail => {
    track('modeling_kit_checkout_payment_confirmation', {
      sales_person_email: salesEmail,
    });
  },
  paymentConfirmationAligner: (customerId, couponCode) => {
    track('aligner_kit_checkout_payment_confirmation', {
      customerId,
      couponCode,
    });
  },
};
