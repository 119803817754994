import React from 'react';
import months from 'core/months';
import { MAX_MONTH_DAYS } from 'core/constants';

const inputClasses = `input-reset ba b--black-30 br2 pa2 db f6 outline-0`;

const BirthdayFields = (props: any) => {
  const {
    isPrimary,
    primaryBirthMonth,
    primaryBirthDay,
    primaryBirthYear,
    onChange,
  } = props;
  const fieldPrefix = isPrimary ? 'primary' : 'dependent';

  return (
    <div className="w-100 mb4 flex">
      <select
        className="w-50 ba br2 db f6 fw4 outline-0 b--black-20 bg-white pointer mr2"
        style={{ height: 35 }}
        name={`${fieldPrefix}BirthMonth`}
        onChange={onChange}
        value={primaryBirthMonth}
        required
      >
        <option value="">Month</option>
        {months.map(month => {
          return (
            <option value={month.value} key={month.value}>
              {month.label}
            </option>
          );
        })}
      </select>
      <select
        className="w-25 ba br2 db f6 fw4 outline-0 b--black-20 pointer mr2"
        style={{ height: 35 }}
        name={`${fieldPrefix}BirthDay`}
        onChange={onChange}
        value={primaryBirthDay}
        required
      >
        <option value="">Day</option>
        {[...Array(MAX_MONTH_DAYS)].map((_, index) => {
          const date = index + 1;
          const leadingZeroDay = ('0' + date).slice(-2);

          return (
            <option key={index} value={leadingZeroDay}>
              {date}
            </option>
          );
        })}
      </select>
      <input
        type="text"
        className={`${inputClasses} w-25`}
        name={`${fieldPrefix}BirthYear`}
        defaultValue={primaryBirthYear}
        placeholder="Year"
        onChange={onChange}
        minLength={4}
        maxLength={4}
        required
      />
    </div>
  );
};

export default BirthdayFields;
