import { produce } from 'immer';
import { LOAD_STATES } from 'core/constants';
import { CaseState } from './types';
import {
  CaseActions,
  CaseAction,
  TreatmentPlanStagingAction,
  TreatmentPlanStagingActions,
} from './actions';

const initialState: CaseState = {
  data: [
    {
      case_ref: undefined,
    },
  ],
  selectedCaseId: null,
  loadState: LOAD_STATES.UNDEFINED,
  errorMessage: '',
  treatmentPlanStagings: [],
};

export default (
  state = initialState,
  action: CaseActions | TreatmentPlanStagingActions
) =>
  produce(state, draft => {
    switch (action.type) {
      case CaseAction.FETCH_CASES_REQUEST:
        draft.loadState = LOAD_STATES.PENDING;
        draft.errorMessage = '';
        break;
      case CaseAction.FETCH_CASES_FAILURE:
        draft.errorMessage = action.payload.errorMessage;
        draft.loadState = LOAD_STATES.FAILURE;
        break;
      case CaseAction.FETCH_CASES_SUCCESS:
        draft.loadState = LOAD_STATES.SUCCESS;
        draft.data = action.payload.cases;
        break;
      case CaseAction.SELECT_CASE:
        draft.selectedCaseId = Number(action.payload.caseId);
        break;
      case TreatmentPlanStagingAction.FETCH_TREATMENT_PLAN_STAGINGS_REQUEST:
        draft.loadState = LOAD_STATES.PENDING;
        draft.errorMessage = '';
        break;
      case TreatmentPlanStagingAction.FETCH_TREATMENT_PLAN_STAGINGS_FAILURE:
        draft.errorMessage = action.payload.errorMessage;
        draft.loadState = LOAD_STATES.FAILURE;
        break;
      case TreatmentPlanStagingAction.FETCH_TREATMENT_PLAN_STAGINGS_SUCCESS:
        draft.loadState = LOAD_STATES.SUCCESS;
        draft.treatmentPlanStagings = action.payload;
        break;
      default:
        break;
    }
  });

/* SELECTORS */

export const getSelectedCase = ({ cases }: { cases: CaseState }) => {
  return cases.data.find(c => c.id === cases.selectedCaseId);
};

export const getCases = ({ cases }: { cases: CaseState }) => {
  return cases.data;
};

export const getActiveCase = ({ cases }: { cases: CaseState }) => {
  return cases.data.find(c => c.is_active);
};

export const getCaseLoadState = ({ cases }: { cases: CaseState }) =>
  cases.loadState;

export const getCaseErrorMessage = ({ cases }: { cases: CaseState }) =>
  cases.errorMessage;

export const getLatestTreatmentPlanStaging = ({
  cases,
}: {
  cases: CaseState;
}) => cases?.treatmentPlanStagings?.slice(-1)[0];
