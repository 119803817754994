/* eslint-disable no-param-reassign */
import { datadogRum } from '@datadog/browser-rum';
import { Context } from '@datadog/browser-core';
import { produce } from 'immer';
import { ProductActions, ProductAction } from 'store/products/actions';
import {
  ProductState,
  ProductCatalog,
  ProductCatalogDetail,
  ProductResponse,
} from 'store/products/types';
import { LOAD_STATES } from 'core/constants';
import Dinero from 'dinero.js';
import { RootState } from 'store/store';

const initialState: ProductState = {
  selectedProductSku: undefined,
  quantity: 1,

  productCatalog: {},
  productCatalogLoadState: LOAD_STATES.UNDEFINED,
  productCatalogErrorMessage: '',

  catalog: {},
  catalogLoadState: LOAD_STATES.UNDEFINED,
  catalogErrorMessage: '',
};

const catalogFactory = (action: { payload: ProductResponse[] }) =>
  action.payload
    .filter(product => product.product_type !== 'MARKETING_MATERIAL')
    .reduce((a, c) => {
      // Convert '1.00' to 100
      const priceInCents = Number(c.price.replace(/[^0-9.-]+/g, '')) * 100;
      // Translate type to ProductCatalogDetail
      const catalogItem = (c as unknown) as ProductCatalogDetail;
      catalogItem.price = Dinero({ amount: priceInCents });
      catalogItem.alignerCheckout = isAlignerCheckoutSku(catalogItem);
      catalogItem.taxable = isTaxableSku(catalogItem);
      catalogItem.showQuantity = showQuantity(catalogItem);
      catalogItem.showAlignerSelection = showAlignerSelection(catalogItem);
      catalogItem.showReplacementSelection = showReplacementSelection(
        catalogItem
      );

      a[catalogItem.sku] = catalogItem;

      return a;
    }, {} as ProductCatalog);

export type ExceptionProductCatalogDetail = ProductCatalogDetail & {
  quantity: number | undefined;
};
const buildFromPaymentSource = ({
  payment,
  products,
}: RootState): ExceptionProductCatalogDetail => {
  const retailUnitPrice = payment.data.orders
    .find(o => o.skus_in_order.some(sku => sku === products.selectedProductSku))
    ?.orderitems.find(oi => oi.product_sku === products.selectedProductSku)
    ?.unit_price;

  return ({
    name: products.selectedProductSku,
    price: Dinero({
      amount:
        typeof retailUnitPrice === 'undefined' ? 0 : retailUnitPrice * 100,
    }),
    quantity: 1,
  } as unknown) as ExceptionProductCatalogDetail;
};

// These should all eventually come from the product service but are a inbetween.
const isAlignerCheckoutSku = (product: ProductCatalogDetail) =>
  new RegExp('AK[0]{4}(7|8)|(ZOOMWHITENING[0]{4}(1|2|3))').test(product.sku);
const isTaxableSku = (product: ProductCatalogDetail) =>
  new RegExp('(AK[0]{4}(7|8))|((ZOOMWHITENING|TOOTHBRUSH)[0]{4}(1|2|3))').test(
    product.sku
  );
const showQuantity = (product: ProductCatalogDetail) =>
  new RegExp('(TOOTHBRUSH[0]{4}(1|2|3))|((AKREPL|RT)[0]{3,4}1)|((ATTREPL)[0]{3,4}1)').test(
    product.sku
  );
const showAlignerSelection = (product: ProductCatalogDetail) =>
  new RegExp('(AKREPL|GL-AKREPL)[0]{3,4}1').test(product.sku);
const showReplacementSelection = (product: ProductCatalogDetail) =>
  'ATTREPL0001' === product.sku;

export default (state = initialState, action: ProductActions) =>
  produce(state, draft => {
    switch (action.type) {
      case ProductAction.SELECT_PRODUCT:
        draft.selectedProductSku = action.payload.productSku;
        draft.quantity = 1;
        break;
      case ProductAction.SELECT_QUANTITY:
        draft.quantity = action.payload;
        break;

      case ProductAction.FETCH_SCOPED_PRODUCTS_REQUEST:
        draft.productCatalogLoadState = LOAD_STATES.PENDING;
        break;
      case ProductAction.FETCH_SCOPED_PRODUCTS_SUCCESS:
        draft.productCatalog = catalogFactory(action);
        draft.productCatalogLoadState = LOAD_STATES.SUCCESS;
        break;
      case ProductAction.FETCH_SCOPED_PRODUCTS_FAILURE:
        draft.productCatalogLoadState = LOAD_STATES.FAILURE;
        draft.productCatalogErrorMessage = action.payload;
        break;
      case ProductAction.CLEAR_SCOPED_PRODUCTS:
        draft = initialState;
        break;
      case ProductAction.FETCH_CATALOG_REQUEST:
        draft.catalogLoadState = LOAD_STATES.PENDING;
        break;
      case ProductAction.FETCH_CATALOG_SUCCESS:
        draft.catalog = catalogFactory(action);
        draft.catalogLoadState = LOAD_STATES.SUCCESS;
        break;
      case ProductAction.FETCH_CATALOG_FAILURE:
        draft.catalogLoadState = LOAD_STATES.FAILURE;
        draft.catalogErrorMessage = action.payload;
        break;
      default:
        break;
    }
  });

/* SELECTORS */

export const getSelectedProduct = (state: RootState) => {
  const { products, payment } = state;
  if (!products.selectedProductSku) return {} as ExceptionProductCatalogDetail;
  try {
    const selectedProduct =
      products.productCatalog[products.selectedProductSku];
    if (typeof selectedProduct === 'undefined') {
      datadogRum.addError(
        new Error(
          `Product SKU was selected but doesn't exist in product catalog.`
        ),
        ({
          selectedProductSku: products.selectedProductSku,
          reduxStateSnapshot: { products, payment },
        } as unknown) as Context
      );
      return buildFromPaymentSource(state);
    }

    return {
      ...selectedProduct,
      quantity: getSelectedQuantity({ products }),
    };
  } catch (e) {
    datadogRum.addError(e);
    return buildFromPaymentSource(state);
  }
};

export const getSelectedProductSKU = ({
  products,
}: {
  products: ProductState;
}) => products.selectedProductSku;

export const getSelectedQuantity = ({ products }: { products: ProductState }) =>
  products.quantity;

export const getProductCatalogMap = ({
  products,
}: {
  products: ProductState;
}) => products.productCatalog;

export const getCatalogMap = ({ products }: { products: ProductState }) =>
  products.catalog;

export const getProductPriceLoadState = ({
  products,
}: {
  products: ProductState;
}) => products.productCatalogLoadState;

export const getProductPriceErrorMessage = ({
  products,
}: {
  products: ProductState;
}) => products.productCatalogErrorMessage;
