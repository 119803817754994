import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Formik, FormikErrors, useFormikContext } from 'formik';
import {
  FormikInputWrapper,
  FormikSelectWrapper,
} from 'components/pages/AlignersKit/Checkout/components/FormikForms';
import { type, Grid } from '@candidco/enamel';
import { AddressFields } from 'components/pages/AlignersKit/Checkout/utils/validation/types';
import { validateAddress } from 'components/pages/AlignersKit/Checkout/utils/validation/smartystreet';
import { ValidatedAddress } from 'components/pages/AlignersKit/Checkout/utils/validation/types';
import { usStateOptions, zipCodeValidator } from 'components/pages/AlignersKit/Checkout/components/FormikForms/utils';
import AddressConfirmation from 'components/pages/AlignersKit/Checkout/components/AddressConfirmation';
import { SelectedAddress as AddressFormType } from 'components/pages/AlignersKit/Checkout/components/ShippingAddressSelection';
import { CartActions } from 'store/cart/actions';
import { getDraftGenericCheckout } from 'store/payment';
import { genericCheckout, PaymentActions } from 'store/payment/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerData } from 'store/customer';

const FormContainer = styled.form`
  margin-bottom: 2rem;
`;

const SubText = styled.span`
  color: ${({ theme }) => theme.colors.black70};
  margin-left: 0.5rem;
`;

const MainText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

//
// Gets input from the smarty streets api, and compares it to the
// user's entered address. If only one result is returned, and it's a case insensitive match
// return true
//
export const enteredAddressMatchesValidatedAddress = (
  validatedAddresses: ValidatedAddress[],
  input: AddressFormType
) => {
  if (validatedAddresses.length !== 1) {
    return false;
  }

  const validatedAddress: ValidatedAddress = validatedAddresses[0];

  let inputDeliveryLine = input.address_line_1;
  if (input.address_line_2) {
    inputDeliveryLine += ' ' + input.address_line_2;
  }

  if (
    inputDeliveryLine?.toUpperCase() !==
    validatedAddress.delivery_line_1.toUpperCase()
  ) {
    return false;
  }

  if (
    input.city?.toUpperCase() !==
    validatedAddress.components.city_name.toUpperCase()
  ) {
    return false;
  }

  if (
    input.state_code?.toUpperCase() !==
    validatedAddress.components.state_abbreviation.toUpperCase()
  ) {
    return false;
  }

  if (input.zip !== validatedAddress.components.zipcode) {
    return false;
  }

  return true;
};

export const validatedAddressToAddressFormType = (
  input: ValidatedAddress,
  business_name?: string | null
): AddressFormType => {
  const ret: AddressFormType = {
    address_line_1: `${input.components.primary_number} ${input.components.street_name} ${input.components.street_suffix}`,
    city: `${input.components.city_name}`,
    state_code: `${input.components.state_abbreviation}`,
    zip: `${input.components.zipcode}`,
  };

  if (
    input.components.primary_number &&
    input.components.secondary_designator
  ) {
    ret.address_line_2 = `${input.components.secondary_designator} ${input.components.secondary_number}`;
  }

  if (business_name) {
    ret.business_name = business_name;
  }
  return ret;
};

export const LabelWithSubText = ({
  mainText,
  subText,
}: {
  mainText: string;
  subText: string | null;
}) => {
  return (
    <>
      <MainText>{mainText}</MainText>
      {subText && <SubText>{`• ${subText}`}</SubText>}
    </>
  );
};

export const ShippingAddressFormFields = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikInputWrapper
            name="business_name"
            type="text"
            label={
              <LabelWithSubText
                mainText="Business name"
                subText="Only if shipping to a business"
              />
            }
            data-private
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormikInputWrapper
            label="Address*"
            name="address_line_1"
            type="text"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikInputWrapper
            label="Apt, suite, etc."
            name="address_line_2"
            type="text"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormikInputWrapper label="City*" name="city" type="text" />
        </Grid>
        <Grid item xs={4}>
          <FormikSelectWrapper
            label="State*"
            name="state_code"
            options={usStateOptions}
            type="select"
          />
        </Grid>
        <Grid item xs={4}>
          <FormikInputWrapper
            label="ZIP code"
            name="zip"
            type="text"
            validate={zipCodeValidator}
          />
        </Grid>
      </Grid>
    </>
  );
};

const validateForm = (values: AddressFormType) => {
  const errors: FormikErrors<AddressFormType> = {};
  if (!values['address_line_1']?.trim()) {
    errors['address_line_1'] = 'Please input patient address';
  }
  if (!values['city']?.trim()) {
    errors['city'] = 'Please input patient city';
  }
  if (!values['state_code']?.trim()) {
    errors['state_code'] = 'Please input patient state';
  }
  if (!values['zip']?.trim()) {
    errors['zip'] = 'Please input patient zipcode';
  }
  return errors;
};

const BasicAddressFormInner = () => {
  // handleSubmit is a function that Formik provides us to call when the form is submitted
  const { handleSubmit } = useFormikContext<AddressFormType>();
  let submitRef = React.createRef<HTMLButtonElement>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (submitRef.current) {
      dispatch(CartActions.setSubmitRef(submitRef.current));
    }
  }, [submitRef, dispatch]);

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <type.H5>Shipping address</type.H5>
        </Grid>
      </Grid>
      <ShippingAddressFormFields />
      <button ref={submitRef} style={{ display: `${submitRef && 'none'}` }}>
        Submit
      </button>
    </FormContainer>
  );
};

const BasicAddressForm = () => {
  const [addressSuggestions, setAddressSuggestions] = useState<
    AddressFormType[]
  >([]);
  const dispatch = useDispatch();
  const customerInfo = useSelector(getCustomerData);
  const draftGenericCheckout = useSelector(getDraftGenericCheckout);

  const addressInitialValues = {
    business_name: undefined,
    address_line_1: '',
    address_line_2: '',
    city: '',
    state_code: '',
    zip: '',
  };
  const [displayAddressConfirmation, setDisplayAddressConfirmation] = useState(
    false
  );

  const onFormSubmit = (address: any) =>{
    dispatch(CartActions.setShippingAddress(address));
    if (draftGenericCheckout) {
      dispatch(genericCheckout(draftGenericCheckout));
    } else {
      dispatch(PaymentActions.genericCheckoutFailure("Unexpected error while checking out with generic checkout and address form"));
    }
  }

  const sanitizeAddress = (address: AddressFormType) => {
    return {
      phone: customerInfo?.phone,
      first_name: customerInfo?.first_name,
      last_name: customerInfo?.last_name,
      address_line_1: address.address_line_1 ?? '',
      address_line_2: address.address_line_2 ?? '',
      city: address.city ?? '',
      state_code: address.state_code ?? '',
      zip: address.zip ?? '',
      country_code: 'US',
      business_name:
        address.business_name === '' ? undefined : address.business_name,
    };
  };

  const onSubmit = async (values: AddressFormType) => {
    const { address_line_1, address_line_2, city, state_code, zip } = values;
    const shippingAddress: AddressFields = {
      address_line_1: address_line_1 ?? '',
      address_line_2: address_line_2 ?? '',
      city: city ?? '',
      state_code: state_code ?? '',
      zip: zip ?? '',
    };
    const suggestions = await validateAddress(shippingAddress);
    //if there is only one change and it matches, don't display the modal
    if (enteredAddressMatchesValidatedAddress(suggestions, shippingAddress)) {
      await onFormSubmit(sanitizeAddress(values));
    } else {
      setAddressSuggestions([
        values,
        ...suggestions.map((a) =>
          validatedAddressToAddressFormType(a, values.business_name)
        ),
      ]);
      setDisplayAddressConfirmation(true);
    }
  };

  const addressConfirmationOnConfirm = (value: AddressFormType) => {
    setDisplayAddressConfirmation(false);
    onFormSubmit(sanitizeAddress(value as AddressFormType));
  };

  return (
    <>
      <AddressConfirmation
        isOpen={displayAddressConfirmation}
        onCancel={() => setDisplayAddressConfirmation(false)}
        onConfirm={addressConfirmationOnConfirm}
        suggestions={addressSuggestions}
      />
      <Formik
        initialValues={addressInitialValues}
        onSubmit={onSubmit}
        validate={validateForm}
      >
        <BasicAddressFormInner/>
      </Formik>
    </>
  );
};

export default BasicAddressForm;