import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dinero from 'dinero.js';
import { getReferralCoupon, getAppliedCoupon, getCoupon } from 'store/coupon';
import PaymentHistory from 'components/pages/AlignersKit/Checkout/components/PaymentHistory';
import { getCatalogMap, getSelectedProduct } from 'store/products';
import {
  getTotalAdjustedPrice,
  getOrderLoadState,
  getSelectedOrder,
  getFirstAKOrderSku,
} from 'store/payment';
import { LOAD_STATES, PRODUCT_TYPES } from 'core/constants';
import { getCustomerLoadState } from 'store/customer';
import { segment } from 'core/integrations';
import { getClaimEstimate, hasEstimateClaims } from 'store/insurance';
import { ProductSkus } from 'store/products/types';
import { ProductActions } from 'store/products/actions';

export const OrderSummary = () => {
  const dispatch = useDispatch();
  const coupon = useSelector(getAppliedCoupon);
  const currentCoupon = useSelector(getCoupon);
  const referralCoupon = useSelector(getReferralCoupon);
  const currentOrder = useSelector(getSelectedOrder);
  const firstAKSku = useSelector(getFirstAKOrderSku);
  const insuranceEstimate = useSelector(getClaimEstimate);
  const hasEstimateInsurance = useSelector(hasEstimateClaims);
  const couponValue = currentCoupon.couponAmount;
  const doesCouponValueExist = couponValue.getAmount() > 0;
  const referralCouponApplied =
    referralCoupon?.coupon_code === currentCoupon?.couponCode;

  const { name, price, quantity } = useSelector(getSelectedProduct);
  const totalAdjustedPrice = useSelector(getTotalAdjustedPrice);
  const orderLoadState = useSelector(getOrderLoadState);
  const customerLoadState = useSelector(getCustomerLoadState);
  const isLoading =
    orderLoadState === LOAD_STATES.PENDING ||
    customerLoadState === LOAD_STATES.PENDING;
  const safePrice = price ?? Dinero({ amount: 0 });
  const subtotal = safePrice.multiply(quantity ?? 0).toFormat('$0,0.00');
  const hasInsurableProduct = useMemo(
    () =>
      currentOrder?.product_types_in_order.includes('ALIGNER_GOOD') ?? false,
    [currentOrder]
  );

  useEffect(() => {
    dispatch(ProductActions.selectProduct(firstAKSku));
  }, [firstAKSku, dispatch]);

  useEffect(() => {
    segment.track('OrderSummary', {
      name,
      quantity,
      subtotal,
      ...(doesCouponValueExist && coupon),
      totalAdjustedPrice: totalAdjustedPrice.toFormat('$0,0.00'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtotal]);

  if (isLoading) {
    return <div>Loading order summary...</div>;
  }
  return (
    <>
      <div className="mt2 mb3 bb b--black-10 pb3">
        <OrderLineItems />
        {doesCouponValueExist && (
          <div className="flex mt3">
            {referralCouponApplied ? `Discount**:` : `Discount:`}
            <div className="ml-auto">-{couponValue.toFormat('$0,0.00')}</div>
          </div>
        )}

        {hasInsurableProduct && hasEstimateInsurance && (
          <div data-cy="OrderSummaryInsurance">
            <div className="flex mt3">
              Discount (insurance):
              <div className="ml-auto" id="insurance-discount">
                -{insuranceEstimate.additionalDiscount.toFormat('$0,0.00')}
              </div>
            </div>
            <div className="flex mt3">
              Insurance coverage:
              <div className="ml-auto" id="insurance-coverage">
                -{insuranceEstimate.claimableAmount.toFormat('$0,0.00')}
              </div>
            </div>
          </div>
        )}
        <div className="flex mt3 fw6">
          Total:{' '}
          <div className="ml-auto">
            {totalAdjustedPrice.toFormat('$0,0.00')}
          </div>
        </div>
      </div>
      <PaymentHistory />

      {referralCouponApplied && (
        <div>
          <p>
            {coupon &&
              `** This customer is receiving a $${coupon.coupon_value} discount from a referral, via the referral code ${coupon.coupon_code}.`}
          </p>
        </div>
      )}
    </>
  );
};

const OrderLineItems = () => {
  const activeOrder = useSelector(getSelectedOrder);
  const claimsEstimate = useSelector(getClaimEstimate);
  const productCatalog = useSelector(getCatalogMap);

  return (
    <>
      {activeOrder?.orderitems
        .filter(
          orderItem => orderItem.product_sku !== ProductSkus.AlignerGood
          // hide the aligner good from the order so it's not rendered to avoid confusion
        )
        .map((orderItem, index) => {
          // orderItem.total represents plus/minus any possible deductions and price modifications
          let price = Dinero({ amount: orderItem.total * 100 });
          if (orderItem.product_type === PRODUCT_TYPES.ALIGNERS_KIT) {
            // NOTE: this assumes that insurance claims are ONLY on aligners.
            // This is to ensure that we support both the variable pricing of AKs (candid pro)
            // and insurance discounts.
            price = price
              .add(claimsEstimate.additionalDiscount)
              .add(claimsEstimate.claimableAmount);
          }

          return (
            <div key={index} className="flex">
              {productCatalog[orderItem.product_sku]?.name ||
                orderItem.product_sku}{' '}
              {orderItem.quantity > 1 ? ` (x${orderItem.quantity})` : ':'}
              <div className="ml-auto">{price.toFormat('$0,0.00')}</div>
            </div>
          );
        })}
    </>
  );
};
