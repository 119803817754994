import React, { useEffect } from 'react';
import CheckoutView from 'components/pages/AlignersKit/Checkout/View';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CaseActions, fetchCasesForCustomer } from 'store/cases/actions';
import { CaseSelection } from '../CaseSelection';
import { CaseWrapper } from '../CaseWrapper';
import { fetchCatalog } from 'store/products/actions';

export const CheckoutViewWrapper = () => {
  const dispatch = useDispatch();
  let query = new URLSearchParams(useLocation().search);
  const caseId = query.get('caseId') as number | null;
  const { id: customerId } = useParams();

  useEffect(() => {
    dispatch(fetchCasesForCustomer(customerId));
  }, [customerId, dispatch]);

  useEffect(() => {
    dispatch(fetchCatalog());
  }, [dispatch]);

  if (caseId === null) {
    return <CaseSelection />;
  } else {
    dispatch(CaseActions.selectCase(caseId));
  }

  return (
    <CaseWrapper>
      <CheckoutView />
    </CaseWrapper>
  );
};
