/* eslint-disable no-param-reassign */
import Dinero from 'dinero.js';
import { produce } from 'immer';
import { LOAD_STATES } from 'core/constants';
import { getSelectedProductSKU } from 'store/products';
import { ProductState } from 'store/products/types';
import { InsuranceActions, InsuranceAction } from './actions';
import {
  ProviderData,
  InsuranceState,
  Insurance,
  InsuranceRequestPayload,
} from './types';
import { InsuranceProvider } from 'store/insurance/types';

type State = {
  data: {
    providers: InsuranceProvider[] | null;
    insurance: InsuranceRequestPayload | null;
    eligibility: any;
  };
  loadState: {
    fetchInsurance: LOAD_STATES;
    saveOrUpdateInsurance: LOAD_STATES;
  };
  errorMessage: string | null;
  inNetwork: boolean;
  isValidInsurance: boolean;
};

/** Reducer */
const initialState: State = {
  data: {
    providers: [],
    insurance: null,
    eligibility: null,
  },
  loadState: {
    fetchInsurance: LOAD_STATES.UNDEFINED,
    saveOrUpdateInsurance: LOAD_STATES.UNDEFINED,
  },
  errorMessage: null,
  inNetwork: false,
  isValidInsurance: false,
};

export default (state = initialState, action: InsuranceActions) =>
  produce(state, draft => {
    switch (action.type) {
      case InsuranceAction.SAVE_INSURANCE_REQUEST:
        draft.loadState.saveOrUpdateInsurance = LOAD_STATES.PENDING;
        break;
      case InsuranceAction.SAVE_INSURANCE_SUCCESS:
        draft.data.insurance = action.payload;
        draft.loadState.saveOrUpdateInsurance = LOAD_STATES.SUCCESS;
        draft.errorMessage = null;
        break;
      case InsuranceAction.SAVE_INSURANCE_FAILURE:
        draft.loadState.saveOrUpdateInsurance = LOAD_STATES.FAILURE;
        draft.errorMessage = action.payload;
        break;
      case InsuranceAction.FETCH_INSURANCE_REQUEST:
        draft.loadState.fetchInsurance = LOAD_STATES.PENDING;
        break;
      case InsuranceAction.FETCH_INSURANCE_SUCCESS:
        draft.data.insurance = action.payload.data;
        draft.isValidInsurance = action.payload.valid;
        draft.loadState.fetchInsurance = LOAD_STATES.SUCCESS;
        draft.errorMessage = null;
        break;
      case InsuranceAction.FETCH_INSURANCE_FAILURE:
        draft.loadState.fetchInsurance = LOAD_STATES.FAILURE;
        draft.errorMessage = action.payload;
        break;
      case InsuranceAction.CHECK_ELIGIBILITY:
        draft.data.eligibility = action.payload;
        break;
      case InsuranceAction.SET_IN_NETWORK:
        draft.inNetwork = action.payload;
        break;
      default:
        break;
    }
  });

/** Selectors */
export const getInsuranceProviders = ({ insurance }: InsuranceState) =>
  insurance.data.providers?.map((provider: ProviderData) => {
    return {
      value: provider.id,
      label: provider.name,
      ...provider,
    };
  }) || [];

export const getInsuranceForm = ({ insurance }: InsuranceState) =>
  insurance.data.insurance;

export const hasValidInsurance = ({ insurance }: InsuranceState) =>
  insurance.isValidInsurance;

export const getInsuranceError = ({ insurance }: InsuranceState) =>
  insurance.errorMessage;

export const getAllClaimEligibility = ({ insurance }: InsuranceState) =>
  insurance.data.eligibility;

export const getClaimEstimate = ({
  insurance,
  products,
}: {
  insurance: Insurance;
  products: ProductState;
}) => {
  const currentSku = getSelectedProductSKU({ products });
  const claim = getAllClaimEligibility({ insurance });

  if (!currentSku || !claim || !claim[currentSku]) {
    return {
      claimableAmount: Dinero({ amount: 0 }),
      additionalDiscount: Dinero({ amount: 0 }),
      inNetwork: false,
    };
  }

  const { claimableAmount, additionalDiscount, inNetwork } = claim[currentSku];

  // quick fix to make sure the claimableAmount is an int
  // the endpoint 'calculate-estimated-claims' returns a float
  return {
    claimableAmount: Dinero({ amount: parseInt(`${claimableAmount}`, 10) }),
    additionalDiscount: Dinero({
      amount: parseInt(`${additionalDiscount}`, 10),
    }),
    inNetwork,
  };
};

export const hasEstimateClaims = (state: any) => {
  return getClaimEstimate(state).inNetwork ?? false;
};

export const isInNetwork = ({ insurance }: InsuranceState) => {
  return insurance.inNetwork;
};

export const getInsuranceLoadState = ({ insurance }: InsuranceState) =>
  insurance.loadState;
