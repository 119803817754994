import Dinero from 'dinero.js';
import { LOAD_STATES } from 'core/constants';
export enum ProductSkus {
  AlignerCase = 'CXALIGNERCASE01',
  AlignerGood = 'AKG00001',
  AlignerKit = 'AK00001',
  AttachmentTemplateReplacement='ATTREPL0001',
  deprecatedAKAllegro = 'AK00002',
  deprecatedAKDM = 'AK00003',
  deprecatedAKDMAllegro = 'AK00004',
  deprecatedAlignerKitDentalMonitoring = 'AK00005',
  deprecatedAlignerKitDentalMonitoringTY = 'AK00006',
  AlignerKitDentalMonitoring = 'AK00007',
  AlignerKitTeenAndYouth = 'AK00008',
  Chewies = 'CXCHEWIES01',
  CheekRetractor = 'CXCHKRETRACTOR01',
  DM_CHEEK_RETRACTOR_MD = 'DMCHKRETRACTOR01',
  DM_SCAN_BOX = 'DMSCANBOX01',
  DM = 'DM00001',
  InTreatmentIssueFree = 'REFINE0001',
  InTreatmentIssuePaid = 'REFINE0003',
  ModelingKit = 'IKDIGITAL00005',
  PullTool = 'CXPULLTOOL01',
  Putty = 'CXPUTTY01',
  RedoKitSmall = 'RDKETTS0001',
  RedoKitMedium = 'RDKETTM0001',
  RedoKitLarge = 'RDKETTL0001',
  Retainers = 'RT00001',
  ReplacementRetainer = 'AKREPL0001',
  WhiteningFoam = 'CXWHITEFOAM01',
  WhiteningFoamPaid = 'WHITEFOAM01',
  ZoomWhitening = 'ZOOMWHITENING00001',
  ZoomWhiteningStandalone = 'ZOOMWHITENING00002',
  ZoomWhiteningStandalonePro = 'ZOOMWHITENING00003',
  WhiteningPen = 'ONEWHITEPEN01',
  InTreatmentIssueModelingKit = 'ITISK00001',
  PhilipsSonicareToothbrush = 'TOOTHBRUSH00001',
  SocialMediaDayOneGiftBag = 'FGS-0011',
}

export enum ProductType {
  Good = 'good',
  Service = 'service',
}

export type ProductResponse = {
  product_type: string;
  sku: ProductSkus;
  name: string;
  price: string; // dollar amount
  type: ProductType;
  is_default: boolean;
};

export type ProductCatalogDetail = {
  name: string;
  product_type: string;
  brand: string;
  sku: ProductSkus;
  type: ProductType;
  is_default: boolean;
  price: Dinero.Dinero;
  alignerCheckout: boolean;
  taxable: boolean;
  showQuantity: boolean;
  showAlignerSelection: boolean;
  showReplacementSelection: boolean;
};

export type ProductDetail = {
  name: string;
  price: Dinero.Dinero;
  alignerCheckout?: boolean;
  taxable?: boolean;
  showQuantity?: boolean;
  showAlignerSelection?: boolean;
};

export type ProductTypeDetail = Array<[ProductSkus, ProductCatalogDetail]>;

export type ProductCatalog = {[k: string]: ProductCatalogDetail}

export type ProductState = {
  selectedProductSku: ProductSkus | undefined;
  quantity: number;

  productCatalog: ProductCatalog;
  productCatalogLoadState: LOAD_STATES;
  productCatalogErrorMessage: string;

  catalog: ProductCatalog;
  catalogLoadState: LOAD_STATES;
  catalogErrorMessage: string;
};
