import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Field,
  FieldRenderProps,
  FormRenderProps,
} from 'react-final-form';
import { States } from 'core/states';
import { Input } from 'components/generic/form';
import { validateRequiredObjects } from 'core/form-utils';
import { Select } from 'components/generic/form/Select';
import { AddressStatic } from './AddressStatic';

const enabledButtonClassNames =
  'bw0 ph4 pv2 bg-blue br2 white outline-0 pointer';
const disabledButtonClassNames =
  'bw0 ph4 pv2 bg-moon-gray br2 mid-gray outline-0 disable';

type IProps = {
  isEditMode: boolean;
  onSave: (address: object) => void;
} & Address;

type Address = {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  country: string;
};

interface IFormRenderProps extends FormRenderProps {
  submitting: boolean;
  pristine: boolean;
}

export class AddressForm extends PureComponent<IProps> {
  static propTypes = {
    isEditMode: PropTypes.bool,
    onSave: PropTypes.func,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    phone: PropTypes.string,
    country: PropTypes.string,
  };

  static defaultProps = {
    addressLine2: '', // default values
    country: 'USA', // default values
    isEditMode: false,
  };

  state = {
    hasErrors: false,
  };

  handleOnSave = (data: any) => {
    const { onSave, isEditMode, ...addressData } = data;
    this.props.onSave(addressData);
  };

  validateValues = (values: Address) => {
    const {
      isEditMode,
      onSave,
      addressLine2,
      country,
      ...requiredProps
    } = this.props;

    const errors = validateRequiredObjects(values, requiredProps as Omit<
      Address,
      'addressLine2' | 'country'
    >);
    this.setState({
      hasErrors: Object.keys(errors).length > 0,
    });

    return errors;
  };

  render() {
    const { isEditMode } = this.props;
    const { hasErrors } = this.state;

    if (!isEditMode) {
      return <AddressStatic {...this.props} />;
    }

    return (
      <div>
        <Form
          initialValues={this.props}
          onSubmit={this.handleOnSave}
          validate={data => this.validateValues(data as Address)}
        >
          {(props: IFormRenderProps) => {
            const { handleSubmit, submitting, pristine } = props;
            const isDisabled = hasErrors || pristine || submitting;

            return (
              // eslint-disable-next-line no-unused-expressions
              <form onSubmit={handleSubmit}>
                <div>
                  <div className="flex mt2">
                    <Field
                      type="text"
                      name="firstName"
                      component="input"
                      required
                    >
                      {({ input, meta }: FieldRenderProps) => (
                        <div className="w-50" data-private data-dd-privacy="mask">
                          <Input
                            input={input}
                            type="text"
                            meta={meta}
                            required
                            placeholder="First Name"
                            className="input-reset ba b--black-30 br2 ph2 pv2 db f6 outline-0"
                          />
                        </div>
                      )}
                    </Field>
                    <Field
                      type="text"
                      name="lastName"
                      component="input"
                      required
                    >
                      {({ input, meta }: FieldRenderProps) => (
                        <div className="w-50 ml2">
                          <Input
                            input={input}
                            meta={meta}
                            type="text"
                            required
                            placeholder="Last Name"
                            className="input-reset ba b--black-30 br2 ph2 pv2 db f6 outline-0"
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="flex mt2">
                    <Field
                      type="text"
                      name="addressLine1"
                      id="frmAddressS"
                      required
                      component="input"
                      placeholder="Address"
                    >
                      {({ input, meta }: FieldRenderProps) => (
                        <div className="w-50" data-private data-dd-privacy="mask">
                          <Input
                            type="text"
                            input={input}
                            meta={meta}
                            required
                            placeholder="Address"
                            className="input-reset ba b--black-30 br2 ph2 pv2 db f6 outline-0"
                          />
                        </div>
                      )}
                    </Field>
                    <Field
                      type="text"
                      name="addressLine2"
                      id="frmAddressS"
                      component="input"
                    >
                      {({ input, meta }: FieldRenderProps) => (
                        <div className="w-50 ml2" data-private data-dd-privacy="mask">
                          <Input
                            type="text"
                            input={input}
                            meta={meta}
                            required={false}
                            placeholder="Additional Address"
                            className="input-reset ba b--black-30 br2 ph2 pv2 db f6 outline-0"
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="flex mt2">
                    <Field
                      type="text"
                      name="city"
                      required
                      component="input"
                      placeholder="City"
                    >
                      {({ input, meta }: FieldRenderProps) => (
                        <div className="w-50 pr1" data-private data-dd-privacy="mask">
                          <Input
                            type="text"
                            input={input}
                            meta={meta}
                            required
                            placeholder="City"
                            className="input-reset ba b--black-30 br2 ph2 pv2 db f6 outline-0"
                          />
                        </div>
                      )}
                    </Field>
                    <div className="flex w-50" style={{ maxHeight: '34px' }}>
                      <Field
                        type="text"
                        name="state"
                        required
                        component="select"
                        placeholder="State"
                      >
                        {({ input, meta }: FieldRenderProps) => (
                          <Select
                            type="state"
                            className="w-30 bg-white ba b--black-30 br2 ph2 pv2 db f6 outline-0 ml1"
                            input={input}
                            required
                            meta={meta}
                            placeholder="State"
                            height="34px"
                          >
                            <option value="">State</option>
                            {States.map(state => (
                              <option key={state.code} value={state.code}>
                                {state.code}
                              </option>
                            ))}
                          </Select>
                        )}
                      </Field>
                      <Field
                        type="text"
                        name="zip"
                        required
                        component="input"
                        placeholder="Zip"
                      >
                        {({ input, meta }: FieldRenderProps) => (
                          <div className="w-70 ml2" data-private data-dd-privacy="mask">
                            <Input
                              type="text"
                              input={input}
                              meta={meta}
                              required
                              placeholder="Zip"
                              className="input-reset ba b--black-30 br2 ph2 pv2 db f6 outline-0"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="flex mt2">
                    <Field
                      type="tel"
                      name="phone"
                      required
                      component="input"
                      placeholder="Phone"
                    >
                      {({ input, meta }: FieldRenderProps) => (
                        <div className="w-50" data-private data-dd-privacy="mask">
                          <Input
                            type="tel"
                            input={input}
                            meta={meta}
                            required
                            placeholder="Phone"
                            className="input-reset ba b--black-30 br2 ph2 pv2 db f6 outline-0"
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="flex mt2">
                    <Field
                      type="text"
                      name="country"
                      required
                      component="input"
                      placeholder="country"
                    >
                      {({ input, meta }: FieldRenderProps) => (
                        <div className="w-50">
                          <Input
                            type="text"
                            input={input}
                            meta={meta}
                            required
                            placeholder="country"
                            className="input-reset ba b--black-30 br2 ph2 pv2 db f6 outline-0"
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="flex mt2 justify-end ">
                    <button
                      type="submit"
                      disabled={isDisabled}
                      className={
                        isDisabled
                          ? disabledButtonClassNames
                          : enabledButtonClassNames
                      }
                    >
                      Save Information
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    );
  }
}
