import { produce } from 'immer';
import { LOAD_STATES, PRODUCT_TYPES } from 'core/constants';
import { CouponState, CouponData } from 'store/coupon/types';
import { CouponActions, CouponAction } from 'store/coupon/actions';
import { PaymentState } from 'store/payment/types';
import Dinero from 'dinero.js';
import { ProductState } from 'store/products/types';
import { getActiveAKOrder } from '../payment/index';
import { CartState } from 'store/cart/types';
import { ALIGNER_REFERRAL_COUPON_AMOUNT } from 'store/coupon/constants';
import { hasAlignerKitInCart } from 'store/cart';

const initialState: CouponState = {
  data: {
    applyCoupon: undefined,
    referralCoupon: undefined,
  },
  loadState: LOAD_STATES.UNDEFINED,
  errorMessage: '',
};

export default (state = initialState, action: CouponActions) =>
  produce(state, draft => {
    switch (action.type) {
      case CouponAction.APPLY_COUPON_REQUEST:
        draft.loadState = LOAD_STATES.PENDING;
        draft.errorMessage = '';
        break;
      case CouponAction.APPLY_COUPON_FAILURE:
        draft.errorMessage = action.payload.errorMessage;
        draft.loadState = LOAD_STATES.FAILURE;
        break;
      case CouponAction.APPLY_COUPON_SUCCESS:
        draft.loadState = LOAD_STATES.SUCCESS;
        draft.data.applyCoupon = action.payload.coupon;
        break;

      case CouponAction.FETCH_REFERRAL_COUPON_REQUEST:
        draft.loadState = LOAD_STATES.PENDING;
        draft.errorMessage = '';
        break;
      case CouponAction.FETCH_REFERRAL_COUPON_FAILURE:
        draft.errorMessage = action.payload.errorMessage.includes('Page not found') ? 'No referring coupon found' : action.payload.errorMessage;
        draft.loadState = LOAD_STATES.FAILURE;
        break;
      case CouponAction.FETCH_REFERRAL_COUPON_SUCCESS:
        draft.loadState = LOAD_STATES.SUCCESS;
        draft.data.referralCoupon = action.payload.coupon;
        break;
      case CouponAction.RESET_COUPON:
        draft.data.applyCoupon = initialState.data.applyCoupon;
        draft.loadState = LOAD_STATES.UNDEFINED;
        draft.errorMessage = '';
        break;
      default:
        break;
    }
  });

/* SELECTORS */
export const getReferralCoupon = ({ coupon }: { coupon: CouponState }) =>
  coupon.data.referralCoupon;

export const getAppliedCoupon = ({ coupon }: { coupon: CouponState }) =>
  coupon.data.applyCoupon;

export const getCouponLoadState = ({ coupon }: { coupon: CouponState }) =>
  coupon.loadState;

export const getCouponErrorMessgae = ({ coupon }: { coupon: CouponState }) =>
  coupon.errorMessage;

const couponValueToCents = (value: string | number) =>
  Dinero({
    amount: Math.round(Number(value) * 100),
  });

/**
 * Tiered Coupon getter
 * First checks for initially applied referral coupons
 * Second checks that there are no coupons on the order
 * Third checks for manually applied coupon (non-referral)
 * Lastly returns a default amount of zero
 * @param param0
 */
export const getCoupon = ({
  coupon,
  payment,
  products,
  cart,
}: {
  coupon: CouponState;
  payment: PaymentState;
  products: ProductState;
  cart: CartState;
}): CouponData => {
  const activeAKOrder = getActiveAKOrder({ payment, products });
  const appliedCoupon = getAppliedCoupon({ coupon });
  const hasAKInCart = hasAlignerKitInCart({ cart, products });

  let couponAmount = couponValueToCents('0');
  let couponCode = '';

  const referralCoupon = getReferralCoupon({ coupon });
  const isAnAKOrderInSelectedOrder =
    activeAKOrder?.product_types_in_order.includes(
      PRODUCT_TYPES.ALIGNERS_KIT
    ) ||
    hasAKInCart ||
    false;

  const shouldApplyReferralCoupon =
    !!referralCoupon?.coupon_code && isAnAKOrderInSelectedOrder;

  // NOTE here we're still blocking any udpates to the coupon if a referral coupon is
  // active. The reason why this is left here is because the coupon code is used
  // to give a reward to the referrer
  if (appliedCoupon) {
    // (1) Applied coupon that's manually applied
    couponAmount = couponValueToCents(appliedCoupon.coupon_value);
    couponCode = appliedCoupon.coupon_code;
  } else if (activeAKOrder?.coupon_code) {
    // (2) Coupon already part of an active order
    couponAmount = couponValueToCents(activeAKOrder.coupon_amount);
    couponCode = activeAKOrder.coupon_code;
  } else if (shouldApplyReferralCoupon) {
    // (3) Referral coupon is initially fetched and applied and an AK order is selected
      couponAmount = couponValueToCents(ALIGNER_REFERRAL_COUPON_AMOUNT);
      couponCode = referralCoupon?.coupon_code || '';
  } 

  return {
    couponAmount,
    couponCode,
  };
};

export const getCouponAmount = ({
  coupon,
  payment,
  products,
  cart,
}: {
  coupon: CouponState;
  payment: PaymentState;
  products: ProductState;
  cart: CartState;
}): Dinero.Dinero =>
  getCoupon({ coupon, payment, products, cart }).couponAmount;

export const getCouponCode = ({
  coupon,
  payment,
  products,
  cart,
}: {
  coupon: CouponState;
  payment: PaymentState;
  products: ProductState;
  cart: CartState;
}) =>
  getCoupon({
    coupon,
    payment,
    products,
    cart,
  }).couponCode;

export const getCouponValue = ({
  coupon,
  payment,
  products,
  cart,
}: {
  coupon: CouponState;
  payment: PaymentState;
  products: ProductState;
  cart: CartState;
}) =>
  getCoupon({
    coupon,
    payment,
    products,
    cart,
  }).couponAmount;
