import { datadogRum } from '@datadog/browser-rum';

export default {
  init: () => {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_TOKEN,
      site: 'datadoghq.com',
      service: 'candid-sales-ui',
      env: process.env.REACT_APP_CANDID_ENV,
      version: process.env.REACT_APP_VERSION,
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      allowedTracingOrigins: [/https:\/\/.*\.candidco\.com/],
    });
  },
  close: () => {
    datadogRum.stopSessionReplayRecording();
  },
};
