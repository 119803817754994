import React from 'react';
import { Overlay } from 'components/common';
import { Link } from 'react-router-dom';

const Success = () => (
  <Overlay>
    <div className="tc">
      <div className="f2 mt6">Success!</div>
      <Link to="/aligners-kit" className="mt2 black dib">
        Go back to the search
      </Link>
    </div>
  </Overlay>
);

export default Success;
