/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Raven from 'raven-js';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      this.setState({ error });

      Raven.captureBreadcrumb({
        message: 'Sales portal is broken for a user',
        type: 'error_screen',
      });

      Raven.captureException(error, { extra: errorInfo });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div className="vh-100 w-100 h-100 flex items-center justify-center">
          <div>
            <div className="f3 mb3">We're sorry — something's gone wrong.</div>
            <div>
              Our team has been notified, but click{' '}
              <a
                className="pointer underline"
                onClick={() => Raven.lastEventId() && Raven.showReportDialog()}
              >
                here
              </a>{' '}
              fill out a report.
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
