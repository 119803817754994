import React, { useState, useEffect, useReducer } from 'react';
import Select, { ValueType } from 'react-select';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerData } from 'store/customer';
import { LOAD_STATES } from 'core/constants';
import {
  getInsuranceForm,
  getInsuranceLoadState,
  getInsuranceError,
  getInsuranceProviders,
  hasValidInsurance,
} from 'store/insurance';
import { saveInsurance, InsuranceActions } from 'store/insurance/actions';
import BirthdayFields from './BirthdayFields';
import Summary from './Summary';
import {
  InsuranceForm,
  PrimaryInsuranceForm,
  DependentInsuranceForm,
  InsuranceSelectOption,
} from 'store/insurance/types';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const RadioOption = styled.div`
  display: inline-block;

  & + & {
    margin-left: 2rem;
  }
`;

const RadioLabel = styled.label`
  position: relative;
  display: inline-block;
  height: 30px;
  padding: 4px 10px 0 30px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  transition: color 200ms ease;

  &::before,
  &::after {
    position: absolute;
    content: '';
    border-radius: 50%;
    transition: transform 200ms ease, border-color 200ms ease;
  }

  &::before {
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgb(219, 219, 219);
  }

  &::after {
    top: 7px;
    left: 7px;
    width: 10px;
    height: 10px;
    background-color: #137752;
    transform: scale(0);
  }

  &:hover {
    color: rgb(251, 135, 43);

    &::before {
      border-color: rgb(251, 135, 43);
    }
  }
`;

const Radio = styled.input`
  display: inline;
  width: 0;
  margin: 0;
  overflow: hidden;
  opacity: 0;
  -webkit-appearance: none;

  &:checked {
    & + label {
      color: #137752;
    }

    & + label::before {
      border-color: #137752;
    }

    & + label::after {
      transform: scale(1);
    }
  }
`;

const inputClasses = `input-reset ba b--black-30 br2 pa2 db f6 outline-0 w-50`;

const errorMessageClasses =
  'bg-washed-red b--dark-red br1 ph3 mv3 br2 ba f6 b--solid';

const initialPrimaryFormState: PrimaryInsuranceForm = {
  onederfulPayerId: null,
  primaryFirstName: '',
  primaryLastName: '',
  primaryMemberId: '',
  groupNumber: '',
  primaryBirthMonth: '',
  primaryBirthDay: '',
  primaryBirthYear: '',
};

const initialDependentFormState: DependentInsuranceForm = {
  dependentFirstName: '',
  dependentLastName: '',
  dependentMemberId: '',
  dependentBirthMonth: '',
  dependentBirthDay: '',
  dependentBirthYear: '',
};

function reducer(
  state: any,
  { field, value }: { field: string; value: string | boolean }
) {
  return {
    ...state,
    [field]: value,
  };
}

const Insurance = () => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [isPrimary, setIsPrimary] = useState(true);
  const insuranceList = useSelector(getInsuranceProviders);
  const currentCustomer = useSelector(getCustomerData);
  const insuranceForm = useSelector(getInsuranceForm);
  const isValidInsurance = useSelector(hasValidInsurance);
  const { saveOrUpdateInsurance } = useSelector(getInsuranceLoadState);
  const errorMessage = useSelector(getInsuranceError);

  const ui = {
    pending: saveOrUpdateInsurance === LOAD_STATES.PENDING,
    success: saveOrUpdateInsurance === LOAD_STATES.SUCCESS,
    failure: saveOrUpdateInsurance === LOAD_STATES.FAILURE,
  };

  const [primaryForm, dispatchPrimaryForm] = useReducer(
    reducer,
    initialPrimaryFormState
  );

  const [dependentForm, dispatchDependentForm] = useReducer(
    reducer,
    initialDependentFormState
  );

  const [errorFields, dispatchErrorFields] = useReducer(reducer, {});

  const {
    onederfulPayerId,
    primaryFirstName,
    primaryLastName,
    primaryMemberId,
    primaryBirthMonth,
    primaryBirthDay,
    primaryBirthYear,
    groupNumber,
  } = primaryForm;

  const {
    dependentFirstName,
    dependentLastName,
    dependentMemberId,
  } = dependentForm;

  const setInitialInsuranceData = () => {
    if (!insuranceForm?.primaryDateOfBirth) return;

    const [
      primaryBirthYear,
      primaryBirthMonth,
      primaryBirthDay,
    ] = insuranceForm.primaryDateOfBirth.split('-');

    const formattedForm = {
      ...insuranceForm,
      primaryBirthMonth,
      primaryBirthDay,
      primaryBirthYear,
    };

    Object.entries(primaryForm).forEach(([field, _]) => {
      // @ts-ignore
      dispatchPrimaryForm({ field, value: formattedForm[field] });
    });
  };

  useEffect(() => {
    if (!insuranceForm) return;
    // If user has insurance saved, prefill fields
    setInitialInsuranceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceForm, errorMessage, isValidInsurance]);

  // Prefill initial values from Customer Data
  useEffect(() => {
    if (insuranceForm) return;

    const { first_name, last_name, birthday } = currentCustomer;
    dispatchPrimaryForm({ field: 'primaryFirstName', value: first_name });
    dispatchPrimaryForm({ field: 'primaryLastName', value: last_name });

    if (birthday) {
      const [year, month, day] = birthday.split('-');
      dispatchPrimaryForm({ field: 'primaryBirthYear', value: year });
      dispatchPrimaryForm({ field: 'primaryBirthMonth', value: month });
      dispatchPrimaryForm({ field: 'primaryBirthDay', value: day });
    }
  }, [currentCustomer, insuranceForm, insuranceList]);

  useEffect(() => {
    const prefillValues = ['FirstName', 'LastName'];

    // Patient is not primary policy holder, prefill dependent name fields
    if (!isPrimary) {
      // Switch primary and dependent name fields
      prefillValues.forEach(fieldName => {
        const field = `dependent${fieldName}`;
        const value = primaryForm[`primary${fieldName}`];

        dispatchDependentForm({ field, value });
      });

      // Clear primary policy holder name fields
      prefillValues.forEach(fieldName => {
        const field = `primary${fieldName}`;
        dispatchPrimaryForm({ field, value: '' });
      });
    } else {
      // Set primary name fields
      prefillValues.forEach(fieldName => {
        const field = `primary${fieldName}`;
        const value = primaryForm[`dependent${fieldName}`];

        dispatchPrimaryForm({ field, value });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrimary]);

  const onPrimaryFormChange = (e: any) =>
    dispatchPrimaryForm({ field: e.target.name, value: e.target.value });

  const handleInsuranceChange = (
    selected: ValueType<InsuranceSelectOption>
  ) => {
    dispatchPrimaryForm({
      field: 'onederfulPayerId',
      value: (selected as InsuranceSelectOption).value,
    });
  };

  const onDependentFormChange = (e: any) =>
    dispatchDependentForm({ field: e.target.name, value: e.target.value });

  const validateFormValues = (values: InsuranceForm) => {
    return Object.entries(values).filter(([_, val]) => !Boolean(val));
  };

  const clearFormErrors = () => {
    Object.keys(errorFields).forEach((field: string) => {
      dispatchErrorFields({ field, value: false });
    });
  };

  const cancelEdit = () => {
    // Reset to initial data
    setInitialInsuranceData();
    setEditMode(false);
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formValues = {
      ...primaryForm,
      ...(!isPrimary && dependentForm),
    };

    clearFormErrors();

    const emptyFields = validateFormValues(formValues)
      .flat()
      .filter(Boolean);

    if (emptyFields.length > 0) {
      dispatch(
        InsuranceActions.saveInsuranceFailure(`Please fill in all fields`)
      );
      emptyFields.forEach(field => {
        if (field) {
          // @ts-ignore
          dispatchErrorFields({ field, value: true });
        }
      });

      return;
    }

    dispatch(saveInsurance(formValues));
  };

  if (!editMode) {
    return (
      <div className="w-100 flex flex-column">
        <a
          href={`${process.env.REACT_APP_API}/admin/app/customer/${currentCustomer.id}/change/#insurance`}
          target="_blank"
          rel="noopener noreferrer"
          className="mt3"
        >
          Edit Insurance Info (opens in new tab)
        </a>
        <Summary
          {...primaryForm}
          {...dependentForm}
          isPrimary={isPrimary}
          handleEdit={setEditMode}
          editMode={editMode}
          providers={insuranceList}
        />
        {ui.success && (
          <div
            className="b--green bg-washed-green b dark-green ba br1 pa3 mt3 w-100"
            data-cy="insuranceSuccessMessage"
          >
            Insurance information successfully processed.
          </div>
        )}
        {errorMessage && (
          <div className={errorMessageClasses}>
            <p className="b dark-red f5">{errorMessage}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <Form
      className="w-100"
      data-cy="insuranceForm"
      onSubmit={e => handleOnSubmit(e)}
      data-private
      data-dd-privacy="mask"
    >
      {/* Select Insurance Provider */}
      {insuranceList && (
        <div className="mt3 mb3">
          <Select
            options={insuranceList}
            className={`w-100 cy-selectInsurance ${
              errorFields.onederfulPayerId ? 'ba pa1 b--dark-red bw1 br2' : ''
            }`}
            classNamePrefix="react-select"
            placeholder="Choose your insurance"
            value={insuranceList.find(
              (item: any) => item.value === onederfulPayerId
            )}
            name="onederfulPayerId"
            onChange={handleInsuranceChange}
          />
        </div>
      )}

      {/* Is Primary Policy Holder? */}
      <h4 className="w-100 normal mv2">
        Is the patient the primary policy holder?
      </h4>
      <div className="mb2">
        <RadioOption>
          <Radio
            className="radio"
            id="yes"
            name="policy-holder"
            type="radio"
            value="yes"
            checked={isPrimary}
            onChange={() => setIsPrimary(!isPrimary)}
          />
          <RadioLabel htmlFor="yes">Yes</RadioLabel>
        </RadioOption>
        <RadioOption>
          <Radio
            className="radio"
            id="no"
            name="policy-holder"
            type="radio"
            value="no"
            checked={!isPrimary}
            onChange={() => setIsPrimary(!isPrimary)}
          />
          <RadioLabel htmlFor="no" className="ml2">
            No
          </RadioLabel>
        </RadioOption>
      </div>

      {/* ------ Patient Info ------ */}
      {!isPrimary && (
        <>
          <h4 className="w-100 normal mv2">Full Name:</h4>
          <div className="w-100 mb4 flex">
            <input
              type="text"
              className={`${inputClasses} mr2`}
              placeholder="First Name"
              value={dependentFirstName}
              name="dependentFirstName"
              onChange={onDependentFormChange}
              required
            />
            <input
              type="text"
              className={inputClasses}
              placeholder="Last Name"
              value={dependentLastName}
              name="dependentLastName"
              onChange={onDependentFormChange}
              required
            />
          </div>
          <h4 className="w-100 normal ma0 mb2">Date of Birth:</h4>
          <BirthdayFields isPrimary={false} onChange={onDependentFormChange} />
          <div className="w-100 mb4 flex">
            <input
              type="text"
              className={`${inputClasses} mr3`}
              placeholder="Member Id #"
              value={dependentMemberId}
              name="dependentMemberId"
              onChange={onDependentFormChange}
              required
            />
          </div>
          <h4 className="w-100 b mb2">Primary Policy Holder:</h4>
        </>
      )}

      {/* ------ Primary Policy Holder's Info ------ */}
      <div data-cy="insurancePrimary">
        <h4 className="w-100 normal mv2">Full Name:</h4>
        <div className="w-100 mb4 flex">
          <input
            type="text"
            className={`${inputClasses} mr2`}
            placeholder="First Name"
            value={primaryFirstName}
            name="primaryFirstName"
            onChange={onPrimaryFormChange}
            required
          />
          <input
            type="text"
            className={inputClasses}
            placeholder="Last Name"
            value={primaryLastName}
            name="primaryLastName"
            onChange={onPrimaryFormChange}
            required
          />
        </div>
        <h4 className="w-100 normal ma0 mb2">Date of Birth:</h4>
        <BirthdayFields
          isPrimary={true}
          primaryBirthMonth={primaryBirthMonth}
          primaryBirthDay={primaryBirthDay}
          primaryBirthYear={primaryBirthYear}
          onChange={onPrimaryFormChange}
        />
        <div className="w-100 mb4 flex">
          <input
            type="text"
            className={`${inputClasses} mr2`}
            placeholder="Member Id #"
            value={primaryMemberId}
            name="primaryMemberId"
            onChange={onPrimaryFormChange}
            required
          />
          <input
            type="text"
            className={inputClasses}
            placeholder="Group #"
            value={groupNumber}
            name="groupNumber"
            onChange={onPrimaryFormChange}
            required
          />
        </div>
      </div>
      {errorMessage && (
        <div className={errorMessageClasses}>
          <p data-cy="insuranceFormError" className="lh-copy b dark-red f5">
            {errorMessage}
          </p>
        </div>
      )}
      <button
        className={`dim pa3 br3 bw0 w-100 b ${
          ui.pending ? 'bg-black-30 black-50' : 'pointer bg-dark-green white'
        }`}
        type="submit"
        disabled={ui.pending}
      >
        {ui.pending ? 'Submitting ...' : 'Submit Insurance'}
      </button>
      {insuranceForm && (
        <button
          type="button"
          className="w-100 mt3 pa3 bw0 pointer"
          onClick={cancelEdit}
        >
          Cancel
        </button>
      )}
    </Form>
  );
};

export default Insurance;
