import axios from 'axios';
import { path } from 'ramda';
import { fetchApi } from 'core/conceptions/api';

export const login = async (email, password) => {
  try {
    const res = await fetchApi('/api/v1/jwt-auth/create/', 'POST', {
      email,
      password,
      brand_id: 1,
    });

    return res;
  } catch (err) {
    throw new Error(
      path(['response', 'data', 'non_field_errors'], err) || "Can't login"
    );
  }
};

export const getUserData = async () =>
  await fetchApi('/api/v1.1/users/current/', 'GET');

export const getNewToken = async refreshToken =>
  axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API}/api/v1/jwt-auth/refresh/`,
    data: {
      refresh: refreshToken,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });
