import { produce } from 'immer';
import { LOAD_STATES } from 'core/constants';
import { CustomerState, Address, CustomerStateObj } from 'store/customer/types';
import { dataIsEmpty } from 'core/form-utils';
import { CustomerActions, CustomerAction } from './actions';

const initialState: CustomerState = {
  data: {
    first_name: '',
    last_name: '',
    birthday: '',
    email: '',
    external_id: '',
    id: 0,
    inciting_order_item_paid: false,
    date_of_birth: '',
    phone: '',
    shipping_address: {
      first_name: '',
      last_name: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state_code: '',
      zip: '',
      phone: '',
      country_code: '',
    },
    // not used
    billing_address: {
      first_name: '',
      last_name: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state_code: '',
      zip: '',
      phone: '',
      country_code: '',
    },
  },
  loadState: LOAD_STATES.UNDEFINED,
  errorMessage: '',
  updateShippingLoadState: LOAD_STATES.UNDEFINED,
  updateShippingErrorMessage: '',
};

const checkMissingCountryCode = (address: Address) => {
  return {
    ...address,
    country_code: address.country_code || 'US',
  };
};

export default (state = initialState, action: CustomerActions) =>
  produce(state, draft => {
    switch (action.type) {
      case CustomerAction.CUSTOMER_REQUEST:
        draft.loadState = LOAD_STATES.PENDING;
        draft.errorMessage = '';
        break;
      case CustomerAction.CUSTOMER_FAILURE:
        draft.errorMessage = action.payload.errorMessage;
        draft.loadState = LOAD_STATES.FAILURE;
        break;
      case CustomerAction.CUSTOMER_SUCCESS:
        const customerPayload = action.payload.customer;
        const shippingAddress = checkMissingCountryCode(
          customerPayload.shipping_address
        );

        draft.loadState = LOAD_STATES.SUCCESS;
        draft.data = {
          ...customerPayload,
          shipping_address: shippingAddress,
        };
        break;

      case CustomerAction.UPDATE_SHIPPING_ADDRESS_REQUEST:
        draft.updateShippingLoadState = LOAD_STATES.PENDING;
        draft.updateShippingErrorMessage = '';
        break;
      case CustomerAction.UPDATE_SHIPPING_ADDRESS_FAILURE:
        draft.updateShippingErrorMessage = action.payload.errorMessage;
        draft.updateShippingLoadState = LOAD_STATES.FAILURE;
        break;
      case CustomerAction.UPDATE_SHIPPING_ADDRESS_SUCCESS:
        draft.updateShippingLoadState = LOAD_STATES.SUCCESS;
        const {
          firstName: first_name,
          lastName: last_name,
          addressLine1: address_line_1,
          addressLine2: address_line_2,
          city,
          state: state_code,
          zip,
          phone,
          country: country_code,
        } = action.payload.address;
        draft.data.shipping_address = {
          first_name,
          last_name,
          address_line_1,
          address_line_2: address_line_2 || '',
          city,
          state_code,
          zip,
          phone,
          country_code,
        };
        break;
      default:
        break;
    }
  });

/* SELECTORS */
export const getCustomerData = (state: CustomerStateObj) => state.customer.data;

export const getShippingAddress = (state: any) =>
  state.customer.data.shipping_address;

export const getCustomerLoadState = (state: any) => state.customer.loadState;

export const getCustomerErrorMessage = (state: any) =>
  state.customer.errorMessage;

export const getCustomerEmail = (state: any) => state.customer.data.email;

export const getCustomerBrand = (state: any) => state.customer.data.brand;

export const getUpdateShippingLoadstate = (state: any) =>
  state.customer.updateShippingLoadState;

export const hasUpdatedShipping = (state: any) =>
  state.customer.updateShippingLoadState === LOAD_STATES.SUCCESS;

export const isCustomerShippingAddressEmpty = (state: any): boolean => {
  const loadState = getCustomerLoadState(state);
  if (loadState !== LOAD_STATES.SUCCESS) {
    return true;
  }
  const shippingAddress = getShippingAddress(state);
  const { address_line_1, city, state_code, zip } = shippingAddress;

  return dataIsEmpty({
    address_line_1,
    city,
    state_code,
    zip,
  });
};
