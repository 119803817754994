import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCaseLoadState, getCases } from 'store/cases';
import { LOAD_STATES } from 'core/constants';
import { Overlay } from 'components/common';
import { Button } from 'components/generic/kit';
import { ErrorText } from 'components/common/ErrorText';

export const CaseSelection = () => {
  return (
    <Overlay>
      <div className="mw7 w-100 ml-auto mr-auto mv4 ph3">
        <h3 className="fw5">Case Selection</h3>
        <CaseBody />
      </div>
    </Overlay>
  );
};

export const CaseBody = () => {
  const [caseId, setCaseId] = useState<string | undefined>(undefined);
  const location = useLocation();
  const history = useHistory();

  const loadState = useSelector(getCaseLoadState);
  const cases = useSelector(getCases);

  if (
    loadState === LOAD_STATES.PENDING ||
    loadState === LOAD_STATES.UNDEFINED
  ) {
    return <div>Loading...</div>;
  }

  if (cases.length === 0) {
    return ErrorText('There are no case for this customer.');
  }

  const handleCaseSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const caseId = event.currentTarget.value;
    setCaseId(caseId);
  };

  const handleCaseClick = (cid?: string) => {
    if (cid) {
      history.push(`${location.pathname}?caseId=${cid}`);
    }
  };

  return (
    <>
      <div>
        <select
          style={{ boxSizing: 'border-box', height: '34px' }}
          className="ba pl1 pr1 br2 db f5 fw4 outline-0 b--black-20 bg-white pointer mr2 w-100"
          data-cy="case-selection"
          onChange={handleCaseSelect}
          key={caseId}
          defaultValue={caseId}
        >
          <option key="default" value="">
            Please select a case
          </option>
          <>
            {cases.map((c, i) => (
              <option key={c.id} value={c.id}>
                {c.case_type?.label} Case - Case ID: {c.id} - Case Status: {c.is_active ? 'Active' : 'Inactive'}
              </option>
            ))}
          </>
        </select>
      </div>
      <div
        className="mv2 b--black-10 flex flex-column items-end"
        data-cy="case-select"
      >
        <Button
          disabled={!caseId}
          fullWidth={false}
          onClick={() => handleCaseClick(caseId)}
        >
          Go to Customer
        </Button>
      </div>
    </>
  );
};
