import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '..';

const defaultClass = 'ba br2 ph2 pv3 db f5 fw6 outline-0';

export const Select = ({
  required,
  input,
  label,
  placeholder,
  type,
  meta: { touched, error },
  className = defaultClass,
  height,
  disabled = false,
  children,
}: {
  required: boolean;
  input: any;
  label?: string;
  placeholder: string;
  type: string;
  meta: Partial<{
    touched: boolean;
    error: any;
  }>;
  className?: string;
  height: string;
  disabled?: boolean;
  children?: any;
}) => (
  <Label title={label} error={touched && error}>
    <select
      disabled={disabled}
      style={{ boxSizing: 'border-box', height }}
      className={`${className} w-100 ${
        touched && error ? 'b--red' : 'b--black-20'
      }`}
      {...input}
      required={required}
      placeholder={placeholder}
      type={type}
    >
      {children}
    </select>
  </Label>
);

Select.propTypes = {
  required: PropTypes.bool,
  input: PropTypes.shape({}),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  className: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.any,
};
