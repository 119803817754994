import { produce } from 'immer';
import Dinero from 'dinero.js';
import {
  LOAD_STATES,
  PRODUCT_TYPES,
  ORDER_FINANCIAL_STATES,
  PAYMENT_GATEWAYS,
  ORDER_STATES,
} from 'core/constants';
import {
  PaymentState,
  Transaction,
  PaymentGateway,
  FinancingGateway,
} from 'store/payment/types';
import { PaymentActions, PaymentAction } from './actions';
import { CouponState } from 'store/coupon/types';
import {
  ProductState,
  ProductSkus,
} from 'store/products/types';
import { getCouponAmount } from 'store/coupon';
import { Insurance } from 'store/insurance/types';
import { CartState } from 'store/cart/types';

const initialState: PaymentState = {
  data: {
    orders: [],
    affirm: undefined,
    allegro: undefined,
    paymentHistory: {
      net_payments: 0,
      net_refunds: 0,
      net_total: 0,
      transactions: [],
      initiated_financing_applications: [],
    },
    experimentEnabled: undefined,
  },
  financingOptions: [],
  financingOptionsLoadState: LOAD_STATES.UNDEFINED,
  experimentLoadState: LOAD_STATES.UNDEFINED,
  ordersLoadState: LOAD_STATES.UNDEFINED,
  ordersErrorMessage: '',
  checkoutErrorMessage: '',
  checkoutLoadState: LOAD_STATES.UNDEFINED,
  affirmCheckoutErrorMessage: '',
  affirmCheckoutLoadState: LOAD_STATES.UNDEFINED,
  allegroCheckoutErrorMessage: '',
  allegroCheckoutLoadState: LOAD_STATES.UNDEFINED,
  finalizeFinancingLoadState: LOAD_STATES.UNDEFINED,
  paymentHistoryLoadState: LOAD_STATES.UNDEFINED,
  paymentHistoryErrorMessage: '',
  selectedOrderId: undefined, // NOTE: this is only used for mult-item order feature
  draftGenricCheckout: undefined,
};

export default (state = initialState, action: PaymentActions) =>
  produce(state, draft => {
    switch (action.type) {
      case PaymentAction.FETCH_ORDERS_REQUEST:
        draft.ordersLoadState = LOAD_STATES.PENDING;
        draft.ordersErrorMessage = '';
        break;
      case PaymentAction.FETCH_ORDERS_FAILURE:
        draft.ordersErrorMessage = action.payload.errorMessage;
        draft.ordersLoadState = LOAD_STATES.FAILURE;
        break;
      case PaymentAction.FETCH_ORDERS_SUCCESS:
        draft.ordersLoadState = LOAD_STATES.SUCCESS;
        draft.data.orders = action.payload.orders;
        draft.ordersErrorMessage = '';
        break;
      case PaymentAction.CHECKOUT_REQUEST:
        draft.checkoutLoadState = LOAD_STATES.PENDING;
        draft.checkoutErrorMessage = '';
        break;
      case PaymentAction.CHECKOUT_FAILURE:
        draft.checkoutErrorMessage = action.payload.errorMessage;
        draft.checkoutLoadState = LOAD_STATES.FAILURE;
        break;
      case PaymentAction.GENERIC_CHECKOUT_FAILURE:
        draft.checkoutErrorMessage = action.payload.errorMessage;
        draft.checkoutLoadState = LOAD_STATES.FAILURE;
        break;
      case PaymentAction.CHECKOUT_SUCCESS:
        draft.checkoutLoadState = LOAD_STATES.SUCCESS;
        break;
      case PaymentAction.FETCH_EXPERIMENT_REQUEST:
        draft.experimentLoadState = LOAD_STATES.PENDING;
        break;
      case PaymentAction.FETCH_EXPERIMENT_SUCCESS:
        const { featureKey, isFeatureEnabled } = action.payload;
        draft.experimentLoadState = LOAD_STATES.SUCCESS;
        draft.data.experimentEnabled = {
          [featureKey]: isFeatureEnabled,
        };
        break;
      case PaymentAction.FETCH_FINANCING_OPTIONS_REQUEST:
        draft.financingOptionsLoadState = LOAD_STATES.PENDING;
        break;
      case PaymentAction.FETCH_FINANCING_OPTIONS_SUCCESS:
        draft.financingOptions = action.payload;
        draft.financingOptionsLoadState = LOAD_STATES.SUCCESS;
        break;
      case PaymentAction.FETCH_FINANCING_OPTIONS_FAILURE:
        draft.financingOptionsLoadState = LOAD_STATES.FAILURE;
        break;
      case PaymentAction.AFFIRM_CHECKOUT_REQUEST:
        draft.affirmCheckoutLoadState = LOAD_STATES.PENDING;
        draft.affirmCheckoutErrorMessage = '';
        break;
      case PaymentAction.AFFIRM_CHECKOUT_FAILURE:
        draft.affirmCheckoutErrorMessage = action.payload.errorMessage;
        draft.affirmCheckoutLoadState = LOAD_STATES.FAILURE;
        break;
      case PaymentAction.AFFIRM_CHECKOUT_SUCCESS:
        draft.affirmCheckoutLoadState = LOAD_STATES.SUCCESS;
        draft.data.affirm = action.payload.affirm;
        break;

      case PaymentAction.ALLEGRO_CHECKOUT_REQUEST:
        draft.allegroCheckoutLoadState = LOAD_STATES.PENDING;
        draft.allegroCheckoutErrorMessage = '';
        break;
      case PaymentAction.ALLEGRO_CHECKOUT_FAILURE:
        draft.allegroCheckoutErrorMessage = action.payload.errorMessage;
        draft.allegroCheckoutLoadState = LOAD_STATES.FAILURE;
        break;
      case PaymentAction.ALLEGRO_CHECKOUT_SUCCESS:
        draft.allegroCheckoutLoadState = LOAD_STATES.SUCCESS;
        draft.data.allegro = action.payload.allegro;
        break;
      case PaymentAction.FINALIZE_FINANCING_REQUEST:
        draft.finalizeFinancingLoadState = LOAD_STATES.PENDING;
        break;
      case PaymentAction.FINALIZE_FINANCING_SUCCESS:
        draft.finalizeFinancingLoadState = LOAD_STATES.SUCCESS;
        break;
      case PaymentAction.FINALIZE_FINANCING_FAILURE:
        draft.finalizeFinancingLoadState = LOAD_STATES.FAILURE;
        break;
      case PaymentAction.RESET_ALLEGRO_CHECKOUT:
        draft.allegroCheckoutLoadState = initialState.allegroCheckoutLoadState;
        draft.data.allegro = initialState.data.allegro;
        draft.experimentLoadState = initialState.experimentLoadState;
        draft.data.experimentEnabled = initialState.data.experimentEnabled;
        draft.financingOptions = initialState.financingOptions;
        break;
      case PaymentAction.FETCH_PAYMENT_HISTORY_REQUEST:
        draft.paymentHistoryLoadState = LOAD_STATES.PENDING;
        draft.paymentHistoryErrorMessage = '';
        break;
      case PaymentAction.FETCH_PAYMENT_HISTORY_FAILURE:
        draft.paymentHistoryErrorMessage = action.payload.errorMessage;
        draft.paymentHistoryLoadState = LOAD_STATES.FAILURE;
        break;
      case PaymentAction.FETCH_PAYMENT_HISTORY_SUCCESS:
        draft.paymentHistoryLoadState = LOAD_STATES.SUCCESS;
        draft.data.paymentHistory = action.payload.paymentHistory;
        break;
      case PaymentAction.RESET_PAYMENT_HISTORY:
        draft.data.paymentHistory = initialState.data.paymentHistory;
        draft.paymentHistoryLoadState = initialState.paymentHistoryLoadState;
        break;
      case PaymentAction.SELECT_ORDER:
        draft.selectedOrderId = action.payload.orderId;
        break;
      case PaymentAction.CLEAR_ORDER:
        draft.selectedOrderId = undefined;
        break;
      case PaymentAction.DRAFT_GENERIC_CHECKOUT:
        draft.draftGenricCheckout = action.payload.data;
        break;
      case PaymentAction.CLEAR_DRAFT_GENERIC_CHECKOUT:
        draft.draftGenricCheckout = undefined;
        break;
      default:
        break;
    }
  });

type PaymentArgument = {
  payment: PaymentState;
};

/* SELECTORS */
export const getOrders = ({ payment }: PaymentArgument) => payment.data.orders;
export const getDraftGenericCheckout = ({ payment }: PaymentArgument) => payment.draftGenricCheckout;
export const getOrderLoadState = ({ payment }: PaymentArgument) =>
  payment.ordersLoadState;
export const getOrderErrorMessage = ({ payment }: PaymentArgument) =>
  payment.ordersErrorMessage;
export const getActiveAKOrders = ({ payment }: PaymentArgument) => {
  const { orders } = payment.data;
  // Find a non-refunded AK order (if one exists)
  const akOrders = orders.filter(
    ({
      product_types_in_order: productTypes = [],
      state: orderState,
      financial_state: financialState,
    }) =>
      productTypes.includes(PRODUCT_TYPES.ALIGNERS_KIT) &&
      (orderState === ORDER_STATES.PAID || orderState === ORDER_STATES.OPEN)
  );

  return akOrders;
};

export const wasFetchOrdersSuccessful = ({ payment }: PaymentArgument) =>
  payment.ordersLoadState === LOAD_STATES.SUCCESS;

export const getActiveAKOrder = ({
  payment,
  products,
}: {
  payment: PaymentState;
  products: ProductState;
}) => {
  if (payment.selectedOrderId) {
    return getSelectedOrder({ payment });
  } else {
    return undefined;
  }
};

export const getSelectedOrder = ({ payment }: { payment: PaymentState }) =>
  payment.data.orders.find(order => order.id === payment.selectedOrderId);

export const getInitiatedFinancingApplications = ({
  payment,
}: PaymentArgument) => {
  return payment.data.paymentHistory?.initiated_financing_applications || [];
};

export const getAllegroInitiatedApplications = ({
  payment,
}: PaymentArgument) => {
  return getInitiatedFinancingApplications({ payment })?.filter(
    ({ gateway }) => gateway === FinancingGateway.allegro
  );
};

export const hasAllegroInitiatedApplications = ({ payment }: PaymentArgument) =>
  Boolean(getAllegroInitiatedApplications({ payment })?.length);

export const isActiveOrderComplete = ({
  payment,
  products,
}: {
  payment: PaymentState;
  products: ProductState;
}) => {
  const AKOrder = getActiveAKOrder({ payment, products });
  return (
    AKOrder && AKOrder.financial_state === ORDER_FINANCIAL_STATES.FINANCIAL_PAID
  );
};

export const getFirstAKOrderId = ({
  payment,
}: PaymentArgument): string | null => {
  const AKOrders = getActiveAKOrders({ payment });
  if (AKOrders.length > 1) {
    console.error(
      'There are more than 1 AK order defaulting to ',
      AKOrders[0].skus_in_order[0]
    );
  }
  if (AKOrders.length === 0) {
    return null;
  }
  return AKOrders[0].id;
};

export const getFirstAKOrderSku = ({ payment }: PaymentArgument) => {
  const AKOrders = getActiveAKOrders({ payment });

  return AKOrders[0]?.skus_in_order.filter(
    sku => sku !== ProductSkus.AlignerGood
  )[0];
};

export const getOrderExistsAndPayment = ({
  payment,
  products,
}: {
  payment: PaymentState;
  products: ProductState;
}) =>
  doesOrderExist({ payment }) &&
  getNetAmountPaid({ payment, products }).getAmount() > 0;

export const doesOrderExist = ({ payment }: { payment: PaymentState }) =>
  getActiveAKOrders({ payment }).length > 0;

export const getNetAmountPaid = ({
  payment,
  products,
}: {
  payment: PaymentState;
  products: ProductState;
}): Dinero.Dinero => {
  const akOrder = getActiveAKOrder({ payment, products });
  if (typeof akOrder === 'undefined') {
    return Dinero({ amount: 0 });
  } else {
    return Dinero({
      amount: Math.round(Number(akOrder.net_amount_paid * 100)),
    });
  }
};

export const getAllegroTransaction = (state: any) => {
  return getPaymentHistory(state).filter(
    transaction => transaction.payment_gateway === PaymentGateway.allegro
  );
};

export const hasApprovedAllegroFinancing = ({ payment }: PaymentArgument) => {
  const approvedPaymentStatus = getAllegroTransaction({ payment }).filter(
    transaction => transaction?.payment_metadata?.status === 'approved & signed'
  ).length;
  return Boolean(approvedPaymentStatus);
};

export const getFinalizeFinancingLoadState = ({ payment }: PaymentArgument) =>
  payment.finalizeFinancingLoadState;

export const getExperimentLoadState = ({ payment }: PaymentArgument) =>
  payment.experimentLoadState;

export const getAffirmLoadState = ({ payment }: PaymentArgument) =>
  payment.affirmCheckoutLoadState;
export const getAffirmErrorMessage = ({ payment }: PaymentArgument) =>
  payment.affirmCheckoutErrorMessage;
export const getAllegroLoadState = ({ payment }: PaymentArgument) =>
  payment.allegroCheckoutLoadState;
export const getAllegroErrorMessage = ({ payment }: PaymentArgument) =>
  payment.allegroCheckoutErrorMessage;
export const getCheckoutLoadState = ({ payment }: PaymentArgument) =>
  payment.checkoutLoadState;
export const getCheckoutErrorMessage = ({ payment }: PaymentArgument) =>
  payment.checkoutErrorMessage;
export const getPaymentHistory = ({ payment }: PaymentArgument) =>
  payment.data.paymentHistory.transactions;
export const getPaymentHistoryLoadState = ({ payment }: PaymentArgument) =>
  payment.paymentHistoryLoadState;
export const getPaymentHistoryErrorMessage = ({ payment }: PaymentArgument) =>
  payment.paymentHistoryErrorMessage;
export const getValidTransactions = ({ payment }: PaymentArgument) =>
  payment.data.paymentHistory.transactions.filter(
    ({ remaining_refundable_amount: finalAmount }) => finalAmount > 0
  );
export const getRefunds = ({ payment }: PaymentArgument) =>
  Dinero({ amount: payment.data.paymentHistory.net_refunds });

export const getAffirmResponse = ({ payment }: { payment: PaymentState }) =>
  payment.data.affirm;

export const getAllegroResponse = ({ payment }: { payment: PaymentState }) =>
  payment.data.allegro;

export const getAffirmPaymentAmount = ({
  payment,
}: {
  payment: PaymentState;
}) => {
  return Dinero({
    amount: getValidTransactions({ payment }).reduce(
      (agg: number, curr: Transaction) => {
        if (curr.payment_gateway === PAYMENT_GATEWAYS.AFFIRM) {
          return agg + curr.remaining_refundable_amount;
        }
        return agg;
      },
      0
    ),
  });
};

export const getAllegroPaymentAmount = ({
  payment,
}: {
  payment: PaymentState;
}) => {
  return Dinero({
    amount: getValidTransactions({ payment }).reduce(
      (agg: number, curr: Transaction) => {
        if (curr.payment_gateway === PAYMENT_GATEWAYS.ALLEGRO) {
          return agg + curr.remaining_refundable_amount;
        }
        return agg;
      },
      0
    ),
  });
};

export const getAffirmRedirectUrl = ({ payment }: PaymentArgument) =>
  payment.data.affirm && payment.data.affirm.redirect_url;

export const getAvailableFinancingOptions = ({
  payment,
}: {
  payment: PaymentState;
}) => payment.financingOptions;

export const getFinancingOptionsLoadState = ({
  payment,
}: {
  payment: PaymentState;
}) => payment.financingOptionsLoadState;

export const getEnabledExperiment = (
  {
    payment,
  }: {
    payment: PaymentState;
  },
  featureKey: string
) => payment.data.experimentEnabled?.hasOwnProperty(featureKey);

export const getTotalAdjustedPrice = ({
  payment,
  coupon,
  products,
  cart,
}: {
  payment: PaymentState;
  coupon: CouponState;
  products: ProductState;
  cart: CartState;
}) => {
  const selectedOrderId = payment.selectedOrderId;
  // if an order is selected, then just return that existing order's total
  const foundOrder =
    selectedOrderId &&
    payment.data.orders.find(order => order.id === selectedOrderId);

  if (foundOrder) {
    const couponAmount = getCouponAmount({
      coupon,
      payment,
      products,
      cart,
    });

    const total = foundOrder.orderitems.reduce((acc, curr) => {
      return acc.add(Dinero({ amount: curr.total * 100 }))
    }, Dinero({ amount: 0 }))
    return total.subtract(couponAmount);
  } else {
    return cart.cartTotal
  }
};

export const getBalanceRemaining = ({
  payment,
  coupon,
  products,
  cart,
}: {
  payment: PaymentState;
  coupon: CouponState;
  products: ProductState;
  insurance: Insurance;
  cart: CartState;
}) => {
  const totalAdjustedPrice = getTotalAdjustedPrice({
    payment,
    coupon,
    products,
    cart,
  });
  const netAmountPaid = getNetAmountPaid({ payment, products });
  return totalAdjustedPrice.subtract(netAmountPaid);
};

export const wasOrderPaid = ({
  payment,
  coupon,
  products,
  cart,
}: {
  payment: PaymentState;
  coupon: CouponState;
  products: ProductState;
  insurance: Insurance;
  cart: CartState;
}): boolean =>
  getTotalAdjustedPrice({ payment, coupon, products, cart }) ===
  getNetAmountPaid({ payment, products });

export const wasCheckoutSuccessful = ({ payment }: { payment: PaymentState }) =>
  getCheckoutLoadState({ payment }) === LOAD_STATES.SUCCESS;

export const getCurrentOrderProductSku = ({
  payment,
  products,
}: {
  payment: PaymentState;
  products: ProductState;
}) => {
  const AKOrder = getActiveAKOrder({ payment, products });
  if (typeof AKOrder === 'undefined') {
    return null;
  }

  return AKOrder.skus_in_order.filter(
    sku => sku !== ProductSkus.AlignerGood
  )[0] as ProductSkus;
};
