import styled from 'styled-components/macro';
import ReactSelect from 'react-select';
import { CSSProperties } from 'react';
import { colors, type } from '@candidco/enamel';

export const Label = styled.label`
  margin-bottom: 0.5rem;
  font-size: ${type.size.small};
  line-height: 1;
`;

export const Input = styled.input<{ hasError?: boolean }>`
  width: 100%;
  padding: 0.625rem 0.75rem;
  background: ${colors.white};
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.red50 : colors.black20)};
  border-radius: 0.125rem;
  transition: border-color 0.15s ease-in-out;

  &[type='number'] {
    -moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }
  }
`;

export const Select = styled.select<{ hasError?: boolean }>`
  width: 100%;
  height: 2.5rem;
  background: ${colors.white};
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.red50 : colors.black20)};
  border-radius: 0.125rem;
  transition: border-color 0.15s ease-in-out;
`;

export const StyledReactSelect = styled(ReactSelect)<{ hasError?: boolean }>`
  width: 100%;
  height: 2.5rem;
  background: white;
`;

export const CustomerReactSelectStyles = {
  control: (provided: CSSProperties, state: any) => {
    return {
      ...provided,
      borderRadius: '0.125rem',
      borderColor: state.selectProps.hasError ? colors.red50 : colors.black20,
    };
  },
};

export const CheckboxLabel = styled.label`
  display: inline-flex;
  cursor: pointer;

  ${Input} {
    width: auto;
    margin-top: 0.1875rem;
    margin-right: 0.5rem;
    vertical-align: text-top;
    border: 0;
    border-radius: 0;
    appearance: checkbox;
  }
`;

export const TextArea = styled.textarea<{ hasError?: boolean }>`
  display: block;
  width: 100%;
  padding: 0.5rem;
  line-height: inherit;
  background: ${colors.white};
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.red50 : colors.black20)};
  border-radius: 0.125rem;
  transition: border-color 0.15s ease-in-out;
`;

export const ErrorText = styled.div`
  margin-top: 0.5rem;
  font-size: ${type.size.small};
  color: ${colors.red50};
`;
