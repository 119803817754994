import React from 'react';
import { Form, Field } from 'react-final-form';
import { Overlay } from 'components/common';
import { Input } from 'components/generic/form';
import { Button } from 'components/generic/kit';
import { segment } from 'core/integrations';

// eslint-disable-next-line react/display-name
export default ({ search, onSubmit, onCustomerClick }) => {
  if (search.data) {
    const searchResults = search.data.map(result => ({
      customerId: result.id,
      name: [result.first_name, result.last_name].join(' '),
    }));
    segment.track('Search Results', { searchResults });
  }

  return (
    <Overlay>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className="mt4 flex flex-column items-center"
          >
            <div
              data-cy="search-customer-input"
              className="flex items-center mw6 w-100 ml-auto mr-auto"
              data-private
              data-dd-privacy="mask"
            >
              <Field
                name="customer"
                label="Search for a customer"
                placeholder="Customer name or email"
                type="text"
                required
                component={Input}
              />
            </div>
            <div className="mt3" data-cy="search-customer-button">
              <Button>Search</Button>
            </div>
          </form>
        )}
      </Form>
      {search.isFetching ? (
        <div className="tc mt3">Searching...</div>
      ) : (
        <div className="mw6 ml-auto mr-auto w-100 mt4 mb3">
          {search.data &&
            search.data.map((item, i) => (
              <div
                data-cy={`search-results-${i}`}
                onClick={() => onCustomerClick(item.id)}
                className="pv3 bb b--black-10 pointer underline-hover"
                key={i}
              >
                {item.id}: &nbsp;
                <span data-private data-dd-privacy="mask">
                  {item.first_name || '[no-first-name]'}{' '}
                </span>
                {item.last_name || '[no-last-name]'}{' '}
                <span className="f6" data-private data-dd-privacy="mask">
                  ({item.brand?.label})
                </span>{' '}
                <span className="f6" data-private data-dd-privacy="mask">
                  ({item.email})
                </span>
              </div>
            ))}
        </div>
      )}
    </Overlay>
  );
};
