import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';
import * as storage from '../storage';

export default Component =>
  compose(
    withRouter,
    lifecycle({
      componentDidMount() {
        if (storage.isAuthenticated()) {
          this.props.history.replace('/');
        }
      },
    })
  )(
    ({ isAuthenticated, ...props }) =>
      !storage.isAuthenticated() && <Component {...props} />
  );
