import Raven from 'raven-js';

export const initSentry = () => {
  if (process.env.NODE_ENV === 'production') {
    Raven.config(process.env.REACT_APP_SENTRY).install();
  }
};

export const captureException = e => {
  Raven.captureException(e);
};
