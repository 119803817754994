import React from 'react';
import styled from 'styled-components';
import { LOAD_STATES } from 'core/constants';
import { Button } from 'components/generic/kit';
import { useDispatch, useSelector } from 'react-redux';
import { preGenericCheckout, genericCheckout } from 'store/payment/actions';
import { withRouter, match as MatchInterface } from 'react-router';
import { getCustomerData } from 'store/customer';
import {
  getCheckoutErrorMessage,
  getCheckoutLoadState,
  getBalanceRemaining,
  getActiveAKOrder,
} from 'store/payment';
import { getCouponCode } from 'store/coupon';
import { getCatalogMap } from 'store/products';
import { getSelectedCase } from 'store/cases';
import { getCart, getIsNewOrder } from 'store/cart';
import FreePurchaseForm from 'components/pages/AlignersKit/Checkout/components/FreePurchaseForm';
import { compileOrderItems } from '../utils/compile-order-items';
import { objectToSnakeCase } from 'core/util';
import { useFlags } from 'launchdarkly-react-client-sdk';


const ChargeButton = styled(Button)`
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
`;

const PaymentForm = ({ match }: { match: MatchInterface<{ id: string }> }) => {
  const dispatch = useDispatch();

  const balanceRemaining = useSelector(getBalanceRemaining);
  const isNewOrder = useSelector(getIsNewOrder);

  const catalog = useSelector(getCatalogMap);
  const checkoutLoadState = useSelector(getCheckoutLoadState);
  const checkoutError = useSelector(getCheckoutErrorMessage);
  const couponCode = useSelector(getCouponCode);
  const isPaying = checkoutLoadState === LOAD_STATES.PENDING;
  const caseInfo = useSelector(getSelectedCase);
  const {
    email,
    first_name,
    last_name,
    shipping_address,
    billing_address,
    id,
    referring_dentist,
    brand,
  } = useSelector(getCustomerData);
  const activeAKOrder = useSelector(getActiveAKOrder);
  const cartItems = useSelector(getCart);
  const { 'non-ak-shipping-address-improvements': nonAkshippingAddressImprovements } = useFlags();
  const checkoutAction = nonAkshippingAddressImprovements ? preGenericCheckout : genericCheckout;


  const createAndDispatchForm = async () => {
    /**
     * This compiles the order items into either a singular order item within a list (legacy)
     * or creates a list of all order items to be used in a checkout
     */

    const items = compileOrderItems({ cartItems, activeAKOrder, catalog });
    const metadata = cartItems
      .map((item) => ({ [item.sku]: item.metadata }))
      .reduce((c, v) => {
        const key = Object.keys(v)[0];
        c[key] = v[key];
        return c;
      }, {});

    dispatch(
      checkoutAction({
        orderId: activeAKOrder?.id,
        email,
        first_name,
        last_name,
        shipping_address,
        billing_address,
        phone: shipping_address.phone,
        items,
        couponCode,
        caseRef: caseInfo?.case_ref!,        
        caseType: caseInfo?.case_type?.name || '',
        metadata: objectToSnakeCase(metadata),
        userId: id,
        referring_dentist: referring_dentist?.id,
        brand: brand?.name,
      })
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createAndDispatchForm();
  };

  const isBalanceRemaining = balanceRemaining.getAmount() > 0;
  return (
    <>
      <div className={(isBalanceRemaining && 'dn') || ''}>
        <FreePurchaseForm />
      </div>
      <div className={(!isBalanceRemaining && 'dn') || ''}>
        <form onSubmit={handleSubmit} data-cy="ccPayment">
          <div className="flex items-end mt2 mb3 ">
            <div className="w-40">
              <ChargeButton wide={false} disabled={isPaying} fullWidth>
                {isPaying ? 'Processing Order...' : 'Create Order'}
              </ChargeButton>
            </div>
          </div>
          
          {checkoutLoadState === LOAD_STATES.SUCCESS && !isNewOrder && (
            <div
              className="bg-light-green b--dark-green near-black pa3 br2 ba f6 b--solid mb1"
              data-cy="paymentSuccessMessage"
            >
              Order successfully processed.
            </div>
          )}

          {(checkoutError) && (
            <div className="bg-light-red b--dark-red near-black pa3 br2 ba f6 b--solid mb1">
              {'Something went wrong'}
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default withRouter(PaymentForm);
