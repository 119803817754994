import { fetchApi } from 'core/conceptions/api';
import { PRODUCT_TYPES, ORDER_FINANCIAL_STATES } from 'core/constants';

export const search = async query => {
  const newQuery = encodeURIComponent(query);

  return await fetchApi(
    `/api/v1/customers/?q=${newQuery}&fields=id,first_name,last_name,email,brand`,
    'GET'
  );
};

export const applyCoupon = async coupon =>
  await fetchApi(`/api/v1/apply-coupon/`, 'POST', {
    coupon_code: coupon.toUpperCase(),
    product_type: 'aligner',
  });

export const fetchCustomer = async id =>
  await fetchApi(`/api/v1/customers/${id}/`, 'GET');

export const getReferralCoupon = async id =>
  await fetchApi(`/api/v1/referral-pending-aligner-credit?id=${id}`, 'GET');

export const fetchAKOrder = async customerId => {
  const orders = await fetchApi(
    `/api/v1/orders/?customer_id=${customerId}`,
    'GET'
  );

  // Find a non-refunded AK order (if one exists)
  const akOrder = orders.find(
    ({
      product_types_in_order: productTypes = [],
      financial_state: financialState,
    }) =>
      productTypes.includes(PRODUCT_TYPES.ALIGNERS_KIT) &&
      financialState !== ORDER_FINANCIAL_STATES.REFUNDED
  );

  return akOrder;
};

export const fetchPaymentHistory = async order => {
  if (!order) {
    return {
      metadata: {},
      transactions: [],
      net_total: 0,
    };
  }

  const result = await fetchApi(
    `/api/v1/orders/${order.id}/get_payments_list/`,
    'GET'
  );
  const transactions = result.transactions.map(transaction => {
    const { payment_metadata } = transaction;

    return {
      ...transaction,
      payment_metadata: payment_metadata || {},
    };
  });

  return {
    ...result,
    transactions,
    metadata: order.metadata,
  };
};

export const initAffirmCheckout = async (customerId, amount, couponCode) =>
  await fetchApi('/api/v1/initiate-affirm-checkout/', 'POST', {
    telesales: true,
    customer_id: customerId,
    payment_amount_in_dollars: amount,
    payment_channel: 'telesale',
    coupon_code: couponCode,
  });

/**
 * Updates a customer's shipping address
 * @param {Int} customerId
 * @param {Address} shippingAddress
 * {
 *    addressLine1: String,
 *    addressLine2: Option<String>.
 *    country: String,
 *    firstName: String,
 *    lastName: String,
 *    phone: String,
 *    state: String,
 *    zip: String
 * }
 */
export const updateCustomerShippingAddress = async (customerId, address) =>
  await fetchApi(`/api/v1/users/${customerId}/update-shipping-address`, 'PUT', {
    shippingAddress: address,
  });
