/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from 'react';
import HomeAddressPng from 'images/home-address.png';
import OtherAddressPng from 'images/other-address.png';
import { LOAD_STATES } from 'core/constants';
import BasicAddressForm from 'components/pages/AlignersKit/Checkout/components/BasicAddressForm';
import { useDispatch, useSelector } from 'react-redux';
import { CartActions } from 'store/cart/actions';

import {
  getShippingAddress,
  getCustomerLoadState,
  getCustomerData,
} from 'store/customer';

import {
  Loading,
  Grid,
  Avatar,
  type,
  colors,
} from '@candidco/enamel';
import {
  FormRow,
  Label,
  QuestionHeading,
  Card,
  StyledImage,
  Checkbox,
  TextLine,
  BoldLine,
  AddressContainer,
  LeftAddressSection,
  SideNote,
} from './ShippingAddressSelection.css';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: any;
  Date: string;
  DateTime: string;
  JSONString: any;
  Decimal: any;
  ISODateString: string;
  GenericScalar: any;
};
export type SelectedAddress = {
  phone?: Maybe<Scalars['String']>,
  first_name?: Maybe<Scalars['String']>,
  last_name?: Maybe<Scalars['String']>,
  address_line_1?: Maybe<Scalars['String']>,
  address_line_2?: Maybe<Scalars['String']>,
  business_name?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  zip?: Maybe<Scalars['String']>,
  state_code?: Maybe<Scalars['String']>,
  country_code?: Maybe<Scalars['String']>,
}

export type SelectedAddressType = 'patient' | 'practice' | 'other';

type SelectedAddressInfo = {
  addressType: SelectedAddressType;
  value: SelectedAddress;
  image: string;
  label: string;
  sendPatientUpdateDefaultOption: boolean;
};

const ShippingAddressSelection = () => {
  const dispatch = useDispatch();
  const customerInfo = useSelector(getCustomerData);
  const customerShippingAddress = useSelector(getShippingAddress);
  const customerLoadState = useSelector(getCustomerLoadState);

  const isLoadingCustomer = (
    customerLoadState === LOAD_STATES.PENDING ||
    customerLoadState === LOAD_STATES.UNDEFINED
  )

  const addressSelections: SelectedAddressInfo[] = [
    {
      addressType: 'patient',
      image: HomeAddressPng,
      sendPatientUpdateDefaultOption: true,
      value: {
        phone: customerInfo?.phone,
        first_name: customerInfo?.first_name,
        last_name: customerInfo?.last_name,
        business_name: customerShippingAddress?.business_name,
        address_line_1: customerShippingAddress?.address_line_1,
        address_line_2: customerShippingAddress?.address_line_2,
        city: customerShippingAddress?.city,
        zip: customerShippingAddress?.zip,
        state_code: customerShippingAddress?.state_code,
        country_code: customerShippingAddress?.country_code,
      },
      label: 'Patient home address',
    },
    {
      addressType: 'other',
      image: OtherAddressPng,
      sendPatientUpdateDefaultOption: false,
      value: {},
      label: 'Somewhere else',
    },
  ];

  const [selectedAddress, setSelectedAddress] = useState<
    SelectedAddressInfo | undefined
  >(addressSelections.find((a) => a.addressType === 'patient'));
  const [sendPatientUpdate, setSendPatientUpdate] = useState<boolean>(true);

  const handleAddressSelection = useCallback((addressInfo: SelectedAddressInfo)=> {
    setSelectedAddress(addressInfo);
    dispatch(CartActions.setShippingAddress(addressInfo?.value || {}));
    dispatch(CartActions.setSentPatientShippingUpdate(addressInfo?.sendPatientUpdateDefaultOption));
    dispatch(CartActions.setShippingAddressType(addressInfo?.addressType || 'other'));
    setSendPatientUpdate(addressInfo?.sendPatientUpdateDefaultOption || false);
  }, [dispatch])

  // Set default address here for the card selection
  useCallback(() => {
    if (selectedAddress?.addressType === 'patient') {
      const patientDefaultSelection = addressSelections.find((a) => a.addressType === 'patient')
      patientDefaultSelection && handleAddressSelection(
        patientDefaultSelection
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo, customerShippingAddress]);

  const renderAvatar = () => {
    return (
      <Avatar
        style={{
          width: 67,
          height: 67,
          backgroundColor: colors.blue50,
          paddingTop: '0.25rem',
          marginTop: '0.2rem',
          fontSize: '2rem',
          marginRight: '16px',
        }}
      >
        {selectedAddress?.value?.business_name ? (
          <>{`${selectedAddress?.value?.business_name[0]}`}</>
        ) : (
          <>
            {customerInfo &&
              `${customerInfo?.first_name[0]}${customerInfo?.last_name[0]}`}
          </>
        )}
      </Avatar>
    );
  };

  const addressText = () => (
    <>
      <TextLine>
        {selectedAddress?.value?.address_line_1}{' '}
        {selectedAddress?.value?.address_line_2}
      </TextLine>
      <TextLine>{`${selectedAddress?.value?.city}, ${selectedAddress?.value?.state_code} ${selectedAddress?.value?.zip}`}</TextLine>
    </>
  );

  const renderAddress = () => {
    switch (selectedAddress?.addressType) {
      case 'patient':
        return (
          <>
            {renderAvatar()}
            <AddressContainer>
              {selectedAddress?.value?.business_name ? (
                <BoldLine>{selectedAddress?.value?.business_name}</BoldLine>
              ) : (
                <BoldLine>{`${customerInfo?.first_name} ${customerInfo?.last_name}`}</BoldLine>
              )}
              {addressText()}
            </AddressContainer>
          </>
        );
      case 'practice':
        return (
          <AddressContainer>
            <BoldLine>{selectedAddress?.value?.business_name}</BoldLine>
            {addressText()}
          </AddressContainer>
        );
      default:
        return null;
    }
  };

  const renderAddressSelectionCards = () => {
    return (
      <Grid container spacing={1}>
        {addressSelections.map((address) => (
          <Grid key={address.addressType} item xs={12} md={4}>
            <Card
              style={{ cursor: 'pointer' }}
              selected={address.addressType === selectedAddress?.addressType}
              onClick={() => handleAddressSelection(address)}
            >
              <StyledImage src={address.image}></StyledImage>
              <type.H6>{address.label}</type.H6>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderAddressDetails = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {['patient', 'practice'].some(
            (address) => address === selectedAddress?.addressType
          ) ? (
            <Card style={{ padding: '24px' }}>
              <Grid
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                container
              >
                <Grid item xs={7}>
                  <Grid
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    container
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <LeftAddressSection>{renderAddress()}</LeftAddressSection>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'right' }}>
                </Grid>
              </Grid>
              {selectedAddress?.addressType === 'practice' && (
                <Grid
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={0}
                  container
                >
                  <Grid item xs={12} style={{ textAlign: 'left' }}>
                  </Grid>
                </Grid>
              )}
            </Card>
          ) : (
            <BasicAddressForm/>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderSentPatientShippingUpdateSelection = () => {
    return (
      <FormRow>
        <Label style={{ marginBottom: 0 }}>
          <Checkbox
            checked={sendPatientUpdate}
            onChange={() => {
              dispatch(CartActions.setSentPatientShippingUpdate(!sendPatientUpdate));
              setSendPatientUpdate(!sendPatientUpdate)
            }}
            type="checkbox"
          />
          Send shipping update emails to the patient's email address?
        </Label>
        <SideNote>The practice will receive shipping update emails either way</SideNote>
      </FormRow>
    );
  };

  const renderAddressSection = () => {
    return (
      <>
        <QuestionHeading>
          Where do you want to ship this order to?
        </QuestionHeading>
        {renderAddressSelectionCards()}
        {isLoadingCustomer ? (
          <div style={{ marginBottom: '1.5rem' }}>
            <Loading isCentered />
          </div>
        ) : (
          renderAddressDetails()
        )}
        {renderSentPatientShippingUpdateSelection()}
      </>
    );
  };

  return (
    <div>
      {
        renderAddressSection()
      }
    </div>
  );
};

export default ShippingAddressSelection;
