export interface ActionType<T extends string> {
  type: T;
}

export interface ActionWithPayload<T extends string, P> extends ActionType<T> {
  payload: P;
}

export function createAction<T extends string>(type: T): ActionType<T>;
export function createAction<T extends string, P>(
  type: T,
  payload: P
): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

type FunctionType = (...args: any[]) => any;
type ActionCreatorsMapObject = { [actionCreator: string]: FunctionType };

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<
  A[keyof A]
>;

export enum AppAction {
  RESET_STORE = 'RESET_STORE',
}

export const AppActions = {
  resetStore: () => {
    return {
      type: AppAction.RESET_STORE
    }
  }
}
