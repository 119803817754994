import React from 'react';
import { useField, useFormikContext } from 'formik';
import { ValueType } from 'react-select';
import styled from 'styled-components/macro';
import {
  ErrorText,
  Input,
  Label,
  StyledReactSelect,
  CustomerReactSelectStyles,
} from './inputs.css';

export type FormikOption<T = string> = {
  value: T;
  displayValue: string;
};

export type ReactSelectOption<T = string> = {
  value: T;
  label: string;
};

type SelectProps = {
  options: FormikOption[];
  showDefaultValue?: boolean;
  multiple?: boolean;
  isClearable?: boolean;
  hasError?: boolean;
  onSelectChange?: (e: any) => void;
};

type InputFieldProps = {
  autoComplete?: string;
  className?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: () => null;
  type: string;
  label?: string | React.ReactElement;
  placeholder?: string;
  style?: any;
  maxLength?: number;
  disabled?: boolean;
  validate?: (arg0: string) => string | undefined;
  value?: string;
  testId?: string;
};

const FormikInput = styled.div`
  .label {
    line-height: 24px;
  }
`;

const transformToReactSelectOptions = (
  options: FormikOption[]
): ReactSelectOption[] => options.map(transformToReactSelectOption);

const transformToReactSelectOption = (
  option: FormikOption
): ReactSelectOption => ({ value: option.value, label: option.displayValue });

/**
 * Formik Input field wrapper wraps an input tag with a label and an error message
 */
export const FormikInputWrapper = ({
  autoComplete,
  className,
  name,
  type,
  validate,
  label,
  style,
  placeholder,
  maxLength,
  onChange,
  disabled = false,
  testId,
}: InputFieldProps) => {
  const [field, meta] = useField({ name, validate });
  const id = `field-${name}`;
  const hasError = !!meta.touched && !!meta.error;

  return (
    <FormikInput className={className}>
      <Label style={style} htmlFor={id}>
        {label}
      </Label>
      <Input
        autoComplete={autoComplete}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        {...field}
        onChange={onChange || field.onChange}
        hasError={hasError}
        id={id}
        type={type}
        {...(testId && { 'data-testid': testId })}
      />
      {hasError ? (
        <ErrorText {...(testId && { 'data-testid': `${testId}-error` })}>
          {meta.error}
        </ErrorText>
      ) : null}
    </FormikInput>
  );
};

export const FormikSelectWrapper = ({
  className,
  name,
  validate,
  label,
  style,
  options,
  showDefaultValue = false,
  placeholder,
  isClearable = false,
  multiple = false,
  disabled = false,
  testId,
}: InputFieldProps & SelectProps) => {
  const [field, meta] = useField({ name, validate });
  const { setFieldValue } = useFormikContext();

  const updatedOptions = transformToReactSelectOptions(options);
  if (!field.value && showDefaultValue && updatedOptions[0]) {
    setFieldValue(field.name, updatedOptions[0].value);
  }
  const id = `field-${name}`;
  const hasError = !!meta.touched && !!meta.error;
  const onChange = (
    option: ValueType<ReactSelectOption | ReactSelectOption[]>
  ) => {
    if (!option) {
      setFieldValue(field.name, option);
    } else {
      setFieldValue(
        field.name,
        multiple
          ? (option as ReactSelectOption[]).map(
              (item: ReactSelectOption) => item.value
            )
          : (option as ReactSelectOption).value
      );
    }
  };

  const getValue = () => {
    if (updatedOptions) {
      const defaultValue = showDefaultValue ? updatedOptions[0] : '';
      return multiple
        ? updatedOptions.filter(
            (option) => field.value.indexOf(option.value) >= 0
          )
        : updatedOptions.find((option) => option.value === field.value) ||
            defaultValue;
    } else {
      return multiple ? [] : ('' as any);
    }
  };

  return (
    <FormikInput className={className}>
      <Label style={style} htmlFor={id}>
        {label}
      </Label>
      <StyledReactSelect
        placeholder={placeholder}
        styles={CustomerReactSelectStyles}
        options={updatedOptions}
        isDisabled={disabled}
        hasError={hasError}
        id={id}
        onChange={onChange}
        name={name}
        value={getValue()}
        isMulti={multiple}
        isClearable={isClearable}
      />
      {hasError ? (
        <ErrorText {...(testId && { 'data-testid': `${testId}-error` })}>
          {meta.error}
        </ErrorText>
      ) : null}
    </FormikInput>
  );
};
