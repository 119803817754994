import { LOAD_STATES } from 'core/constants';
import { ProductSkus } from 'store/products/types';
import { Address } from 'store/customer/types';

export type OrderItems = {
  product_type: string;
  product_sku: ProductSkus;
  orderitem_ref: string;
  quantity: number;
  claim_amount_applied: number;
  claim_additional_discount: number;
  coupon_amount: number;
  unit_price: number;
  unit_price_adjustment: number;
  total: number;
  metadata: {[key: string]: any};
};

export type OrderResponse = {
  id: string;
  customer_id: string;
  order_number: number;
  product_types_in_order: string[];
  coupon_code?: string;
  coupon_amount: string;
  tax_transaction: TaxTransactionResponse;
  total: string;
  amount_paid: number;
  amount_refunded: number;
  net_amount_paid: number;
  state: number; // TODO: AK to fill with correct enum types
  financial_state: number; // TODO: AK to fill with correct enum types
  skus_in_order: ProductSkus[];
  orderitems: OrderItems[];
  subtotal: number;
  metadata: {[key:string]: any};
};

export type TaxTransactionResponse = {
  order_id: number;
  id: number;
  code: string;
  totalTaxable: number;
  totalTax: number;
};

export type PaymentState = {
  data: {
    orders: OrderResponse[];
    affirm?: AffirmCheckoutResponse;
    allegro?: AllegroCheckoutResponse;
    paymentHistory: PaymentHistory;
    experimentEnabled?:
      | {
          [key: string]: boolean;
        }
      | undefined;
  };
  financingOptions: PaymentGateway[];
  financingOptionsLoadState: LOAD_STATES;
  experimentLoadState: LOAD_STATES;
  ordersLoadState: LOAD_STATES;
  ordersErrorMessage: string;
  checkoutLoadState: LOAD_STATES;
  checkoutErrorMessage: string;
  affirmCheckoutLoadState: LOAD_STATES;
  affirmCheckoutErrorMessage: string;
  allegroCheckoutErrorMessage: string;
  allegroCheckoutLoadState: LOAD_STATES;
  finalizeFinancingLoadState: LOAD_STATES;
  paymentHistoryLoadState: LOAD_STATES;
  paymentHistoryErrorMessage: string;

  // New multi-item order support order selected
  selectedOrderId?: string;
  draftGenricCheckout?: GenericCheckout;
};

export type CheckoutItem = {
  sku: ProductSkus;
  quantity: number;
};

export type GenericCheckout = {
  orderId?: string;
  email: string;
  token?: string;
  first_name: string;
  last_name: string;
  shipping_address: Address;
  billing_address?: Address;
  phone: string;
  items: CheckoutItem[];
  couponCode: string;
  couponValue?: string;
  isAffirmCard?: boolean;
  isHSA?: boolean;
  last4?: number;
  payment_amount_in_dollars?: number;
  cardType?: string;
  caseRef: string;
  caseType: string;
  metadata?: object;
  userId?: number;
  referring_dentist?: number;
  brand?: string;
};

export type AffirmCheckoutResponse = {
  checkout_id: string;
  order_id: number;
  payment_amount_in_dollars: number;
  redirect_url: string;
  total: number;
  paymentHistoryLoadState: LOAD_STATES;
  paymentHistoryErrorMessage: string;
};

export type AllegroCheckoutResponse = {
  id: number;
  customer_id: number;
  order_number: number;
  product_types_in_order: string[];
  skus_in_order: string[];
  coupon_code: string;
  coupon_amount: number;
  tax_transaction: number;
  total: number;
  amount_paid: number;
  amount_refunded: number;
  net_amount_paid: number;
  state: number;
  financial_state: number;
  redirect_url: string;
};

export enum FinancingGateway {
  affirm = 'affirm',
  allegro = 'allegro',
}

export enum PaymentGateway {
  affirm = 'affirm',
  allegro = 'allegro',
  stripe = 'stripe',
  invoice = 'invoice',
}

export const PaymentGatewayStringMap = {
  [PaymentGateway.allegro]: 'Allegro Financing',
  [PaymentGateway.stripe]: 'Credit Card',
  [PaymentGateway.invoice]: 'Invocing',
  [PaymentGateway.affirm]: 'Affirm Financing',
};

export type BaseMetadata = {
  card_type: string;
  coupon_code?: string;
  coupon_value: string;
  email: string;
  first_name: string;
  last_name: string;
  last4: string;
};

export type PaymentMetadata = BaseMetadata & Partial<AllegroMetadata>;

export type AllegroMetadata = {
  status: string;
  success: boolean;
  expires: string;
  approval: number;
  links: {
    app: string;
    return: string;
    cart: string;
  };
  contract: number;
  id: number;
  additionalPayment: number;
  minimumAdditionalPayment: number;
  amount: number;
  external_payment_id: number;
};

export type Transaction = {
  amount: number;
  created: string;
  destination_account_id: string;
  id: number;
  is_routed: boolean;
  order_ref: string;
  payment_gateway: PaymentGateway;
  payment_metadata?: PaymentMetadata | undefined;
  payment_ref: string;
  refunds: [];
  remaining_refundable_amount: number;
};

export type PaymentHistory = {
  net_payments: number;
  net_refunds: number;
  net_total: number;
  transactions: Transaction[];
  initiated_financing_applications?: InitiatedFinancingApplications[];
};

type InitiatedFinancingApplications = {
  ref: string;
  payment_id: number | null;
  gateway: FinancingGateway;
};

export type CheckoutEligibilityResponse = {
  financing: FinancingGateway[];
};
