/**
 * Checks to see that the required object keys are not empty in the given
 * data object
 * @param {Object<string, string>} data
 * @param {Object<string, string>} required
 * @returns Object<string, string> returns all the object key values that
 * are empty
 */
export const validateRequiredObjects = (data, required) => {
  const errors = {};
  Object.keys(required).forEach(key => {
    if (!data[key]) {
      errors[key] = 'Required';
    }
  });

  return errors;
};

/**
 * returns true if any of the values are empty in the key value pair
 * @param {Object<string, any>} data
 */
export const dataIsEmpty = data => {
  const keys = Object.keys(data);
  for (let i = 0; i < keys.length; i++) {
    if (!data[keys[i]]) {
      return true;
    }
  }

  return false;
};
