import React from 'react';
import Dinero from 'dinero.js';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ORDER_FINANCIAL_STATES } from 'core/constants';
import { getOrders, getSelectedOrder } from 'store/payment';
import { segment } from 'core/integrations';
import { ProductSkus } from 'store/products/types';
import { titleCaseScreamingSnake } from 'core/util';
import { colors } from '@candidco/enamel';
import { PaymentActions } from 'store/payment/actions';
import { CouponActions } from 'store/coupon/actions';
import { getCatalogMap } from 'store/products';

interface RowProps {
  isSelected: boolean;
}

const Row = styled.div<RowProps>`
  background-color: ${props =>
    props.isSelected ? colors.blue30 : colors.white};
  &:hover {
    cursor: pointer;
    background-color: ${props =>
      props.isSelected === false ? colors.yellow30 : 'none'};
  }
`;

export const OrderSelection = () => {
  const dispatch = useDispatch();
  const allOrders = useSelector(getOrders);
  const selectedOrder = useSelector(getSelectedOrder);
  const productCatalog = useSelector(getCatalogMap);
  const displayFinancialState = (code: ORDER_FINANCIAL_STATES) => {
    switch (code) {
      case ORDER_FINANCIAL_STATES.PENDING: {
        return 'Pending';
      }
      case ORDER_FINANCIAL_STATES.FINANCIAL_PAID: {
        return 'Paid';
      }
      case ORDER_FINANCIAL_STATES.PARTIALLY_REFUNDED: {
        return 'Partially Refunded';
      }
      case ORDER_FINANCIAL_STATES.REFUNDED: {
        return 'Refunded';
      }
      default:
        return code;
    }
  };

  if (!allOrders.length && !selectedOrder) {
    return <div className="mv2 b--black-10 tc">( ͡❛ ͜ʖ ͡❛) ... None yet</div>;
  }

  return (
    <div className="mv2 b--black-10 flex flex-column items-end">
      <div className="flex w-100 pa2">
        <div className="w-20 b pa2 tl">Order Id</div>
        <div className="w-20 b pa2 tl">Products</div>
        <div className="w-20 b pa2 tl">Order Status</div>
        <div className="w-20 b pa2 tr">Amount Paid</div>
        <div className="w-20 b pa2 tr">Total</div>
      </div>
      {allOrders.map(order => {
        const amountPaid = Dinero({
          amount: Math.round(order.amount_paid * 100),
        });
        const productNames = order.orderitems
          .filter(
            orderItem => orderItem.product_sku !== ProductSkus.AlignerGood
            // hide the aligner good from the order so it's not rendered to avoid confusion
          )
          .map(orderItem => (
            <div key={orderItem.orderitem_ref}>
              {productCatalog[orderItem.product_sku]?.name ||
                titleCaseScreamingSnake(orderItem.product_type) ||
                ''}
            </div>
          ));

        // if there are no products in an order (because aligner good was filtered) don't render that order
        if (productNames.length === 0) return null;

        return (
          <Row
            className="pa2 flex w-100 items-center"
            key={order.id}
            isSelected={order.id === selectedOrder?.id}
            onClick={() => {
              dispatch(PaymentActions.selectOrder(order.id));
              dispatch(CouponActions.resetCoupon());
            }}
          >
            <div className="w-20 pa2 tl">
              <a
                className="link blue hover-pink underline"
                href={`${process.env.REACT_APP_API}/admin/orders/order/${order.id}/change/`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  segment.trackClick(`Open order in admin`, {
                    orderId: order.id,
                  })
                }
              >
                {order.id}
              </a>
            </div>
            <div className="w-20 tl pa2">{productNames}</div>
            <div className="w-20 tl pa2">
              {displayFinancialState(order.financial_state)}
            </div>
            <div className="w-20 tr pa2 relative has-tooltip">
              {amountPaid.toFormat('$0,0.00')}
            </div>
            <div className="w-20 tr pa2">${order.total}</div>
          </Row>
        );
      })}
    </div>
  );
};
