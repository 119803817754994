import React from 'react';
import styled from 'styled-components';

const ErrorTextPillStyle = styled.div`
  display: flex;
  padding: 8px 10px 8px 10px;
  color: #e7040f;
  background-color: #fff1ef;
  border: 1px solid #e7040f;
  border-radius: 0.25rem;
`;

export const ErrorTextPill = error => (
  <ErrorTextPillStyle>
    <div className="f6 fw5 bw1">{error}</div>
  </ErrorTextPillStyle>
);
