/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Overlay } from 'components/common';
import { LOAD_STATES } from 'core/constants';
import { ErrorText } from 'components/common/ErrorText';
import CustomerInfo from 'components/pages/AlignersKit/Checkout/components/CustomerInfo';
import {
  fetchOrdersForCustomer,
  fetchPaymentHistory,
  PaymentActions,
} from 'store/payment/actions';
import {
  doesOrderExist,
  wasCheckoutSuccessful,
  wasFetchOrdersSuccessful,
  getActiveAKOrder,
  getOrders,
} from 'store/payment';
import { match as MatchInterface } from 'react-router';
import { loadCustomer } from 'store/customer/actions';
import {
  getCustomerErrorMessage,
  getCustomerLoadState,
  isCustomerShippingAddressEmpty,
} from 'store/customer';
import { fetchReferralCoupon, CouponActions } from 'store/coupon/actions';
import { PaymentBody } from 'components/pages/AlignersKit/Checkout/components/PaymentBody';
import ShippingAddressSelection from 'components/pages/AlignersKit/Checkout/components/ShippingAddressSelection';
import { Container } from 'components/generic/container';
import { OrderSummary } from 'components/pages/AlignersKit/Checkout/components/OrderSummary';
import { OrderSelection } from 'components/pages/AlignersKit/Checkout/components/OrderSelection';
import Insurance from 'components/pages/AlignersKit/Checkout/components/Insurance';
import { AppActions } from 'store/action';
import { fetchInsurance } from 'store/insurance/actions';
import { segment } from 'core/integrations';
import { Button } from 'components/generic/kit';
import Tabs from 'components/common/Tab/Tabs';
import { CartActions } from 'store/cart/actions';
import { getIsNewOrder } from 'store/cart';
import { getCases } from 'store/cases';

import { getActiveCase, getSelectedCase } from 'store/cases';
import { fetchCatalogProductsForCaseType } from 'store/products/actions';
import { ProductCart } from './components/ProductCart';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface CheckoutProps {
  match: MatchInterface<{ id: string }>;
}

const Checkout: FunctionComponent<CheckoutProps> = ({ match }) => {
  const dispatch = useDispatch();
  const orderExists = useSelector(doesOrderExist);
  const { id: customerId } = match.params;

  const customerErrorMessage = useSelector(getCustomerErrorMessage);
  const customerLoadState = useSelector(getCustomerLoadState);
  const checkoutSuccessful = useSelector(wasCheckoutSuccessful);
  const fetchOrderSuccessful = useSelector(wasFetchOrdersSuccessful);
  const activeAKOrder = useSelector(getActiveAKOrder);
  const activeCase = useSelector(getActiveCase);
  const cases = useSelector(getCases);
  const latestCase = cases.length ? cases[cases.length -1] : null

  useEffect(() => {
    const case_type = (activeCase || latestCase)?.case_type?.name;
    if (case_type) {
      dispatch(fetchCatalogProductsForCaseType(case_type));
    }
  }, [activeCase, latestCase, dispatch]);

  /**
   * Initialization of page
   */
  useEffect(() => {
    dispatch(fetchOrdersForCustomer(customerId, true));
    dispatch(loadCustomer(customerId));
    dispatch(fetchReferralCoupon(customerId));
    dispatch(fetchInsurance(customerId));
    dispatch(PaymentActions.resetAllegroCheckout());
    segment.track('Loaded customer page', {
      customerId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutSuccessful, customerId]);

  useEffect(() => {
    // only fetch payment history if order exists
    if (orderExists || fetchOrderSuccessful) {
      if (activeAKOrder) {
        dispatch(fetchPaymentHistory(activeAKOrder && activeAKOrder.id));
        segment.track('initial fetching PaymentHistory', {
          orderId: activeAKOrder.id,
        });
      } else {
        dispatch(PaymentActions.resetPaymentHistory());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderExists, activeAKOrder, fetchOrderSuccessful]);

  if (customerLoadState === LOAD_STATES.FAILURE) {
    return ErrorText(customerErrorMessage);
  }

  const onBackToSearchClick = () => {
    dispatch(AppActions.resetStore());
    segment.trackClick('Back to search');
  };

  return (
    <Overlay>
      <div className="mw7 w-100 ml-auto mr-auto mv4 ph3">
        <div className="flex">
          <Link
            to="/aligners-kit"
            className=" pointer underline black f5"
            onClick={onBackToSearchClick}
          >
            Back to search
          </Link>
        </div>
        <Tabs
          defaultTab="order"
          hasBottomMargin
          items={[
            {
              content: <OrderComponentsWrapper customerId={customerId} />,
              id: 'order',
              name: 'Order',
            },
            {
              content: <CustomerComponents customerId={customerId} />,
              id: 'customer',
              name: 'Customer',
            },
          ]}
        />
      </div>
    </Overlay>
  );
};

const CustomerComponents = ({ customerId }: { customerId: string }) => {
  const caseInfo = useSelector(getSelectedCase);

  return (
    <>
      <Container headerText="Customer Info">
        <CustomerInfo customerId={customerId} />
      </Container>
      {caseInfo?.case_type?.name !== 'standalone_whitening' && (
        <Container headerText="Insurance Info" hasToggle isOpen>
          <Insurance />
        </Container>
      )}
    </>
  );
};

const OrderComponentsWrapper = ({ customerId }: { customerId: string }) => {
  const { 'non-ak-shipping-address-improvements': nonAkshippingAddressImprovements } = useFlags();
  const dispatch = useDispatch();
  const isNewOrder = useSelector(getIsNewOrder);
  const isShippingAddressEmpty = useSelector(isCustomerShippingAddressEmpty);
  const fetchOrderSuccessful = useSelector(wasFetchOrdersSuccessful);
  const orders = useSelector(getOrders);
  const hasValidationError = isShippingAddressEmpty;

  if (fetchOrderSuccessful || !hasValidationError) {
    return (
      <>
        <OrderComponents
          onToggle={() => {
            if (!isNewOrder) {
              // when transitioning from false to true means that a new order is being started,
              // in which case, clear the previous cart and clear the selected order id
              dispatch(CartActions.clearCart());
              dispatch(PaymentActions.clearOrder());
            } else {
              // when toggling to cancel an existing build of an order, default the selected order to the first one
              if (orders.length > 0) {
                dispatch(PaymentActions.selectOrder(orders[0].id));
                dispatch(CouponActions.resetCoupon());
              }
            }
            dispatch(CartActions.setNewOrder(!isNewOrder));
          }}
          isNewOrder={isNewOrder}
        />
        {
          nonAkshippingAddressImprovements && (
            <ShippingAddressSelection/>
          )
        }
        {!isShippingAddressEmpty && (
          <Container headerText="Order Options">
            <PaymentBody customerId={customerId} />
          </Container>
        )}
      </>
    );
  }

  return null;
};

const OrderComponents = ({
  onToggle,
  isNewOrder,
}: {
  onToggle: () => void;
  isNewOrder: boolean;
}) => {
  if (isNewOrder) {
    return (
      <Container
        headerText="Create Order"
        customContent={
          <NewOrderButton onToggle={onToggle} buttonText="Cancel Order" />
        }
      >
        <ProductCart />
      </Container>
    );
  }

  return (
    <>
      <Container
        headerText="Order Selection"
        customContent={
          <NewOrderButton onToggle={onToggle} buttonText="Create New Order" />
        }
      >
        <OrderSelection />
      </Container>
      <Container headerText="Order Summary">
        <OrderSummary />
      </Container>
    </>
  );
};

const NewOrderButton = ({
  onToggle,
  buttonText,
}: {
  onToggle: () => void;
  buttonText: string;
}) => (
  <div className="mv2 b--black-10 flex flex-column items-end">
    <Button fullWidth={false} onClick={onToggle} dataCy="create-order-button">
      {buttonText}
    </Button>
  </div>
);

export default withRouter(Checkout);
