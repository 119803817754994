import React from 'react';
import { LOAD_STATES } from 'core/constants';
import { useSelector } from 'react-redux';
import { Transaction, PaymentGateway } from 'store/payment/types';
import Dinero from 'dinero.js';
import {
  getPaymentHistoryLoadState,
  getPaymentHistoryErrorMessage,
  getPaymentHistory,
  getTotalAdjustedPrice,
  getNetAmountPaid,
  getRefunds,
} from 'store/payment';

const getPaymentText = ({
  payment_gateway: paymentGateway,
  payment_metadata,
}: {
  payment_gateway: PaymentGateway;
  payment_metadata?:
    | {
        card_type: string;
        last4: string;
      }
    | undefined;
}) => {
  if (!payment_metadata) {
    return '';
  }

  const { card_type: cardType = 'Card', last4 = '####' } = payment_metadata;
  switch (paymentGateway) {
    case PaymentGateway.stripe:
      return `${cardType} ${last4}`;

    case PaymentGateway.affirm:
      return 'Affirm';

    case PaymentGateway.allegro:
      return 'Financing';

    default:
      return '';
  }
};

const PaymentHistoryRow = ({ payment }: { payment: Transaction }) => {
  const { remaining_refundable_amount: finalAmount } = payment;

  return (
    <div className="flex mb2">
      {getPaymentText(payment)}{' '}
      <div className="ml-auto">
        - {Dinero({ amount: finalAmount }).toFormat('$0,0.00')}
      </div>
    </div>
  );
};

const PaymentHistory = () => {
  const paymentHistoryLoadState = useSelector(getPaymentHistoryLoadState);
  const paymentHistoryErrorMessage = useSelector(getPaymentHistoryErrorMessage);
  const payments = useSelector(getPaymentHistory);
  const totalAdjustedPrice = useSelector(getTotalAdjustedPrice);
  const amountPaid = useSelector(getNetAmountPaid);
  const refundAmount = useSelector(getRefunds);

  if (paymentHistoryLoadState === LOAD_STATES.PENDING) {
    return <div> Fetching payment history </div>;
  }

  if (paymentHistoryLoadState === LOAD_STATES.FAILURE) {
    return (
      <div className="bg-light-red b--dark-red near-black pa1 br2 ba f6 b--solid mb1">
        Error loading payment history: {paymentHistoryErrorMessage}
      </div>
    );
  }

  return (
    <>
      {payments.map((payment: Transaction, i) => (
        <PaymentHistoryRow key={`payment-${i}`} payment={payment} />
      ))}

      <div className="flex mt2">
        <strong>Refund Amount:</strong>
        <div className="ml-auto">{refundAmount.toFormat('$0,0.00')}</div>
      </div>

      <div className="flex mt2">
        <strong>Balance Remaining:</strong>
        <div className="ml-auto">
          {totalAdjustedPrice.subtract(amountPaid).toFormat('$0,0.00')}
        </div>
      </div>
    </>
  );
};

export default PaymentHistory;
