import Cookies from 'js-cookie';

const { REACT_APP_CANDID_ENV: candidEnv } = process.env;
export const cookieSuffix =
  !candidEnv || candidEnv.toLowerCase() === 'production' ? '' : `_${candidEnv}`;
const authTokenName = `candid_auth_token${cookieSuffix}`;
const refreshTokenName = `candid_refresh_token${cookieSuffix}`;
const userGroupsName = `candid_user_groups${cookieSuffix}`;

export const getToken = () => Cookies.get(authTokenName);
export const getRefreshToken = () => Cookies.get(refreshTokenName);
export const getUserGroups = () => Cookies.get(userGroupsName);
export const signIn = (accessToken, refreshToken, groups = []) => {
  Cookies.set(authTokenName, accessToken, {
    domain: 'candidco.com',
    secure: true,
  });
  Cookies.set(refreshTokenName, refreshToken, {
    domain: 'candidco.com',
    secure: true,
  });
  Cookies.set(userGroupsName, groups, {
    domain: 'candidco.com',
    secure: true,
  });
};
export const signOut = () => {
  Cookies.remove(authTokenName, { domain: 'candidco.com' });
  Cookies.remove(refreshTokenName, { domain: 'candidco.com' });
  Cookies.remove(userGroupsName, { domain: 'candidco.com' });
};
export const isAuthenticated = () => !!getToken();
